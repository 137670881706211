import {useParams} from 'react-router-dom';

import {useGetUser, useLenderById} from '@renofi/graphql';

export default function useCurrentLender({id} = {}) {
  const params = useParams();
  const {user, loading: loadingUser} = useGetUser();

  const lenderId = id || params?.id || user?.lenderId;
  const {loading, lender} = useLenderById({
    variables: {lenderId},
  });

  return {
    lenderId,
    lender,
    loading: loading || loadingUser,
    user,
  };
}
