import React from 'react';

import PropTypes from 'prop-types';

import Container from './Container';
import Row from './Row';
import Col from './Col';

const SingleColumnLayout = ({children, ...props}) => (
  <Container {...props}>
    <Row>
      <Col>{children}</Col>
    </Row>
  </Container>
);

SingleColumnLayout.propTypes = {
  children: PropTypes.node,
};

export default SingleColumnLayout;
