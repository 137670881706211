import React from 'react';

import PropTypes from 'prop-types';

import ThumbsDown from '@renofi/icons/src/ThumbsDown';

import {Note, Row} from '../styled';
import Card from '../../Card';
import Flex from '../../Flex';
import Alert from '../../Alert';
import Box from '../../Box';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Table from '../../Table';
import Text from '../../Text';
import TextField from '../../TextField';
import RatioField from '../../RatioField';
import Textarea from '../../Textarea';

import DateField from './DateField';

const Denied = ({
  activeStatus,
  status,
  disabled,
  loading,
  persistable,
  deniedOn,
  deniedCreditScoreExplanation,
  deniedDebtExplanation,
  deniedOtherExplanation,
  finalCreditScore,
  finalDtiRatio,
  isDeniedReasonCreditScore,
  isDeniedReasonDebt,
  isDeniedReasonOther,
  onChangeStatus,
  onChangeFieldValue,
  onSaveLoanApplication,
}) => {
  const getChangeHandler = (field) => (value) =>
    onChangeFieldValue(field, value);
  return (
    <Card
      title={
        <Flex width={1} alignItems="center">
          <ThumbsDown />
          <Text ml={16}>Denial details</Text>
        </Flex>
      }
      mt={24}
      p={0}>
      {persistable && (
        <Alert variant="info">
          <Flex width={1} alignItems="center" justifyContent="space-between">
            <div>
              Enter details for denying the loan application below before
              confirming the status change.
            </div>
            <Box>
              <Button
                variant="link"
                onClick={() => onChangeStatus('inProgress')}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={disabled}
                onClick={onSaveLoanApplication}>
                {activeStatus !== status
                  ? 'Confirm status change'
                  : 'Update details'}
              </Button>
            </Box>
          </Flex>
        </Alert>
      )}

      <Note>Date and reason(s) for denial</Note>

      <Table borderless css={{marginBottom: 0}}>
        <tbody>
          <Row>
            <td width={200}>Official date denied</td>
            <td>
              <Box width={1 / 3}>
                <DateField
                  mb={0}
                  value={deniedOn}
                  onChange={getChangeHandler('deniedOn')}
                />
              </Box>
            </td>
          </Row>
          <Row>
            <td width={200}>
              <Checkbox
                onChange={getChangeHandler('isDeniedReasonCreditScore')}
                checked={isDeniedReasonCreditScore}
                label="Low FICO score"
              />
            </td>
            <td>
              {isDeniedReasonCreditScore && (
                <Flex>
                  <Box width={160} mr={16}>
                    <TextField
                      value={finalCreditScore}
                      label="Enter final FICO"
                      my={12}
                      onChange={getChangeHandler('finalCreditScore')}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <TextField
                      value={deniedCreditScoreExplanation}
                      label="Add a note"
                      my={12}
                      onChange={getChangeHandler(
                        'deniedCreditScoreExplanation',
                      )}
                    />
                  </Box>
                </Flex>
              )}
            </td>
          </Row>

          <Row>
            <td width={200}>
              <Checkbox
                onChange={getChangeHandler('isDeniedReasonDebt')}
                checked={isDeniedReasonDebt}
                label="DTI above maximum"
              />
            </td>
            <td>
              {isDeniedReasonDebt && (
                <Flex>
                  <Box width={160} mr={16}>
                    <RatioField
                      value={finalDtiRatio}
                      leftIcon="percent"
                      label="Enter final DTI"
                      my={12}
                      onChange={getChangeHandler('finalDtiRatio')}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <TextField
                      value={deniedDebtExplanation}
                      label="Add a note"
                      my={12}
                      onChange={getChangeHandler('deniedDebtExplanation')}
                    />
                  </Box>
                </Flex>
              )}
            </td>
          </Row>

          <Row>
            <td width={200}>
              <Checkbox
                onChange={getChangeHandler('isDeniedReasonOther')}
                checked={isDeniedReasonOther}
                label="Other"
              />
            </td>
            <td>
              {isDeniedReasonOther && (
                <Textarea
                  my={12}
                  rows={3}
                  value={deniedOtherExplanation}
                  label="Provide details"
                  onChange={getChangeHandler('deniedOtherExplanation')}
                />
              )}
            </td>
          </Row>
        </tbody>
      </Table>
    </Card>
  );
};

Denied.propTypes = {
  activeStatus: PropTypes.string,
  status: PropTypes.string,
  persistable: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  deniedOn: PropTypes.string,
  deniedCreditScoreExplanation: PropTypes.string,
  deniedDebtExplanation: PropTypes.string,
  deniedOtherExplanation: PropTypes.string,
  finalCreditScore: PropTypes.string,
  finalDtiRatio: PropTypes.string,
  isDeniedReasonCreditScore: PropTypes.bool,
  isDeniedReasonDebt: PropTypes.bool,
  isDeniedReasonOther: PropTypes.bool,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeFieldValue: PropTypes.func.isRequired,
  onSaveLoanApplication: PropTypes.func.isRequired,
};

export default Denied;
