import {
  assoc,
  defaultTo,
  evolve,
  isNil,
  map,
  once,
  path,
  pick,
  pipe,
} from 'ramda';
import {v4 as uuid} from 'uuid';
import {withCurrentSource, withFeasibilityReviewState} from 'api/hoc';
import {
  removeEmptyPayments,
  prepareFeasibilityReviewForSubmit,
} from 'lib/feasibilityReview/utils';
import {withFeasibilityProject} from 'modules/feasibility-reviewer';

import {
  branch,
  compose,
  renderNothing,
  withHandlers,
  withLifecycle,
  withProps,
} from '@renofi/recompose';
import {withApollo, withSubmitFeasibilityReview} from '@renofi/graphql';
import debounce from '@renofi/utilities/src/debounce';

const debounceMutation = once((func, wait = 500) => {
  return debounce(func, wait);
});

const keys = [
  'changeOrderProcedureApproved',
  'clearDescriptionOfWorkApproved',
  'clearGuaranteeApproved',
  'clearGuaranteeRating',
  'contactInfoApproved',
  'contractTimelineApproved',
  'contractTimelineRating',
  'costEstimateSuppliedApproved',
  'estimatedDuration',
  'termsOfPaymentApproved',
  'termsOfPaymentNotes',
];

const resetState = (currentSource, feasibilityReview, state) => {
  return keys.reduce((result, key) => {
    const original = result[key];
    const source = feasibilityReview[`${key}Source`];
    const value = currentSource === source ? original : null;
    return assoc(key, value, result);
  }, state);
};

const storeFeasibilityReview = once(
  (currentSource, feasibilityReview, updateFeasibilityReviewState) => {
    const state = pipe(
      pick([
        'id',
        'changeOrderProcedureApproved',
        'changeOrderProcedureNotes',
        'clearDescriptionOfWorkApproved',
        'clearDescriptionOfWorkNotes',
        'clearGuaranteeApproved',
        'clearGuaranteeNotes',
        'clearGuaranteeRating',
        'complexityGrading',
        'contactInfoApproved',
        'contactInfoNotes',
        'contractTimelineApproved',
        'contractTimelineNotes',
        'contractTimelineRating',
        'costEstimateSuppliedApproved',
        'costEstimateSuppliedNotes',
        'estimatedDuration',
        'feasibilityStudyItems',
        'generalCommentaryAndNotes',
        'homeownerResponsibilityCompleted',
        'inScopeAdjustedTotalRating',
        'inScopeTotalRating',
        'outOfScopeCompleted',
        'termsOfPaymentApproved',
        'termsOfPaymentNotes',
        'termsOfPayments',
        'uncompletedDetails',
        'uncompletedReason',
      ]),
      evolve({
        feasibilityStudyItems: pipe(
          defaultTo([]),
          map(
            pick([
              'id',
              'adjustedCost',
              'cost',
              'descriptionOfWork',
              'reviewerNotes',
              'reviewerRating',
              'source',
              'type',
            ]),
          ),
        ),
        termsOfPayments: pipe(
          defaultTo([]),
          map(pick(['amount', 'comment', 'specialMaterialCosts'])),
          map((item) => assoc('id', uuid(), item)),
        ),
      }),
    )(feasibilityReview);
    updateFeasibilityReviewState(
      resetState(currentSource, feasibilityReview, state),
    );
  },
);

export default () =>
  compose(
    withApollo,
    withCurrentSource(),
    withFeasibilityProject(),
    withFeasibilityReviewState(),
    withSubmitFeasibilityReview(),
    withLifecycle({
      componentDidMount() {
        const {
          currentSource,
          feasibilityReview,
          feasibilityReviewId,
          feasibilityReviewState,
          updateFeasibilityReviewState,
        } = this.props;
        if (
          !(
            feasibilityReviewId &&
            feasibilityReview &&
            !feasibilityReviewState?.id
          )
        )
          return;
        storeFeasibilityReview(
          currentSource,
          feasibilityReview,
          updateFeasibilityReviewState,
        );
      },
    }),
    withHandlers({
      updateFeasibilityReview:
        ({
          readFeasibilityReviewState,
          submitFeasibilityReview,
          updateFeasibilityReviewState,
        }) =>
        (partial) => {
          const currentState = readFeasibilityReviewState();
          const feasibilityReviewState = removeEmptyPayments(currentState);

          updateFeasibilityReviewState(partial);
          setTimeout(async () => {
            const {id} = feasibilityReviewState;
            const submit = debounceMutation(submitFeasibilityReview);
            const feasibilityReview = prepareFeasibilityReviewForSubmit(
              feasibilityReviewState,
              partial,
            );

            submit({
              id,
              feasibilityReview,
            });
          }, 0);
        },
    }),
    withHandlers({
      updateFeasibilityReviewField:
        ({updateFeasibilityReview}) =>
        async (key, value) => {
          await updateFeasibilityReview({[key]: value});
        },
    }),
    withProps(({feasibilityReview, feasibilityReviewState}) => ({
      feasibilityReview: {
        ...feasibilityReview,
        ...(feasibilityReviewState?.id ? feasibilityReviewState : {}),
      },
    })),
    branch(pipe(path(['feasibilityReviewState', 'id']), isNil), renderNothing),
  );
