import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_BBB_GRADE} from '../../../mutations';

export default function useSubmitBbbGrade({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [submitBbbGrade, {data, loading, error}] = useMutation(
    SUBMIT_BBB_GRADE,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.submitBbbGrade?.contractor;

  return {submitBbbGrade, response, loading, error};
}
