import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {GET_FEASIBILITY_REQUEST_PREVIEW} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getFeasibilityRequestPreview:
        ({client}) =>
        async (variables) => {
          const result = await client.query({
            query: GET_FEASIBILITY_REQUEST_PREVIEW,
            variables,
          });
          return pathOr(null, ['data', 'feasibilityReviewRequested'], result);
        },
    }),
  );
