import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {find, keys, pathOr, pick, propEq, propOr} from 'ramda';
import {DOC_RETURN_REASONS, MESSAGE_REASONS} from 'lib/gcdd';

import {
  Badge,
  Box,
  Flex,
  SelectField,
  Textarea,
  TextField,
} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {actionRegular} from '@renofi/theme/src/colors';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {useGcddReviewById} from '@renofi/graphql';

import {Remove} from './styled';
import {
  getMessageForReferences,
  getMessageForReturnedDocument,
  getGcddDocuments,
} from './utils';

function MessageContractorModal({
  gcddReviewId,
  onChange,
  validReasons = keys(MESSAGE_REASONS),
  ...props
}) {
  const [totalDocuments, setTotalDocuments] = useState([]);
  const [totalReferences, setTotalReferences] = useState([]);
  const [messageReasons, setMessageReasons] = useState(MESSAGE_REASONS);
  const {fetch, loading, gcddReview} = useGcddReviewById({lazy: true});

  // User input states
  const [message, setMessage] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [returnReason, setReturnReason] = useState('');
  const [reason, setReason] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);

  useEffect(() => {
    setMessageReasons(pick(validReasons));
    setReason(propOr(null, 'reason', props));
    setSelectedDocuments(propOr([], 'selectedDocuments', props));
    if (gcddReviewId) {
      fetch({variables: {id: gcddReviewId}});
    }
  }, [gcddReviewId]);

  useEffect(() => {
    onChange({
      message,
      otherReason,
      reason,
      returnReason,
      selectedDocuments,
      selectedReferences,
    });
  }, [
    message,
    otherReason,
    reason,
    returnReason,
    selectedDocuments,
    selectedReferences,
  ]);

  useEffect(() => {
    const references = pathOr([], ['questionnaire', 'references'], gcddReview);
    const docs = getGcddDocuments(gcddReview);
    const totalDocs = docs.map((doc) => ({
      ...doc,
      label: doc.fileName,
      value: doc.id,
    }));
    const totalRefs = references.map((ref) => ({
      ...ref,
      label: `${humanizeSnakeCase(ref.type)} (${ref.name})`,
      value: ref.id,
    }));
    setTotalDocuments(totalDocs);
    setTotalReferences(totalRefs);
  }, [gcddReview]);

  useEffect(() => {
    switch (reason) {
      case 'return_document':
        return setMessage(
          getMessageForReturnedDocument({
            selectedDocuments,
            totalDocuments,
            returnReason,
          }),
        );
      case 'reference_unresponsive':
        return setMessage(
          getMessageForReferences({selectedReferences, totalReferences}),
        );
      default:
        return setMessage('');
    }
  }, [reason, returnReason, selectedDocuments, selectedReferences]);

  return (
    <ThemeProvider theme="light">
      <Flex css={{minHeight: 500}} flexDirection="column">
        <Box>
          <SelectField
            label="What would you like to do with this rejected document?"
            onChange={setReason}
            options={messageReasons}
            placeholder="Select action"
            value={reason}
          />
        </Box>

        {reason === 'other' && (
          <Box>
            <TextField
              disabled={loading}
              label="Please specify. (This will be used for the subject of the email)"
              onChange={setOtherReason}
              placeholder="Other reason..."
              value={otherReason}
            />
          </Box>
        )}

        {reason === 'reference_unresponsive' && (
          <Box>
            <SelectField
              active
              disabled={loading}
              label="Please choose the unresponsive reference"
              multi
              onChange={setSelectedReferences}
              options={totalReferences}
              placeholder="Select reference(s)"
              value={selectedReferences}
            />
          </Box>
        )}

        {reason === 'return_document' && (
          <Fragment>
            <Box>
              <SelectField
                active
                disabled={loading}
                label="Document(s) being returned"
                multi
                onChange={setSelectedDocuments}
                options={totalDocuments}
                placeholder="Select document(s)"
                value={selectedDocuments}
              />
            </Box>
            <Box>
              {!!selectedDocuments.length &&
                selectedDocuments.map((docId) => {
                  return (
                    <Badge mr={2} pill variant="info" key={docId}>
                      <Flex alignItems="center">
                        <Box>
                          {propOr(
                            docId,
                            'label',
                            find(propEq('value', docId), totalDocuments),
                          )}
                        </Box>
                        <Remove
                          color={actionRegular}
                          onClick={() =>
                            setSelectedDocuments(
                              selectedDocuments.filter((d) => d !== docId),
                            )
                          }
                        />
                      </Flex>
                    </Badge>
                  );
                })}
            </Box>
            <Box mt={3}>
              <SelectField
                disabled={loading}
                label="Reason for returning this document to the contractor"
                onChange={setReturnReason}
                options={DOC_RETURN_REASONS}
                placeholder="Select return reason..."
                value={returnReason}
              />
            </Box>
          </Fragment>
        )}

        {reason !== 'delete_document' && (
          <Box mt={3}>
            <Textarea
              disabled={loading}
              label="Your message"
              onChange={setMessage}
              placeholder="Message for contractor"
              rows={14}
              value={message}
            />
          </Box>
        )}
      </Flex>
    </ThemeProvider>
  );
}

MessageContractorModal.propTypes = {
  gcddReviewId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  reason: PropTypes.string,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string),
  validReasons: PropTypes.arrayOf(PropTypes.string),
};

export default MessageContractorModal;
