import React from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';

import {Dropdown, HeadCell, Link} from '@renofi/components-internal';
import {actionRegular, basic80} from '@renofi/theme';
import {Check} from '@renofi/icons';

const ShareWithLenderHeadCell = ({
  isInternal,
  onToggleSharing,
  autoSharing,
}) => {
  if (!isInternal) {
    return null;
  }

  return (
    <HeadCell css={{textAlign: 'right !important'}}>
      <Flex width={1} alignItems="center">
        <Box>Share with Lender</Box>
        <Dropdown heading="For updates that are On Schedule or Ahead of Schedule">
          <Link
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggleSharing(true);
            }}
            css={{display: 'flex'}}>
            <Box width={16} height={16} mr="8px">
              {autoSharing && (
                <Check height={16} width={16} color={actionRegular} />
              )}
            </Box>
            <Text color={autoSharing ? actionRegular : basic80}>
              Automatically share with Lender
            </Text>
          </Link>
          <Link
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggleSharing(false);
            }}
            css={{display: 'flex'}}>
            <Box width={16} height={16} mr="8px">
              {!autoSharing && (
                <Check height={16} width={16} color={actionRegular} />
              )}
            </Box>
            <Text color={!autoSharing ? actionRegular : basic80}>
              Manually share with Lender
            </Text>
          </Link>
        </Dropdown>
      </Flex>
    </HeadCell>
  );
};

ShareWithLenderHeadCell.propTypes = {
  isInternal: PropTypes.bool,
  onToggleSharing: PropTypes.func,
  autoSharing: PropTypes.bool,
};

export default ShareWithLenderHeadCell;
