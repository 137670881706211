import {gql} from '@apollo/client';

export default gql`
  query softCreditChecksByProjectId($projectId: ID!) {
    softCreditChecksByProjectId(projectId: $projectId) {
      authorizedRenofiToVerifyCreditAt
      coborrowerEmail
      coborrowerFirstName
      coborrowerLastName
      dateOfBirth
      firstName
      haltedByBusinessRules
      taskId
      id
      inquiryErrors
      lastName
      refreshable
      creditFiles {
        borrowerRole
        errorMessages
        fileFrozen
        modelName
        score
        source
      }
      creditLiabilityOverrides {
        adjustedMonthlyPayment
        adjustmentReason
        creditLiabilityId
        projectId
        softCreditCheckId
      }
      creditLiabilityProperties {
        id
        realEstateOwnedPropertyId
        additionalCreditLiabilityId
        softCreditCheckId
        softCreditLiabilityId
      }
      creditAlertMessages {
        borrowerRole
        categoryType
        code
        id
        requiresReview
        text
      }
    }
  }
`;
