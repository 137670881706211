import React from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';

import {useTheme} from '@renofi/theme';

import Text from '../Text';
import Flex from '../Flex';

import {LabelWrapper} from './styled';

const keys = [
  'm',
  'mb',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
  'width',
  'className',
  'small',
];

const RadioButton = (props) => {
  const {checked, disabled, label, value, onChange, help, ...rest} = props;
  const {styles} = useTheme('radioButton', props);

  return (
    <Flex
      style={{...styles, ...props.style}}
      css={{
        ':hover': {
          ...(checked ? {} : {borderColor: '#ADADAD !important'}),
        },
      }}
      {...pick(keys, rest)}
      onClick={() => {
        if (disabled) {
          return;
        }

        onChange(value);
      }}>
      <input
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        css={{margin: '0 4px 0 0', padding: 0}}
        onChange={(event) => {
          event.stopPropagation();
        }}
        {...omit([...keys, 'small'], rest)}
      />
      <LabelWrapper hasHelpLabel={Boolean(help)}>
        {label}
        {help && (
          <Text fontStyle="italic" fontSize={12} mt="5px">
            {help}
          </Text>
        )}
      </LabelWrapper>
    </Flex>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  help: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  small: PropTypes.bool,
  height: PropTypes.number,
};

export default RadioButton;
