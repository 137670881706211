import {pick, propEq, reject} from 'ramda';
import {withReviewerFeasibilityReview} from 'modules/feasibility-reviewer';

import {compose, withHandlers, withProps} from '@renofi/recompose';
import {
  withAddFeasibilityStudyItem,
  withRemoveFeasibilityStudyItem,
  withUpdateFeasibilityStudyItem,
} from '@renofi/graphql';

export default () =>
  compose(
    withReviewerFeasibilityReview(),
    withAddFeasibilityStudyItem(),
    withRemoveFeasibilityStudyItem(),
    withUpdateFeasibilityStudyItem(),
    withProps(({feasibilityReview}) => ({
      feasibilityStudyItems: feasibilityReview?.feasibilityStudyItems || [],
    })),
    withHandlers({
      addFeasibilityStudyItems:
        ({
          addFeasibilityStudyItem,
          readFeasibilityReviewState,
          updateFeasibilityReviewState,
        }) =>
        async (items) => {
          const result = await Promise.all(
            items.map((variables) => addFeasibilityStudyItem(variables)),
          );
          const {feasibilityStudyItems} = readFeasibilityReviewState();
          updateFeasibilityReviewState({
            feasibilityStudyItems: feasibilityStudyItems.concat(result),
          });
        },
      removeFeasibilityStudyItem:
        ({
          removeFeasibilityStudyItem,
          readFeasibilityReviewState,
          updateFeasibilityReviewState,
        }) =>
        async (id) => {
          const {feasibilityStudyItems} = readFeasibilityReviewState();
          updateFeasibilityReviewState({
            feasibilityStudyItems: reject(
              propEq('id', id),
              feasibilityStudyItems,
            ),
          });
          await removeFeasibilityStudyItem(id);
        },
      updateFeasibilityStudyItem:
        ({
          updateFeasibilityStudyItem,
          readFeasibilityReviewState,
          updateFeasibilityReviewState,
        }) =>
        async (id, partial) => {
          const {feasibilityStudyItems} = readFeasibilityReviewState();
          const feasibilityStudyItemState = feasibilityStudyItems.find(
            propEq('id', id),
          );
          const feasibilityStudyItem = {
            ...feasibilityStudyItemState,
            ...partial,
          };

          updateFeasibilityReviewState({
            feasibilityStudyItems: feasibilityStudyItems.map((studyItem) =>
              id === studyItem.id ? feasibilityStudyItem : studyItem,
            ),
          });

          await updateFeasibilityStudyItem({
            id,
            feasibilityStudyItem: pick(
              [
                'adjustedCost',
                'cost',
                'descriptionOfWork',
                'reviewerNotes',
                'reviewerRating',
              ],
              feasibilityStudyItem,
            ),
          });
        },
    }),
    withHandlers({
      addFeasibilityStudyItem:
        ({addFeasibilityStudyItems}) =>
        (item) => {
          addFeasibilityStudyItems([item]);
        },
    }),
  );
