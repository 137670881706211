import {
  useChangeTaskStatus,
  useCreateTask,
  useRemoveTask,
  useUpdateTask,
} from '@renofi/graphql';

export default function useTaskOperations(options = {}) {
  const {changeTaskStatus} = useChangeTaskStatus(options);
  const {createTask} = useCreateTask(options);
  const {removeTask} = useRemoveTask(options);
  const {updateTask} = useUpdateTask(options);

  return {changeTaskStatus, createTask, removeTask, updateTask};
}
