import React, {useState} from 'react';

import PropTypes from 'prop-types';

import Flex from '../Flex';

import {HeaderWrapper, Title} from './styled';

const Collapsible = ({open, children, title}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleWrapperClick = (e) => {
    e.stopPropagation();
  };

  const handleCollapsible = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <Flex flexDirection="column" width={1} onClick={handleWrapperClick}>
      <HeaderWrapper>
        <button
          type="button"
          className={!isOpen ? 'expand' : 'collapse'}
          onClick={handleCollapsible}
        />
        <Title onClick={handleCollapsible}>{title}</Title>
      </HeaderWrapper>

      <Flex width={1} pl="22px">
        {isOpen && children}
      </Flex>
    </Flex>
  );
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
};

Collapsible.defaultProps = {
  open: false,
  title: 'Title',
  onSelectionChanged: () => {},
};

export default Collapsible;
