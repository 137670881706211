import {useContext} from 'react';

import {identity} from 'ramda';

import {compose, mapProps, withProps, branch} from '@renofi/recompose';
import {getUserId} from '@renofi/utilities/src/auth';
import {Context as StorageContext} from '@renofi/utilities/src/storage';

import {GET_USER_BY_ID} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options} = {}) =>
  compose(
    withProps(() => {
      const storage = useContext(StorageContext);
      const userId = getUserId(storage);
      if (!userId) {
        return {
          errors: [new Error('AUTHENTICATION_ERROR')],
        };
      }
      return {userId};
    }),
    branch(
      ({errors}) => !errors?.length,
      withQuery({
        query: GET_USER_BY_ID,
        props: ({data: {userById, error}, ...props}) => ({
          user: userById,
          ...(error?.graphQLErrors?.length
            ? {errors: error.graphQLErrors}
            : {}),
          ...(error?.networkError?.result?.errors?.length
            ? {errors: error.networkError.result.errors}
            : {}),
          ...props,
        }),
        breakOnError: false,
        options: options || (({userId}) => ({variables: {id: userId}})),
      }),
    ),
    mapProps(mapper),
  );
