import React, {useCallback} from 'react';

import {Box, Flex, Text} from 'rebass';

import {Button, Switch, Textarea, TimeAgo} from '@renofi/components-internal';
import {useFeatureFlags} from '@renofi/utilities';

const FeatureFlags = () => {
  const {clearAllOverrides, flags, hasOverrides, setOverrideByKeyValue} =
    useFeatureFlags();
  const flagKeys = Object.keys(flags);

  const onChangeExtraData = useCallback(
    (key, value) => {
      const existingData = flags[key] || {};
      let data = {};
      try {
        data = JSON.parse(value);
      } catch (err) {
        data = {};
      }
      setOverrideByKeyValue(key, {...existingData, ...data});
    },
    [flags],
  );

  const onToggleEnabled = useCallback(
    (key, enabled) => {
      const existingData = flags[key] || {};
      setOverrideByKeyValue(key, {...existingData, enabled});
    },
    [flags],
  );

  return (
    <Flex p={3} flexDirection="column">
      <Box p={3}>
        <Button disabled={hasOverrides} onClick={clearAllOverrides}>
          Clear all overrides
        </Button>
      </Box>
      <Text p={3}>
        Click the Checkboxes to override any given feature-flag; this override
        will persist across browser refreshes.
      </Text>
      <table css={{margin: 16, textAlign: 'left', verticalAlign: 'top'}}>
        <thead>
          <tr>
            <th>Flag name</th>
            <th>Enabled?</th>
            <td>Is override enabled?</td>
            <th>Extra data (as JSON)</th>
          </tr>
        </thead>
        <tbody>
          {flagKeys.map((key) => {
            const data = flags[key];
            const {enabled, overwrittenAt, ...extraData} = data || {};

            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <Switch
                    checked={enabled}
                    onClick={() => onToggleEnabled(key, !enabled)}
                  />
                </td>
                <td>
                  <TimeAgo date={overwrittenAt} />
                </td>
                <td>
                  <Textarea
                    mb={2}
                    onChange={(v) => onChangeExtraData(key, v)}
                    value={JSON.stringify(extraData)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Flex>
  );
};

export default FeatureFlags;
