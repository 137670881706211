import React, {useState} from 'react';

import {map, uniq} from 'lodash';
import {useHistory, useLocation} from 'react-router-dom';
import {home, internalUsers, myAccount} from 'modules/routes';

import {
  Flex,
  Link,
  Navbar,
  Text,
  BorrowersSearch,
  Image,
  Modal,
  Box,
} from '@renofi/components-internal';
import PersonIcon from '@renofi/icons/src/Person';
import {GraphQlAlert} from '@renofi/modules-internal';
import {useGetUser} from '@renofi/graphql';
import {useLogout, useScreenSize} from '@renofi/utilities/src/hooks';
import {
  canEditStandard,
  canViewStandard,
  canViewGcdd,
  canEditGcdd,
  canManageInternalUsers,
} from '@renofi/utilities/src/permissions';
import {Glass} from '@renofi/icons';
import SideMenuIcon from '@renofi/icons/src/SideMenu';

import {NAVBAR_HEIGHT} from '../../constants';

import {Blueprint, Menu, NavTab} from './styled';
import blueprint from './blueprint.svg';
import renofi from './renofi.svg';
import GcddSearch from './GcddSearch';
import {PATHS} from './constants';

function Header() {
  const {user} = useGetUser();
  const location = useLocation();
  const history = useHistory();
  const {logout} = useLogout();
  const {isDesktop, isMobile} = useScreenSize();
  const [showSearch, setShowSearch] = useState(false);

  const path = location?.pathname || '/';
  const {email, permissions = []} = user || {};
  const allKeys = uniq(map(permissions, 'key'));
  const isOpsUser = canEditStandard(user) || canViewStandard(user);
  const isGcddUser = canEditGcdd(user) || canViewGcdd(user);

  let allowedPaths = PATHS.reduce((arr, data) => {
    return !data.permissions.length ||
      data.permissions.some((k) => allKeys.includes(k))
      ? arr.concat(data)
      : arr;
  }, []);

  const isGcddTab = () => {
    return location?.pathname?.includes('dd-requests');
  };

  const onClickSearch = () => {
    setShowSearch(true);
  };

  const onSelectProject = (projectId) => {
    setShowSearch(false);
    history.push(`/borrowers/${projectId}`);
  };

  const onSelectReview = (contractorId) => {
    setShowSearch(false);
    history.push(`/dd-requests/${contractorId}/details`);
  };

  return (
    <>
      <GraphQlAlert />

      <Navbar fixed height={NAVBAR_HEIGHT} py={0} px={[2, 3]} zIndex={3}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Link to={home()}>
              <Image height={19} src={renofi} alt="RenoFi" />
            </Link>
            <Blueprint>
              <Link to={home()}>
                <Image height={[24, 31]} src={blueprint} alt="Blueprint" />
              </Link>
            </Blueprint>

            {isDesktop
              ? allowedPaths.map((data) => {
                  return (
                    <NavTab
                      key={data.label}
                      active={path.startsWith(data.path)}
                      to={data.path}>
                      {data.label}
                    </NavTab>
                  );
                })
              : null}
          </Flex>
          {(isGcddUser || isOpsUser) && isMobile ? (
            <Box onClick={onClickSearch}>
              <Glass />
            </Box>
          ) : null}

          <Flex
            alignItems="center"
            justifyContent="center"
            css={{zIndex: 1007}}>
            {isDesktop && isGcddUser && isGcddTab() && (
              <GcddSearch onSelectReview={onSelectReview} />
            )}
            {isDesktop && isOpsUser && !isGcddTab() && (
              <BorrowersSearch onSelectProject={onSelectProject} />
            )}
            {Boolean(email) && (
              <Menu
                fixed
                pure={isMobile}
                local={false}
                label={isMobile ? <SideMenuIcon /> : <PersonIcon width={20} />}>
                <Text fontSize={12} p={3}>
                  {email}
                </Text>
                {isMobile
                  ? allowedPaths.map((data) => {
                      return (
                        <Link key={data.label} to={data.path}>
                          {data.label}
                        </Link>
                      );
                    })
                  : null}
                <Link to={myAccount()}>My account</Link>
                {canManageInternalUsers(user) && (
                  <Link to={internalUsers()}>Users</Link>
                )}
                <Link onClick={logout}>Sign out</Link>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Navbar>

      {showSearch ? (
        <Modal onClose={() => setShowSearch(false)} show header="Search">
          <Box>
            {isGcddUser && isGcddTab() && (
              <GcddSearch
                inline
                onSelectReview={onSelectReview}
                theme="light"
              />
            )}
            {isOpsUser && !isGcddTab() && (
              <BorrowersSearch
                inline
                onSelectProject={onSelectProject}
                theme="light"
              />
            )}
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

export default React.memo(Header);
