import {
  __,
  add,
  assoc,
  concat,
  evolve,
  filter,
  find,
  map,
  omit,
  pathOr,
  pick,
  pipe,
  propEq,
  propOr,
  reduce,
  toPairs,
} from 'ramda';

import {branch, compose, withProps} from '@renofi/recompose';
import {renovationTypes} from '@renofi/utilities/src/enumTypes';
import * as options from '@renofi/utilities/src/renovationChanges';

const complexChanges = {
  ...options.bathrooms,
  ...options.bedrooms,
  ...options.fireplaces,
  ...options.garages,
  ...options.hvac,
};

const getChangeMapper =
  (propertyDetails) =>
  ({
    additional,
    changeTypes,
    details,
    existing,
    renovationType,
    total,
    type,
  }) => {
    if (['exterior', 'interior'].includes(renovationType)) {
      return details
        ? {label: renovationTypes[renovationType], value: details}
        : null;
    }
    if (renovationType === 'squareFootage') {
      const {totalSquareFootage = 0} = propertyDetails || {};
      const diff = total - totalSquareFootage;
      return diff
        ? {
            label: renovationTypes[renovationType],
            value: `${diff > 0 ? '+' : ''}${diff} sqft`,
          }
        : null;
    }
    if (type === 'SingleChange' && changeTypes) {
      const values = changeTypes.map(
        (changeType) => options[renovationType][changeType],
      );
      const value = values.join(', ');
      return value
        ? {label: renovationTypes[renovationType], value, details}
        : null;
    }
    if (type === 'MultipleChange') {
      if (!(additional.length || existing.length)) return null;

      const results = [];
      if (existing.length) {
        const groups = pipe(
          filter(({changeType}) => changeType !== 'no_changes'),
          reduce((acc, val) => {
            if (acc[val.changeType]) {
              return evolve({[val.changeType]: add(1)})(acc);
            }
            return assoc(val.changeType, 1)(acc);
          }, {}),
          toPairs,
          map(([type, count]) => [complexChanges[type], count]),
          filter(([label, count]) => label && count),
          map(([label, count]) => `${count} ${label}`),
        )(existing);
        results.push(...groups);
      }
      if (additional.length) {
        results.push(`${additional.length} New`);
      }
      return results.length
        ? {
            label: renovationTypes[renovationType],
            value: results.join(', '),
          }
        : null;
    }
    return null;
  };

const extractValues = ({
  appraisalRenovationDetails: renovationDetails,
  tasks = [],
}) => {
  const task = find(propEq('taskType', 'appraisal_property_details'), tasks);
  const propertyDetails = propOr(null, 'appraisalPropertyDetails', task);

  const values = pipe(
    filter(([_type, value]) => !!value),
    map(([renovationType, {__typename: type, ...value}]) => ({
      ...value,
      renovationType,
      type,
    })),
    map(getChangeMapper(propertyDetails)),
    filter((value) => !!value),
    concat(
      __,
      pipe(
        pathOr([], ['renovationChanges', 'custom']),
        map(({details, name}) => ({label: name, value: details})),
      )(renovationDetails),
    ),
  )(
    concat(
      pipe(
        propOr({}, 'renovationChanges'),
        omit(['__typename', 'custom', 'cooling', 'heating']),
        toPairs,
      )(renovationDetails),
      pipe(
        propOr({}, 'renovationChanges'),
        pick(['cooling', 'heating']),
        toPairs,
      )(renovationDetails),
    ),
  );
  return {values};
};

export default () =>
  compose(
    branch(
      propEq('taskType', 'appraisal_renovation_details'),
      withProps(extractValues),
    ),
  );
