import React from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';

import {border, white} from '@renofi/theme';

import Flex from '../Flex';
import Icon from '../Icon';

import {Container, Content, Header, Title, Wrapper} from './styled';

const marginKeys = ['m', 'mb', 'ml', 'mr', 'mt', 'mx', 'my'];
const paddingKeys = ['p', 'pb', 'pl', 'pr', 'pt', 'px', 'py'];

const Card = ({
  children,
  defaultBackgroundColor = white,
  titleBackgroundColor,
  defaultBorderColor = border,
  header,
  icon,
  iconProps = {},
  title,
  styledTitle = true,
  radius = 4,
  p = 16,
  isFullHeight,
  success = false,
  ...props
}) => {
  const TitleWrapper = styledTitle ? Title : Flex;
  return (
    <Wrapper {...pick([...marginKeys, 'width'], props)}>
      {header && <Header>{header}</Header>}
      <Container
        {...omit([...marginKeys, ...paddingKeys, 'width'], props)}
        defaultBackgroundColor={defaultBackgroundColor}
        defaultBorderColor={defaultBorderColor}
        success={success}
        radius={radius}>
        {title && (
          <TitleWrapper titleBackgroundColor={titleBackgroundColor}>
            {Boolean(icon) ? <Icon name={icon} {...iconProps} /> : null}
            {title}
          </TitleWrapper>
        )}
        {children && (
          <Content
            p={p}
            isFullHeight={isFullHeight}
            {...pick(paddingKeys, props)}>
            {children}
          </Content>
        )}
      </Container>
    </Wrapper>
  );
};

Card.propTypes = {
  defaultBackgroundColor: PropTypes.string,
  titleBackgroundColor: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  title: PropTypes.node,
  header: PropTypes.node,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  children: PropTypes.node,
  styledTitle: PropTypes.bool,
  radius: PropTypes.number,
  hasTabs: PropTypes.bool,
  p: PropTypes.number,
  success: PropTypes.bool,
  isFullHeight: PropTypes.bool,
};

export default Card;
