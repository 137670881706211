import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {throttle} from 'lodash';

function redactUrl(url, value) {
  return url.replace(
    new RegExp(`/${value}/([^/]*)`, 'i'),
    `/${value}/REDACTED`,
  );
}

const urlSanitizer = (url) => {
  let sanitizedUrl = url;
  ['reset_password', 'signup', 'token_auth'].forEach((value) => {
    sanitizedUrl = redactUrl(sanitizedUrl, value);
  });
  return sanitizedUrl;
};

const requestSanitizer = (request) => {
  request.headers['authorization'] = null;
  return request;
};

export function initLogRocket({
  childDomains,
  parentDomain,
  sanitize = false,
} = {}) {
  const LOGROCKET_ID =
    process.env.REACT_APP_LOGROCKET_ID || process.env.NEXT_PUBLIC_LOGROCKET_ID;
  const APP_ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;
  const release =
    process.env.REACT_APP_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

  if (APP_ENV === 'production') {
    LogRocket.init(LOGROCKET_ID, {
      rootHostname: 'renofi.com',
      mergeIframes: true,
      browser: {
        ...(sanitize ? {urlSanitizer} : {}),
      },
      network: {
        ...(sanitize ? {requestSanitizer} : {}),
      },
      ...(childDomains ? {childDomains} : {}),
      ...(parentDomain ? {parentDomain} : {}),
      release,
    });
    setupLogRocketReact(LogRocket);

    addSessionUrlToSegment();
  }
}

export function addSessionUrlToSegment() {
  LogRocket.getSessionURL(function (sessionURL) {
    window.analytics?.track('LogRocket', {
      sessionURL: sessionURL,
    });
  });
}

export function identifyLogRocket(uid, traits = {}) {
  const APP_ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;

  if (APP_ENV === 'production') {
    throttle(() => {
      LogRocket.identify(uid, traits);
    }, 500);
  }
}

const noop = () => {};

export default process.env.REACT_APP_ENV ||
process.env.NEXT_PUBLIC_ENV === 'production'
  ? LogRocket
  : {
      sessionUrl: 'https://dev',
      init: noop,
      identify: noop,
      track: noop,
      captureException: noop,
      captureMessage: noop,
    };
