import {gql} from '@apollo/client';

export default gql`
  mutation removeResidentialAddress($residentialAddressId: ID!, $taskId: ID!) {
    removeResidentialAddress(
      residentialAddressId: $residentialAddressId
      taskId: $taskId
    ) {
      project {
        id
      }
    }
  }
`;
