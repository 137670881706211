import PropTypes from 'prop-types';
import {ItemsBarTabFileName} from 'modules/viewer-v2/components/Layout';

import {Flex} from '@renofi/components-internal';

import GcddReviewDocumentActions from './GcddReviewDocumentActions';

const DOCUMENT_TITLES = {
  contractor_license: 'State license',
  contractor_general_liability_insurance: 'General liability insurance',
  contractor_workers_comp_insurance: 'Workers comp. insurance',
};

const TabContent = ({doc}) => {
  const title = DOCUMENT_TITLES[doc.documentType];

  return (
    <Flex justifyContent="space-between" width={1}>
      <Flex flexDirection="column" maxWidth={180} css={{overflow: 'hidden'}}>
        <ItemsBarTabFileName>
          {title} ({doc.fileName})
        </ItemsBarTabFileName>
      </Flex>
      <Flex alignItems="center" flexShrink={0}>
        <GcddReviewDocumentActions item={doc} />
      </Flex>
    </Flex>
  );
};

TabContent.propTypes = {
  doc: PropTypes.shape({
    documentType: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  }),
};

export default TabContent;
