import {isNil} from 'ramda';

import capitalize from './capitalize';

export default (string = '') => {
  if (isNil(string)) {
    return '';
  }

  return capitalize(
    string
      .replace(/([A-Z])/g, ' $1')
      .replace(/-|_/gi, ' ')
      .trim()
      .toLowerCase(),
  );
};
