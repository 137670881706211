import styled from '@emotion/styled';
import {error} from 'logrocket';

import {
  actionDark,
  actionRegular,
  basic20,
  basic55,
  basic75,
  border,
  dangerDark,
} from '@renofi/theme';

import {Box} from '../index';
import Flex from '../Flex';

export const TabsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: -3,
});

export const Tab = styled.div(({isActive}) => ({
  padding: '8px 16px',
  marginRight: '8px',
  width: 177,
  cursor: 'pointer',
  backgroundColor: 'white',
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
  borderTop: `2px solid ${isActive ? actionRegular : 'transparent'}`,
  transition: 'all 0.2s ease',
  '&:nth-of-type(1)': {
    borderLeft: `solid 1px ${border}`,
  },
  '&:hover': {
    borderTopColor: isActive ? actionRegular : basic20,
  },
  position: isActive ? 'relative' : 'static',
}));

export const MainText = styled.div(
  {
    fontSize: 16,
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({bold, error}) => ({
    fontFamily: bold ? 'Averta-Bold' : 'Averta',
    color: error ? dangerDark : bold ? actionDark : basic75,
  }),
);

export const SubText = styled.div({
  fontSize: 12,
  color: basic55,
  marginBottom: '2px',
});

export const ButtonWrapper = styled(Flex)({
  width: 40,
  height: 56,
  paddingLeft: '8px',
  alignItems: 'center',
  position: 'relative',
});

export const OptionsWrapper = styled(Box)({
  position: 'absolute',
  width: 'auto',
  background: 'white',
  padding: '8px',
  top: 40,
  left: 9,
  borderRadius: 3,
  boxShadow: '1px 1px 6px 0px #00000038',
  zIndex: 100,
});
