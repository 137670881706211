import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {APPRAISAL_ORDER_CREATED} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getAppraisalOrderPreview:
        ({client}) =>
        async (appraisalOrder) => {
          const result = await client.query({
            query: APPRAISAL_ORDER_CREATED,
            variables: {appraisalOrder},
          });
          return pathOr(null, ['data', 'appraisalOrderCreated'], result);
        },
    }),
  );
