import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {DELETE_NOTE} from '../../mutations';
import useMutation from '../useMutation';

export default function useDeleteNote({
  onCompleted = noop,
  refetchQueries,
} = {}) {
  const [deleteNote, {data, loading, error}] = useMutation(DELETE_NOTE, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['deleteNote'], data);
  return {deleteNote, response, loading, error};
}
