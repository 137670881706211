import {propEq} from 'ramda';

import {branch, compose, withProps} from '@renofi/recompose';
import formatMoney from '@renofi/utilities/src/formatMoney';
import formatNumber from '@renofi/utilities/src/formatNumber';
import {
  basementTypes,
  coolingTypes,
  fireplaceTypes,
  heatingTypes,
  propertyTypes,
} from '@renofi/utilities/src/enumTypes';

const getBathroomsAbove = ({
  fullBathroomsInBasement,
  fullBathroomsTotal,
  halfBathroomsInBasement,
  halfBathroomsTotal,
}) => {
  const value = []
    .concat(
      fullBathroomsTotal
        ? `${fullBathroomsTotal - fullBathroomsInBasement || 0} Full bathrooms`
        : [],
    )
    .concat(
      halfBathroomsTotal
        ? `${halfBathroomsTotal - halfBathroomsInBasement} Half-baths`
        : [],
    )
    .join(', ');
  return value ? [`Above grade: ${value}`] : [];
};

const getBathroomsBelow = ({
  fullBathroomsInBasement,
  halfBathroomsInBasement,
}) => {
  const value = []
    .concat(
      fullBathroomsInBasement
        ? `${fullBathroomsInBasement} Full bathrooms`
        : [],
    )
    .concat(
      halfBathroomsInBasement ? `${halfBathroomsInBasement} Half-baths` : [],
    )
    .join(', ');
  return value ? [`Below grade: ${value}`] : [];
};

const getBathrooms = (propertyDetails) =>
  [
    ...getBathroomsAbove(propertyDetails),
    ...getBathroomsBelow(propertyDetails),
  ].join(', ');

const getBedrooms = ({bedroomsInBasement, bedroomsTotal}) => {
  return []
    .concat(
      bedroomsTotal
        ? `Above grade: ${bedroomsTotal - bedroomsInBasement || 0} Bedrooms`
        : [],
    )
    .concat(
      bedroomsInBasement ? `Below grade: ${bedroomsInBasement} Bedrooms` : [],
    )
    .join(', ');
};

const extractValues = ({appraisalPropertyDetails: propertyDetails}) => {
  const values = propertyDetails
    ? [
        {
          label: 'Address',
          value: [
            propertyDetails.streetAddressOne,
            propertyDetails.county,
            propertyDetails.city,
            propertyDetails.state,
            propertyDetails.zipCode,
          ].join(', '),
        },
        {
          label: 'Property type',
          value:
            propertyDetails.propertyType === 'other'
              ? propertyDetails.propertyTypeOther
              : propertyTypes[propertyDetails.propertyType],
        },
        {
          label: 'Year built / purchased',
          value:
            (propertyDetails.yearBuilt || propertyDetails.yearPurchased) &&
            [propertyDetails.yearBuilt, propertyDetails.yearPurchased].join(
              ' / ',
            ),
        },
        {
          label: 'Basement',
          value: basementTypes[propertyDetails.basementState],
        },
        {
          label: 'Bedrooms',
          value:
            propertyDetails.bedroomsTotal &&
            `${propertyDetails.bedroomsTotal} bd (${getBedrooms(
              propertyDetails,
            )})`,
        },
        {
          label: 'Bathrooms',
          value:
            (propertyDetails.fullBathroomsTotal ||
              propertyDetails.halfBathroomsTotal) &&
            `${(
              propertyDetails.fullBathroomsTotal +
              propertyDetails.halfBathroomsTotal / 2
            ).toFixed(1)} ba (${getBathrooms(propertyDetails)})`,
        },
        {
          label: 'Square footage',
          value: propertyDetails.totalSquareFootage
            ? `${formatNumber(
                propertyDetails.totalSquareFootage,
              )} sqft (Above grade: ${formatNumber(
                propertyDetails.totalSquareFootage -
                  propertyDetails.basementSquareFootage || 0,
              )} sqft, Below grade: ${formatNumber(
                propertyDetails.basementSquareFootage,
              )} sqft)`
            : null,
        },
        {
          label: 'HVAC',
          value: []
            .concat(
              (propertyDetails.coolingTypes || []).map(
                (value) => coolingTypes[value],
              ),
            )
            .concat(
              (propertyDetails.heatingTypes || []).map(
                (value) => heatingTypes[value],
              ),
            )
            .join(', '),
        },
        {
          label: 'Fireplace',
          value: propertyDetails.fireplaceTypes?.length
            ? propertyDetails.fireplaceTypes
                .map((value) => fireplaceTypes[value])
                .join(', ')
            : 'None',
        },
        {
          label: 'Garage',
          value:
            propertyDetails.garagesAttached || propertyDetails.garagesDetached
              ? []
                  .concat(
                    propertyDetails.garagesAttached
                      ? `${propertyDetails.garagesAttached} Attached`
                      : [],
                  )
                  .concat(
                    propertyDetails.garagesDetached
                      ? `${propertyDetails.garagesDetached} Detached`
                      : [],
                  )
                  .join(', ')
              : 'None',
        },
        {
          label: 'Rental income',
          value:
            propertyDetails.rentalIncomeCollectedOnPrimaryResidence !== null &&
            (!propertyDetails.rentalIncomeCollectedOnPrimaryResidence
              ? 'No'
              : propertyDetails.rentalIncome
                ? formatMoney(propertyDetails.rentalIncome, false)
                : null),
        },
        {
          label: 'Additional information',
          value:
            propertyDetails.additionalInformationPresent !== null &&
            (propertyDetails.additionalInformationPresent ? 'Yes' : 'No'),
          details: propertyDetails.additionalInformation,
        },
        {
          label: 'Renovations since purchase',
          value:
            propertyDetails.previousRenovationsDescriptionPresent !== null &&
            (propertyDetails.previousRenovationsDescriptionPresent
              ? 'Yes'
              : 'No'),
          details: propertyDetails.previousRenovationsDescription,
        },
      ]
    : [];
  return {values};
};

export default () =>
  compose(
    branch(
      propEq('taskType', 'appraisal_property_details'),
      withProps(extractValues),
    ),
  );
