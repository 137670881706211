import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {isFunction} from 'lodash';
import {PatternFormat} from 'react-number-format';

import noop from '@renofi/utilities/src/noop';
import {isPhone} from '@renofi/utilities';

import FieldFrame from '../FieldFrame';
import FieldInput from '../FieldInput';

const INVALID_MSG = 'Invalid phone number';

const PhoneField = ({
  active,
  autoComplete = 'tel',
  canClear = false,
  clearValue = null,
  defaultValue,
  disabled,
  name,
  placeholder = '(___) ___-____',
  value: currentValue,
  onBlur = noop,
  onChange = noop,
  onClear = noop,
  onFocus = noop,
  tabIndex,
  small,
  validations = [],
  onValidate = () => {},
  showValidation,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || defaultValue || '');
  const [dirty, setDirty] = useState(Boolean(value));
  const [error, setError] = useState(null);
  const [validationErr, setValidationErr] = useState(null);
  const isUsingInputValidation = isFunction(onValidate);

  useEffect(() => {
    const val = currentValue || defaultValue || '';
    if (active) {
      setValue(val);
    }
    setError(!isPhone(val) && val !== '' ? INVALID_MSG : null);
  }, [dirty, defaultValue, currentValue]);

  const changeHandler = (event) => {
    const val = event.target.value;

    const numbersOnly = val?.replace(/\D/g, '');
    const numbersValue = numbersOnly?.slice(0, 10);

    setValue(numbersValue);
    setError(!isPhone(numbersValue) ? INVALID_MSG : null);
    onChange(numbersValue);
  };

  const clearHandler = useCallback(() => {
    setValue(clearValue);
    onClear(clearValue);
  }, [clearValue]);

  const blurHandler = (event) => {
    setFocus(false);
    setDirty(true);
    onBlur(event);
  };
  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  return (
    <FieldFrame
      name={name}
      canClear={canClear}
      disabled={disabled}
      small={small}
      error={
        showValidation
          ? validationErr
          : !isUsingInputValidation && dirty && error
      }
      focused={focus}
      leftIcon={small ? undefined : 'phone'}
      onClear={clearHandler}
      {...props}>
      <PatternFormat
        format="(###) ###-####"
        placeholder={small ? '(___) ___-____' : placeholder}
        id={name}
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        tabIndex={tabIndex}
        type="tel"
        value={value}
        mask="_"
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        validations={validations}
        onValidate={validationHandler}
        showValidation={showValidation}
        customInput={FieldInput}
        passFullEvent
      />
    </FieldFrame>
  );
};

PhoneField.propTypes = {
  autoComplete: PropTypes.string,
  canClear: PropTypes.bool,
  small: PropTypes.bool,
  clearValue: PropTypes.any,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default PhoneField;
