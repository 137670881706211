import {pathOr, propEq} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';
import {getAllFacets} from '@renofi/utilities/src/facets';

import {GET_PROJECT_TASKS, GET_TASK} from '../queries';

import withBorrowerProject from './withBorrowerProject';
import withApollo from './withApollo';

const facets = getAllFacets();

export default () =>
  compose(
    withApollo,
    withBorrowerProject(),
    withHandlers({
      fetchTask:
        ({client, project}) =>
        async (documentId) => {
          await Promise.all(
            facets.map(async (facet) => {
              const {projectTasks = []} =
                client.readQuery({
                  query: GET_PROJECT_TASKS,
                  variables: {projectId: project.id, facet},
                }) || {};
              const oldTask = projectTasks.find(({documents}) =>
                documents.some(propEq('id', documentId)),
              );

              if (!oldTask) return;

              const response2 = await client.query({
                query: GET_TASK,
                variables: {id: oldTask.id},
                fetchPolicy: 'no-cache',
              });
              const updatedTask = pathOr(null, ['data', 'taskById'], response2);

              client.writeQuery({
                query: GET_PROJECT_TASKS,
                variables: {projectId: project.id, facet},
                data: {
                  projectTasks: projectTasks.map((task) => {
                    return task.id === updatedTask.id ? updatedTask : task;
                  }),
                },
              });
            }),
          );
        },
    }),
  );
