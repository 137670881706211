import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_SOFT_INQUIRY_BY_ENTITY_ID} from '../../queries';

export default function useSoftInquiryByEntityId({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_SOFT_INQUIRY_BY_ENTITY_ID,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, errors, loading, fetch, refetch} = response;

  const softInquiry = data?.softInquiryByEntityId;

  return {
    fetch,
    softInquiry,
    loading,
    errors,
    refetch,
  };
}
