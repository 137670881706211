import {assoc, identity} from 'ramda';

import {compose, mapProps, withHandlers} from '@renofi/recompose';

import {GET_RENOVATION_UPDATE} from '../queries';

import withQuery from './withQuery';
import withChangeRenovationStateAction from './withChangeRenovationStateAction';
import withChangeRenovationStateData from './withChangeRenovationStateData';
import withChangeRenovationStateKind from './withChangeRenovationStateKind';

export default ({mapper = identity, options} = {}) =>
  compose(
    withChangeRenovationStateAction(),
    withChangeRenovationStateData(),
    withChangeRenovationStateKind(),
    withQuery({
      query: GET_RENOVATION_UPDATE,
      props: ({data, ...props}) => ({
        renovationUpdate:
          data?.renovationUpdate || data?.previousData?.renovationUpdate || {},
        ...props,
      }),
      options,
    }),
    mapProps(mapper),
    withHandlers({
      changeRenovationStateId:
        ({renovationUpdate: {data}, changeRenovationStateData}) =>
        (id) => {
          changeRenovationStateData({...data, id});
        },
      changeRenovationStateDate:
        ({renovationUpdate: {data}, changeRenovationStateData}) =>
        (date) => {
          changeRenovationStateData({...data, date});
        },
      changeRenovationStateDetails:
        ({renovationUpdate: {data}, changeRenovationStateData}) =>
        (details) => {
          changeRenovationStateData({...data, details});
        },
      changeRenovationStateFiles:
        ({changeRenovationStateData}) =>
        (files) => {
          changeRenovationStateData({
            files: files.map(assoc('__typename', 'RenovationUpdateFile')),
          });
        },
    }),
  );
