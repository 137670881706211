import {useParams} from 'react-router-dom';
import {
  equals,
  filter,
  find,
  flatten,
  isNil,
  map,
  not,
  path,
  pick,
  pipe,
  prop,
  propEq,
  reject,
} from 'ramda';
import {withMessage} from 'api/hoc';
import TASK_STATUSES from 'lib/task/taskStatuses';
import {REASONS_TO_MESSAGE_BORROWER} from 'lib/borrower/constants';

import {compose, withHandlers, withProps, withState} from '@renofi/recompose';
import {
  withAddNotification,
  withCreateTaskAdditionalRequest,
  withRejectContractor,
  withRejectDocuments,
  withSendReminder,
} from '@renofi/graphql';
import {useProjectTasks} from '@renofi/graphql';
import spreadProps from '@renofi/utilities/src/spreadProps';

import Component from './Component';

const rejectionOptions = {
  incomplete: 'Incomplete',
  illegible: 'Illegible',
  not_signed: 'Not signed',
  incorrect: 'Incorrect',
  expired: 'Expired',
  other: 'Other',
};

export default compose(
  withProps(() => {
    const {projectId} = useParams();
    const {tasks} = useProjectTasks({variables: {projectId}});
    return {
      projectId,
      allTasks: tasks || [],
    };
  }),
  withMessage(),
  withSendReminder(),
  withAddNotification(),
  withRejectDocuments(),
  withRejectContractor(),
  withCreateTaskAdditionalRequest(),
  spreadProps(['message']),
  withState('loading', 'setLoading', false),
  withState('showPreview', 'setShowPreview', false),
  withHandlers({
    onAddDocument:
      ({documents, updateMessage}) =>
      async (documentId) => {
        await updateMessage({documents: documents.concat(documentId)});
      },
    onAddTask:
      ({allTasks, reason, tasks, updateMessage}) =>
      async (taskId) => {
        if (reason === REASONS_TO_MESSAGE_BORROWER.REQUEST) {
          await updateMessage({tasks: [taskId]});
          return;
        }
        if (taskId === '*') {
          await updateMessage({
            tasks: allTasks
              .filter(propEq('status', TASK_STATUSES.OUTSTANDING))
              .map(prop('id')),
          });
        } else {
          await updateMessage({tasks: tasks.concat(taskId)});
        }
      },
    onChangeReason:
      ({updateMessage}) =>
      async (reason) => {
        await updateMessage({reason});
      },
    onChangeRejectionReason:
      ({updateMessage}) =>
      async (rejectionReason) => {
        await updateMessage({rejectionReason});
      },
    onChangeMessageContent:
      ({updateMessage}) =>
      async (messageContent) => {
        await updateMessage({messageContent});
      },
    onCancel:
      ({resetMessage, setShowPreview}) =>
      async () => {
        setShowPreview(false);
        await resetMessage();
      },
    onRemoveDocument:
      ({documents, updateMessage}) =>
      async (id) => {
        await updateMessage({documents: reject(equals(id), documents)});
      },
    onRemoveTask:
      ({tasks, updateMessage}) =>
      async (id) => {
        await updateMessage({tasks: reject(equals(id), tasks)});
      },
    onSubmit:
      ({
        type,
        projectId,
        reason,
        rejectionReason,
        documents,
        tasks,
        allTasks,
        messageContent: message,
        addNotification,
        createTaskAdditionalRequest,
        rejectContractor,
        rejectDocuments,
        resetMessage,
        sendReminder,
        setLoading,
        setShowPreview,
        showPreview,
      }) =>
      async () => {
        if (!showPreview && reason === REASONS_TO_MESSAGE_BORROWER.REQUEST) {
          return setShowPreview(true);
        }

        setLoading(true);
        if (type === 'contractor') {
          const contractorId = pipe(
            find(pipe(prop('contractor'), isNil, not)),
            path(['contractor', 'id']),
          )(allTasks);
          await rejectContractor({
            rejectionReason,
            contractorId,
            projectId,
            message,
          });
          await addNotification({
            type: 'snackbar',
            variant: 'success',
            content: 'Contractor rejection message sent successfully.',
          });
        } else {
          if (reason === REASONS_TO_MESSAGE_BORROWER.RETURN) {
            await rejectDocuments({
              projectId,
              documents: documents.map((id) => ({
                id,
                rejectionReason,
              })),
              message,
              delayedMessage: true,
            });
            await addNotification({
              type: 'snackbar',
              variant: 'success',
              content: 'Document message queued for sending.',
            });
          }
          if (reason === REASONS_TO_MESSAGE_BORROWER.REMIND) {
            await sendReminder({message, tasks: tasks.map((id) => ({id}))});
            await addNotification({
              type: 'snackbar',
              variant: 'success',
              content: 'Reminder sent successfully.',
            });
          }
          if (reason === REASONS_TO_MESSAGE_BORROWER.REQUEST) {
            const [taskId] = tasks;
            await createTaskAdditionalRequest({message, projectId, taskId});
            await addNotification({
              type: 'snackbar',
              variant: 'success',
              content: 'Request sent successfully.',
            });
          }
        }
        setLoading(false);
        setShowPreview(false);
        await resetMessage();
      },
  }),
  withProps(({allTasks}) => ({
    allDocuments: pipe(map(prop('documents')), flatten)(allTasks || []),
  })),
  withProps(
    ({allDocuments = [], allTasks = [], documents = [], tasks = []}) => ({
      documentOptions: pipe(
        filter(({id}) => !documents.includes(id)),
        map(({fileName: label, id: value}) => ({label, value})),
      )(allDocuments),
      documents: documents
        .map((id) => allDocuments.find(propEq('id', id)))
        .map(pick(['id', 'fileName'])),
      tasks: tasks
        .map((id) => allTasks.find(propEq('id', id)))
        .map(pick(['id', 'title'])),
    }),
  ),
  withProps(({type, allTasks}) => ({
    ...(type === 'contractor'
      ? {
          tasks: allTasks
            .filter(pipe(prop('contractor'), isNil, not))
            .map(pick(['id', 'title'])),
        }
      : {}),
  })),
  withProps(
    ({type, documents, messageContent, reason, rejectionReason, tasks}) => ({
      disabled:
        type === 'contractor'
          ? !(rejectionReason && tasks.length && messageContent)
          : !(
              ((reason === REASONS_TO_MESSAGE_BORROWER.RETURN &&
                documents.length &&
                rejectionReason) ||
                ([
                  REASONS_TO_MESSAGE_BORROWER.REMIND,
                  REASONS_TO_MESSAGE_BORROWER.REQUEST,
                ].includes(reason) &&
                  tasks.length)) &&
              messageContent
            ),
    }),
  ),
  withProps(({type}) => ({
    rejectionOptions:
      type === 'contractor'
        ? pick(['incomplete', 'incorrect', 'other'])(rejectionOptions)
        : rejectionOptions,
  })),
)(Component);
