import {cloneElement, forwardRef} from 'react';

import PropTypes from 'prop-types';

import useClickOutside from '@renofi/utilities/src/useClickOutside';

const ClickOutside = forwardRef(({children, onClickOutside}, parentRef) => {
  useClickOutside(onClickOutside, parentRef);

  return cloneElement(children, {ref: parentRef, forwardRef: parentRef});
});

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func,
};

export default ClickOutside;
