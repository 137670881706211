import {basic20} from '@renofi/theme';

const CopyToClipboardIcon = ({color = basic20, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.31574 5.78955H3.89471C3.39237 5.7901 2.91076 5.98991 2.55555 6.34513C2.20034 6.70035 2.00054 7.18197 2 7.68432V12.1054C2.00054 12.6078 2.20034 13.0894 2.55555 13.4446C2.91076 13.7998 3.39237 13.9996 3.89471 14.0002H8.31574C8.81808 13.9996 9.29969 13.7998 9.6549 13.4446C10.0101 13.0894 10.2099 12.6078 10.2105 12.1054V7.68432C10.2099 7.18197 10.0101 6.70035 9.6549 6.34513C9.29969 5.98991 8.81808 5.7901 8.31574 5.78955ZM8.94731 12.1054C8.94715 12.2729 8.88056 12.4334 8.76215 12.5518C8.64374 12.6702 8.48319 12.7368 8.31574 12.737H3.89471C3.72726 12.7368 3.56671 12.6702 3.44831 12.5518C3.3299 12.4334 3.26331 12.2729 3.26314 12.1054V7.68432C3.26331 7.51687 3.3299 7.35632 3.44831 7.23791C3.56671 7.1195 3.72726 7.0529 3.89471 7.05274H8.31574C8.48319 7.0529 8.64374 7.11949 8.76215 7.2379C8.88056 7.35631 8.94715 7.51687 8.94731 7.68432V12.1054Z"
        fill={color}
      />
      <path
        d="M12.1048 2H7.68378C7.18143 2.00055 6.69982 2.20035 6.34461 2.55556C5.9894 2.91078 5.78961 3.3924 5.78906 3.89475V4.52633C5.78906 4.60927 5.8054 4.6914 5.83714 4.76803C5.86888 4.84465 5.9154 4.91428 5.97404 4.97293C6.03269 5.03157 6.10232 5.07809 6.17894 5.10983C6.25557 5.14157 6.33769 5.15791 6.42063 5.15791C6.50357 5.15791 6.5857 5.14157 6.66232 5.10983C6.73895 5.07809 6.80857 5.03157 6.86722 4.97293C6.92587 4.91428 6.97239 4.84465 7.00413 4.76803C7.03587 4.6914 7.0522 4.60927 7.0522 4.52633V3.89475C7.05237 3.72729 7.11896 3.56674 7.23737 3.44833C7.35578 3.32992 7.51632 3.26333 7.68378 3.26317H12.1048C12.2723 3.26333 12.4328 3.32993 12.5512 3.44834C12.6696 3.56675 12.7362 3.72729 12.7364 3.89475V8.31584C12.7362 8.4833 12.6696 8.64385 12.5512 8.76225C12.4328 8.88066 12.2723 8.94726 12.1048 8.94743H11.4732C11.3057 8.94743 11.1451 9.01397 11.0266 9.13241C10.9082 9.25086 10.8417 9.4115 10.8417 9.57901C10.8417 9.74651 10.9082 9.90716 11.0266 10.0256C11.1451 10.144 11.3057 10.2106 11.4732 10.2106H12.1048C12.6071 10.21 13.0888 10.0102 13.444 9.65503C13.7992 9.29981 13.999 8.81819 13.9995 8.31584V3.89475C13.999 3.3924 13.7992 2.91078 13.444 2.55556C13.0888 2.20035 12.6071 2.00055 12.1048 2Z"
        fill={color}
      />
    </svg>
  );
};

export default CopyToClipboardIcon;
