import React from 'react';

import PropTypes from 'prop-types';

import {getCountriesWithPinnedList} from '@renofi/utilities/src/countries';

import SelectField from '../SelectField';

const COUNTRIES_OPTIONS = getCountriesWithPinnedList();
function CountriesField({
  disabled,
  label,
  onChange,
  placeholder,
  tabIndex,
  value,
  searchable,
  searchPlaceholder,
  ...props
}) {
  return (
    <SelectField
      disabled={disabled}
      label={label}
      name="country"
      onChange={onChange}
      options={COUNTRIES_OPTIONS}
      placeholder={placeholder}
      tabIndex={tabIndex}
      value={value}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      {...props}
    />
  );
}

CountriesField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export default CountriesField;
