import {useMemo} from 'react';

import {assoc} from 'ramda';

import {compose} from '@renofi/recompose';

import {getCookie} from './cookies';

export default compose((keys) => (BaseComponent) => (props) => {
  const cookies = useMemo(
    () => keys.reduce((obj, key) => assoc(key, getCookie(key), obj), {}),
    [keys],
  );

  return <BaseComponent {...props} cookies={cookies} />;
});
