import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_GCDD_DOCUMENT} from '../../../mutations';

export default function useSubmitGcddDocument({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitGcddDocument, {data, loading, error}] = useMutation(
    SUBMIT_GCDD_DOCUMENT,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitGcddDocument?.contractor;

  return {submitGcddDocument, response, loading, error};
}
