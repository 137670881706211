import styled from '@emotion/styled';

import {
  actionExtraLight,
  actionLight,
  basic80,
  maxSmall,
  white,
} from '@renofi/theme';
import {slideInComponent} from '@renofi/utilities/src/animations';

import Text from '../Text';
import Flex from '../Flex';

export const Action = styled(Text)({
  color: actionLight,
  cursor: 'pointer',
  fontSize: 14,
  fontWeight: 'bold',
  '&:hover': {
    color: actionExtraLight,
  },
  '&:active': {
    color: white,
  },
});

export const CircleText = styled(Text)({
  boxSizing: 'border-box',
  background: basic80,
  borderRadius: '100px',
  marginRight: '8px',
  display: 'flex',
  width: '34px',
  height: '34px',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
});

const SIDEBAR_WIDTH = 308;
export const Wrapper = styled(Flex)(
  {
    animation: `${slideInComponent()} 80ms ease-out`,
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    borderRadius: 8,
    color: white,
    fontSize: 16,
    lineHeight: '19px',
    position: 'fixed',
    bottom: 24,
    zIndex: 1000,
    [maxSmall]: {
      left: 10,
      right: 10,
      bottom: 68,
      top: 'unset',
    },
  },
  ({center, width}) => ({
    left: center ? `calc(50% - ${width / 2}px + ${SIDEBAR_WIDTH / 2}px)` : 24,
    maxWidth: width ? width : 400,
    width: width ? width : 400,
  }),
);

export const CloseWrapper = styled(Flex)(() => ({
  boxSizing: 'border-box',
  margin: '-16px',
  width: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderLeft: 'solid 1px #676767',
  cursor: 'pointer',
}));
