import React from 'react';

import PropTypes from 'prop-types';

import Text from '../Text';

import {Placeholder} from './styled';

const NoDataPlaceholder = ({text = 'No data', colSpan = 1}) => {
  return (
    <tr>
      <td colSpan={colSpan} style={{padding: 0}}>
        <Placeholder>
          <Text>{text}</Text>
        </Placeholder>
      </td>
    </tr>
  );
};

NoDataPlaceholder.propTypes = {
  text: PropTypes.string,
  colSpan: PropTypes.number,
};

export default NoDataPlaceholder;
