import styled from '@emotion/styled';

import BackIcon from '@renofi/icons/src/BackIcon';
import Cross from '@renofi/icons/src/Cross';
import {actionRegular, white} from '@renofi/theme';

import Flex from '../../Flex';

export const Back = styled(BackIcon)({
  zoom: 0.6,
  cursor: 'pointer',
  marginRight: 16,
});

export const ButtonBG = styled(Flex)({
  width: 40,
  height: 40,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: actionRegular,
  cursor: 'pointer',
  position: 'fixed',
  top: '40%',
  borderRadius: 40,
  zIndex: 1900,
});

export const Prev = styled(BackIcon)({
  color: white,
  zoom: 0.65,
});

export const Next = styled(BackIcon)({
  transform: 'rotate(180deg)',
  color: white,
  zoom: 0.65,
});

export const StyledCross = styled(Cross)({
  path: {
    fill: 'white',
  },
  '&:hover': {
    cursor: 'pointer',
  },
});
