import {assoc, pathOr, propEq} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';
import {getAllFacets} from '@renofi/utilities/src/facets';

import {MARK_TASK_DATA_READY_FOR_PROCESSING} from '../mutations';
import {GET_PROJECT_TASKS} from '../queries';

import withMutation from './withMutation';
import withApollo from './withApollo';

const facets = getAllFacets();

export default () =>
  compose(
    withApollo,
    withMutation(MARK_TASK_DATA_READY_FOR_PROCESSING, {
      name: 'markTaskDataAsReadyForProcessing',
    }),
    withHandlers({
      markTaskDataAsReadyForProcessing:
        ({client, markTaskDataAsReadyForProcessing}) =>
        async ({projectId, taskId}) => {
          const response = await markTaskDataAsReadyForProcessing({
            variables: {taskId},
          });
          const task = pathOr(
            null,
            ['data', 'markTaskDataAsReadyForProcessing', 'task'],
            response,
          );
          if (task) {
            const {status} = task;
            await Promise.all(
              facets.map(async (facet) => {
                const {projectTasks = []} =
                  client.readQuery({
                    query: GET_PROJECT_TASKS,
                    variables: {projectId, facet},
                  }) || {};
                const containsTask = projectTasks.some(propEq('id', taskId));

                if (!containsTask) return;

                client.writeQuery({
                  query: GET_PROJECT_TASKS,
                  variables: {projectId, facet},
                  data: {
                    projectTasks: projectTasks.map((task) => {
                      return task.id === taskId
                        ? assoc('status', status, task)
                        : task;
                    }),
                  },
                });
              }),
            );
          }
        },
    }),
  );
