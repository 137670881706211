import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme';

import Flex from '../../Flex';

export const TabsContainer = styled(Flex)({
  [maxSmall]: {
    width: '100%',
  },
});
