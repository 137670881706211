import React, {Fragment, useEffect, useState} from 'react';

import {AssignGcddAnalystModal} from 'modules/modals';

import {Box, Flex, Link} from '@renofi/components-internal';
import {formatDateTime} from '@renofi/utilities/src/dates';
import PERMISSIONS from '@renofi/utilities/src/permissions';
import InfoIcon from '@renofi/icons/src/Info';
import {actionLight} from '@renofi/theme/src/colors';
import {useGcddReviewById, useInternalUsersByPermission} from '@renofi/graphql';

import {
  StatusBarItemContainer,
  StatusBarItemLabel,
  StatusBarItemValue,
  StatusBarWrapper,
} from '../styled';

import {AssignButton} from './styled';
import {DOCUMENT_TABS, QUESITONNAIRE_STATUS_TABS} from './constants';
import {getTitle, isReturnedAsPdf, extractAssignedToInfo} from './utils';
import useGetCurrentTab from './useGetCurrentTab';

function GcddReviewStatusBar() {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const {fetch, gcddReview} = useGcddReviewById({lazy: true});
  const {searchInternalUsersByPermission, internalUsersByPermission} =
    useInternalUsersByPermission();

  const {questionnaire = {}, assignedTo, assignedAt} = gcddReview || {};
  const {createdAt, lastSentAt, returnedAt, reviewedAt} = questionnaire || {};

  const [gcddReviewId, currentGcddTab] = useGetCurrentTab();

  const isDocumentsTab = DOCUMENT_TABS.includes(currentGcddTab);
  const showQuestionnaireStatus =
    QUESITONNAIRE_STATUS_TABS.includes(currentGcddTab);

  const showPdfLink = isReturnedAsPdf({
    questionnaire,
  });
  const title = getTitle(questionnaire);

  const {assignedToId, assignedToText, assignedToButtonText} =
    extractAssignedToInfo(assignedTo);

  const toggleAssignModalOpen = (isOpen) => {
    setIsAssignModalOpen(isOpen);
  };

  useEffect(() => {
    if (!gcddReviewId) {
      return;
    }

    fetch({variables: {id: gcddReviewId}});
  }, [gcddReviewId]);

  useEffect(() => {
    searchInternalUsersByPermission({
      variables: {permission: PERMISSIONS.GCDD_EDIT},
    });
  }, []);

  if (!gcddReview || isDocumentsTab) {
    return null;
  }

  return (
    <StatusBarWrapper>
      {showQuestionnaireStatus && (
        <Fragment>
          <Flex alignItems="center">
            <InfoIcon color={actionLight} />
            <Box color={actionLight} ml={3}>
              {title}
              {!showPdfLink ? '.' : null}
              {showPdfLink && (
                <>
                  &nbsp;as PDF. Add Details&nbsp;
                  <Link to={`/dd-requests/${gcddReviewId}/details`}>here.</Link>
                </>
              )}
            </Box>
          </Flex>

          <Flex>
            <StatusBarItemContainer>
              <StatusBarItemLabel>Questionnaire created</StatusBarItemLabel>
              <StatusBarItemValue>
                {formatDateTime(createdAt)}
              </StatusBarItemValue>
            </StatusBarItemContainer>

            <StatusBarItemContainer>
              <StatusBarItemLabel>Date last sent</StatusBarItemLabel>
              <StatusBarItemValue>
                {formatDateTime(lastSentAt)}
              </StatusBarItemValue>
            </StatusBarItemContainer>

            {returnedAt && (
              <StatusBarItemContainer>
                <StatusBarItemLabel>Date returned</StatusBarItemLabel>
                <StatusBarItemValue>
                  {formatDateTime(returnedAt)}
                </StatusBarItemValue>
              </StatusBarItemContainer>
            )}

            {reviewedAt && (
              <StatusBarItemContainer>
                <StatusBarItemLabel>Date reviewed</StatusBarItemLabel>
                <StatusBarItemValue>
                  {formatDateTime(reviewedAt)}
                </StatusBarItemValue>
              </StatusBarItemContainer>
            )}

            <StatusBarItemContainer>
              <StatusBarItemLabel>Analyst</StatusBarItemLabel>
              <StatusBarItemValue>
                {assignedToText}
                {assignedAt && <>&nbsp;&nbsp;{formatDateTime(assignedAt)}</>}
              </StatusBarItemValue>
            </StatusBarItemContainer>

            <AssignButton onClick={() => toggleAssignModalOpen(true)}>
              {assignedToButtonText}
            </AssignButton>

            {isAssignModalOpen && (
              <AssignGcddAnalystModal
                onClose={() => toggleAssignModalOpen(false)}
                users={internalUsersByPermission}
                selectedUserId={assignedToId}
                gcddReviewId={gcddReviewId}
              />
            )}
          </Flex>
        </Fragment>
      )}
    </StatusBarWrapper>
  );
}

export default GcddReviewStatusBar;
