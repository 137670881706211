import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';
import {getLocation} from '@renofi/utilities/src/window';

export const PREFIX_DEV = '[DEV]';
export const PREFIX_STAG = '[STAGING]';

const TITLE = 'Renofi';

const STAGING =
  /^https:\/\/[a-z0-9\-_]+\.(renofistaging|renofitesting)?\.(app|com)/;

export const getPreviewPrefix = () => {
  const {host, hostname} = getLocation() || {};
  const [, previewId] =
    (host || hostname).match(/deploy-preview-([0-9]+)/) || [];
  return `[preview #${previewId}]`;
};

export const isStagingOrigin = () => {
  const {origin} = getLocation() || {};
  return Boolean(origin?.match(STAGING));
};

export default () => {
  const {NODE_ENV, REACT_APP_SERVICE_NAME: APP = ''} = process.env;
  const appName = APP.split('-')[0] || '';
  const suffix = appName ? `(${appName})` : '';

  switch (true) {
    case isDeployPreviewUrl():
      return [getPreviewPrefix(), suffix].join(' ').trim();
    case NODE_ENV === 'development':
      return [PREFIX_DEV, TITLE, suffix].join(' ').trim();
    case isStagingOrigin():
      return [PREFIX_STAG, TITLE, suffix].join(' ').trim();
    default:
      return TITLE;
  }
};
