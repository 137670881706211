import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {RETURN_FEASIBILITY_REVIEW} from '../mutations';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(RETURN_FEASIBILITY_REVIEW, {
      name: 'returnFeasibilityReview',
    }),
    withHandlers({
      returnFeasibilityReview:
        ({returnFeasibilityReview}) =>
        async (variables) => {
          const response = await returnFeasibilityReview({
            variables,
          });
          const feasibilityReview = pathOr(
            null,
            ['data', 'returnFeasibilityReview', 'feasibilityReview'],
            response,
          );
          if (feasibilityReview) {
            // ignore
          }
          return feasibilityReview;
        },
    }),
  );
