import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose';

import {GET_EXCEPTION_REQUEST} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    withQuery({
      query: GET_EXCEPTION_REQUEST,
      props: ({data: {exceptionRequestById: exceptionRequest}, ...props}) => ({
        exceptionRequest,
        ...props,
      }),
      options: options || ((props) => ({variables: {id: props.id}})),
    }),
    mapProps(mapper),
  );
