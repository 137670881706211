import React, {useCallback, useEffect, useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {find, pick, propEq, propOr, sortBy, prop} from 'ramda';

import {useProjectTasks, useTaskTemplates} from '@renofi/graphql';
import {
  Form,
  Box,
  Text,
  SelectField,
  Textarea,
  TextField,
} from '@renofi/components-internal';

import {generateTemplateOptions, isTaskEmpty} from './utils';

const EMPTY_TASK = {
  details: '',
  title: '',
  requirements: '',
};

function CreateEditTaskForm({onChange, taskId, isNewTask}) {
  const {projectId} = useParams();
  const {taskTemplates} = useTaskTemplates();
  const query = useProjectTasks({variables: {projectId}});
  const tasks = propOr([], 'tasks', query);
  const [task, setTask] = useState(null);
  const [templateId, setTemplateId] = useState(null);

  const taskTypes = useMemo(() => {
    const generated = generateTemplateOptions(tasks, taskTemplates);
    return sortBy(prop('label'), generated);
  }, [tasks, taskTemplates]);

  useEffect(() => {
    const newTask = taskId ? find(propEq('id', taskId), tasks) : EMPTY_TASK;

    setTask(newTask);
  }, [taskId, tasks]);

  const onSubmit = useCallback(() => {
    onChange({task});
  }, [task]);

  const onChangeTemplate = (templateId) => {
    const template = taskTemplates.find(propEq('id', templateId));

    const newTask = {
      ...task,
      ...pick(
        ['title', 'details', 'requirements', 'informationKind', 'taskType'],
        template,
      ),
    };

    setTemplateId(templateId);
    setTask(newTask);
    onChange({task: newTask});
  };

  const onChangeValue = (value, key) => {
    const newTask = {...task, [key]: value};
    setTask(newTask);
    onChange({task: newTask});
  };

  return (
    <Box height={425}>
      <Text fontSize={24} mb={24}>
        {isNewTask ? 'Add new' : 'Edit'} task
      </Text>
      <Form onSubmit={onSubmit}>
        {!taskId && (
          <SelectField
            mb={40}
            name="taskTemplate"
            label="Task Type"
            placeholder="Select task type"
            options={taskTypes}
            onChange={(value) => onChangeTemplate(value)}
            value={templateId}
          />
        )}
        <TextField
          disabled={!isNewTask || isTaskEmpty(task?.taskType)}
          active
          label="Task title"
          name="requestTitle"
          value={task?.title || ''}
          onChange={(v) => onChangeValue(v, 'title')}
        />
        <Textarea
          disabled={isNewTask && isTaskEmpty(task?.taskType)}
          label="Description"
          name="requestDetails"
          value={task?.details || ''}
          onChange={(v) => onChangeValue(v, 'details')}
        />
        {isNewTask ? null : (
          <Textarea
            label="Requirements"
            value={task?.requirements || ''}
            onChange={(v) => onChangeValue(v, 'requirements')}
          />
        )}
      </Form>
    </Box>
  );
}

CreateEditTaskForm.propTypes = {
  isNewTask: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string,
};

export default CreateEditTaskForm;
