import styled from '@emotion/styled';

import {
  actionLight,
  basic75,
  basic80,
  white,
  black,
  renofiBlue,
} from '@renofi/theme';

import Flex from '../Flex';
import Button from '../Button';

export const TOOLTIP_CLASSNAME = 'dropdown-button-react-tooltip';

export const ArrowButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 0,
  paddingRight: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  width: 32,
});

export const TheButton = styled(Button)(() => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
}));

export const ButtonWrapper = styled(Flex)(() => ({
  alignItems: 'center',

  [`+ .${TOOLTIP_CLASSNAME}`]: {
    background: white,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.20)',
    color: renofiBlue,
    padding: 0,
  },
}));

export const Item = styled(Flex)(
  {
    alignItems: 'center',
    height: 40,
    width: '100%',
    ':last-of-type': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  ({variant, disabled}) => ({
    borderBottomColor: variant === 'primary' ? basic75 : 'transparent',
    color: variant === 'primary' ? actionLight : basic80,

    ':hover': {
      color: variant === 'primary' ? basic80 : black,
      // backgroundColor: variant === 'primary' ? actionLight : actionExtraLight,
    },
    ...(disabled
      ? {pointerEvents: 'none', color: 'rgba(0,0,0,0.2)'}
      : {pointerEvents: 'all'}),
  }),
);
