import {isNil, not, pipe} from 'ramda';

import {getPlaceDetails} from '@renofi/utilities/src/places';

export const mapPredictions = (predictions = []) =>
  predictions.map(
    ({
      description,
      place_id: placeId,
      structured_formatting: {main_text: primary, secondary_text: secondary},
    }) => ({
      placeId,
      primary,
      secondary,
      description,
    }),
  );

export const getPlaceById = async (placeId, placeFields) => {
  const details = await getPlaceDetails(placeId, placeFields);
  const {address_components: components, geometry} = details;
  let component,
    streetNumber,
    streetName,
    city,
    country = '',
    county = '',
    state,
    zipCode,
    lat,
    lng;
  component = components.find(({types}) => types.includes('street_number'));
  if (component) {
    streetNumber = component.long_name;
  }
  component = components.find(({types}) => types.includes('route'));
  if (component) {
    streetName = component.long_name;
  }
  component = components.find(({types}) => types.includes('locality'));
  if (component) {
    city = component.long_name;
  }
  component = components.find(({types}) =>
    types.includes('administrative_area_level_1'),
  );
  if (component) {
    state = component.short_name;
  }
  component = components.find(({types}) =>
    types.includes('administrative_area_level_2'),
  );
  if (component) {
    county = component.long_name;
  }
  component = components.find(({types}) => types.includes('country'));
  if (component) {
    country = component.short_name;
  }
  component = components.find(({types}) => types.includes('postal_code'));
  if (component) {
    zipCode = component.long_name;
  }
  if (geometry?.location) {
    try {
      lat =
        typeof geometry.location.lat === 'function'
          ? geometry.location.lat()
          : geometry.location.lat;
      lng =
        typeof geometry.location.lng === 'function'
          ? geometry.location.lng()
          : geometry.location.lng;
    } catch (error) {
      // ignore
    }
  }
  const address = [streetNumber, streetName].filter(pipe(isNil, not)).join(' ');
  return {
    address,
    city,
    county,
    country,
    details,
    state,
    zipCode,
    lat,
    lng,
  };
};
