import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_REFERENCE_SCORE} from '../../../mutations';

export default function useSubmitReferenceScore({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitReferenceScore, {data, loading, error}] = useMutation(
    SUBMIT_REFERENCE_SCORE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitReferenceScore?.reference;

  return {submitReferenceScore, response, loading, error};
}
