import {compose} from '@renofi/recompose';

import {GET_LENDER_OWN_REPORT} from '../queries';

import withQuery from './withQuery';

const date = new Date();
date.setFullYear(date.getFullYear() - 1);

export default ({options} = {}) =>
  compose(
    withQuery({
      query: GET_LENDER_OWN_REPORT,
      props: ({data: {lenderOwnReport}, ...props}) => ({
        lenderOwnReport,
        ...props,
      }),
      options: options || {variables: {from: date, to: new Date()}},
    }),
  );
