import {documentWithAllProps} from '../document';

export default `
coborrowerOwned
documentIds
documents {
  ${documentWithAllProps}
}
existingMortgageLoan
homeownerMonthlyInsurance
homeownersAssociationFee
homeownersAssociationFeesExist
homeownersAssociationFeesIncluded
id
mortgageAccountNumber
mortgageBalance
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyInsurance
mortgageMonthlyPayment
mortgageStatementDate
otherMonthlyExpenses
primaryBorrowerOwned
propertyKind
propertyMonthlyTaxes
propertyStructureType
propertyUseType
taskId
taxAndInsuranceIncluded
yearPurchased
`;
