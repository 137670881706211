import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {GET_EXCEPTION_REQUEST_PREVIEW} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getExceptionRequestPreview:
        ({client}) =>
        async (exceptionRequest) => {
          const result = await client.query({
            query: GET_EXCEPTION_REQUEST_PREVIEW,
            variables: {exceptionRequest},
          });
          return pathOr(
            null,
            ['data', 'exceptionRequestSubmittedLenderNotification'],
            result,
          );
        },
    }),
  );
