import styled from '@emotion/styled';

import {basic55, basic80} from '@renofi/theme';

import Box from '../Box';
import Flex from '../Flex';
import Heading from '../Heading';

export const Container = styled(Flex)({
  flexDirection: 'column',
});

export const Title = styled(Heading)({
  display: 'flex',
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 'bold',
  color: basic80,
  marginBottom: 8,
  alignItems: 'center',
});

export const Content = styled(Box)({
  fontSize: 16,
  lineHeight: '24px',
  color: basic55,
});
