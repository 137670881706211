import {useMemo, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {Arrow} from '@renofi/icons';
import {actionRegular, basic20, basic55, useTheme} from '@renofi/theme';
import noop from '@renofi/utilities/src/noop';

import Box from '../Box';
import {ReactTooltip} from '../Tooltip';
import TextField from '../TextField';
import Icon from '../Icon';

import {
  ArrowButton,
  TheButton,
  ButtonWrapper,
  TOOLTIP_CLASSNAME,
  Item,
} from './styled';

const DropdownButton = ({
  arrowColor,
  arrowCss = {},
  containerCss = {},
  buttonLabel = 'Choose action',
  disabled,
  disableAll = false,
  id,
  labelCss = {},
  offset = 0,
  onButtonHover = noop,
  onButtonBlur = noop,
  onButtonClick = noop,
  onSelect = noop,
  options = [],
  place = 'bottom-end',
  searchable = false,
  searchInputProps = {},
  selectedIcon = 'check',
  showSelected = false,
  small,
  unselectedIcon = 'bulletList',
  value: selectedValue = null,
  variant,
}) => {
  const {styles} = useTheme('button', {
    defaultVariant: 'primary',
    disabled: disabled || disableAll,
    variant,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const tooltipRef = useRef();
  const wrapperRef = useRef();
  const bounds = wrapperRef?.current?.getBoundingClientRect() || {};

  const onClickItem = (value) => {
    onSelect(value);
    tooltipRef?.current?.close();
  };

  const onClickToggle = () => {
    if (!tooltipRef?.current) {
      return;
    }

    setIsOpen(!isOpen);
    const {isOpen: isTooltipOpen, open, close} = tooltipRef.current || {};
    if (isTooltipOpen) {
      close();
    } else {
      open();
    }
  };

  const availableOptions = useMemo(() => {
    if (!searchable) {
      return options;
    }

    return options.filter((opt) => {
      return opt.label.toLowerCase().match(searchBy.toLowerCase());
    });
  }, [options, searchable, searchBy]);

  return (
    <>
      <ButtonWrapper ref={wrapperRef} id={id}>
        <TheButton
          disabled={disabled}
          onBlur={onButtonBlur}
          onHover={onButtonHover}
          onClick={onButtonClick}
          small={small}
          variant={variant}
          css={labelCss}>
          {buttonLabel}
        </TheButton>

        <ArrowButton
          disabled={disableAll ? disabled : false}
          css={arrowCss}
          onClick={onClickToggle}
          small={small}
          variant={variant}>
          <Arrow
            color={
              disabled && disableAll ? basic55 : arrowColor || styles?.color
            }
            direction="down"
          />
        </ArrowButton>
      </ButtonWrapper>

      <ReactTooltip
        anchorSelect={`#${id}`}
        className={TOOLTIP_CLASSNAME}
        clickable
        closeEvents={{mouseout: false, blur: false}}
        content={
          <Box p={2} css={containerCss}>
            {searchable && (
              <Box>
                <TextField
                  active
                  canClear={Boolean(searchBy)}
                  clearValue=""
                  leftIcon="glass"
                  mb={2}
                  onChange={setSearchBy}
                  placeholder="Search"
                  value={searchBy}
                  {...searchInputProps}
                />
              </Box>
            )}
            {availableOptions.map(({label, value, disabled = false}) => {
              const isSelected = value === selectedValue;
              return (
                <Item
                  py={0}
                  key={value}
                  selected={isSelected}
                  variant={variant}
                  disabled={disabled}
                  onClick={() => onClickItem(value)}>
                  {showSelected && (
                    <Icon
                      forceEmpty
                      width={16}
                      name={isSelected ? selectedIcon : unselectedIcon}
                      color={isSelected ? actionRegular : basic20}
                    />
                  )}
                  <Box ml={showSelected ? 2 : 0}>{label}</Box>
                </Item>
              );
            })}
          </Box>
        }
        noArrow
        offset={offset}
        openEvents={{click: true}}
        opacity={1}
        place={place}
        ref={tooltipRef}
        style={{minWidth: bounds?.width || 'auto'}}
      />
    </>
  );
};

DropdownButton.propTypes = {
  arrowColor: PropTypes.string,
  arrowCss: PropTypes.object,
  containerCss: PropTypes.object,
  buttonLabel: PropTypes.any,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelCss: PropTypes.object,
  offset: PropTypes.number,
  onButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  place: PropTypes.string,
  searchable: PropTypes.bool,
  searchInputProps: PropTypes.object,
  selectedIcon: PropTypes.string,
  showSelected: PropTypes.bool,
  small: PropTypes.bool,
  unselectedIcon: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
};

export default DropdownButton;
