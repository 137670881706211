import {assoc, find, map, pipe, propEq, propOr} from 'ramda';

import {useUsersByIds} from '../chainlink';

const getNotificationPreferences = propOr([], 'notificationPreferences');

export default function useMapLenderUsers(lender) {
  const ids = lender?.users?.map(({id}) => id);
  const {users = []} = useUsersByIds({variables: {ids}});
  const lenderPreferences = pipe(
    getNotificationPreferences,
    map(assoc('active', false)),
  )(lender);

  return users.map((user) => {
    const userPreferences = getNotificationPreferences(user);

    return {
      ...user,
      notificationPreferences: lenderPreferences.reduce((arr, pref) => {
        const active = pipe(
          find(propEq('notificationType', pref.notificationType)),
          propOr(false, 'active'),
        )(userPreferences);
        return arr.concat(assoc('active', active, pref));
      }, []),
    };
  });
}
