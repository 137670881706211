import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {MARK_FEASIBILITY_REVIEW_AS_COMPLETED} from '../mutations';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(MARK_FEASIBILITY_REVIEW_AS_COMPLETED, {
      name: 'markFeasibilityReviewAsCompleted',
    }),
    withHandlers({
      markFeasibilityReviewAsCompleted:
        ({markFeasibilityReviewAsCompleted}) =>
        async (variables) => {
          const response = await markFeasibilityReviewAsCompleted({
            variables,
          });
          const feasibilityReview = pathOr(
            null,
            ['data', 'markFeasibilityReviewAsCompleted', 'feasibilityReview'],
            response,
          );
          if (feasibilityReview) {
            // ignore
          }
          return feasibilityReview;
        },
    }),
  );
