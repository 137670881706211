import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {propEq, reject} from 'ramda';

import Cross from '@renofi/icons/src/CrossCircle';
import {basic20, dangerLight, dangerRegular} from '@renofi/theme/src/colors';

import Badge from '../../../Badge';
import Button from '../../../Button';
import Flex from '../../../Flex';
import Text from '../../../Text';
import Tooltip from '../../../Tooltip';
import Progress from '../../Component';

import {
  ConfirmDeleteWrapper,
  Dot,
  Hover,
  Info,
  InfoLabelBox,
  Label,
  Level,
  Status,
  Wrapper,
} from './styled';

const Remove = (props) => {
  const [hover, setHover] = useState(false);
  return (
    <Cross
      color={hover ? dangerLight : basic20}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    />
  );
};

const Item = ({
  compact,
  complete,
  canConfirmRemove = false,
  confirmRemoveLabel = 'Are you sure you want to delete this item?',
  current,
  flat,
  value,
  label,
  level = 0,
  info,
  children = [],
  clickable,
  removable,
  simple,
  onRemove,
  onSelect,
  tooltipContent = null,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const visibleChildren = reject(propEq('hidden', true), children);
  const nextLevel = level + 1;
  const showLabel = label && !info;
  const showInfo = info && !label;
  const id = props.id || value;

  const onClickRemove = () => {
    if (!canConfirmRemove) {
      return onRemove();
    }
    setShowConfirmRemove(true);
  };

  return (
    <Level flat={flat} complete={complete} level={level}>
      <Flex
        width={1}
        alignItems="center"
        justifyContent="space-between"
        css={{position: 'relative', cursor: clickable ? 'pointer' : 'default'}}
        onClick={() => onSelect(id)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        {clickable && hover && <Hover />}
        <Status level={level}>
          <Tooltip
            disable={!compact}
            place="right"
            content={tooltipContent || value}>
            <Dot
              active={current}
              complete={complete}
              hover={clickable && hover}
              small={level > 0}
            />
          </Tooltip>
          <Label
            hidden={compact}
            active={current || complete}
            level={level}
            simple={simple}>
            {value}
          </Label>
        </Status>
        {(showLabel || showInfo || removable) && (
          <InfoLabelBox pl="8px">
            {showLabel && (
              <Flex pl="8px" alignItems="center">
                <Badge
                  small
                  fontSize="10px"
                  lineHeight="12px"
                  fontWeight="bold"
                  variant={complete || current ? 'info' : 'default'}>
                  {label}
                </Badge>
              </Flex>
            )}
            {showInfo && <Info title={info}>{info}</Info>}
            {removable && (
              <Flex pl="8px" alignItems="center">
                <Remove
                  width={16}
                  height={16}
                  css={{cursor: 'pointer'}}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClickRemove();
                  }}
                />
              </Flex>
            )}
          </InfoLabelBox>
        )}
      </Flex>
      {showConfirmRemove && (
        <ConfirmDeleteWrapper>
          <Text color={dangerRegular} fontSize={14} mb={3} width={1}>
            {confirmRemoveLabel}
          </Text>

          <Flex>
            <Button css={{marginRight: 8}} onClick={onRemove} variant="danger">
              Yes, delete
            </Button>
            <Button
              onClick={() => setShowConfirmRemove(false)}
              variant="outline">
              Cancel
            </Button>
          </Flex>
        </ConfirmDeleteWrapper>
      )}
      {!flat && visibleChildren && Boolean(visibleChildren.length) && (
        <Wrapper
          pl={compact ? 0 : nextLevel < 2 ? 32 : 16}
          mt={nextLevel < 2 ? 14 : '8px'}>
          <Progress
            compact={compact}
            flat={flat}
            data={visibleChildren}
            level={nextLevel}
            onRemoveItem={onRemove}
            onSelectItem={onSelect}
          />
        </Wrapper>
      )}
    </Level>
  );
};

Item.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canConfirmRemove: PropTypes.bool,
  compact: PropTypes.bool,
  complete: PropTypes.bool,
  confirmRemoveLabel: PropTypes.node,
  current: PropTypes.bool,
  flat: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.node,
  level: PropTypes.number,
  info: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.object),
  clickable: PropTypes.bool,
  removable: PropTypes.bool,
  simple: PropTypes.bool,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  tooltipContent: PropTypes.string,
};

export default React.memo(Item);
