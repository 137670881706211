import React from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';

import {
  Badge,
  Button,
  Card,
  DateFormat,
  HeadCell,
  Modal,
  Table,
  Textarea,
  TextField,
  TimeAgo,
} from '@renofi/components-internal';
import {File} from '@renofi/icons';
import {useScreenSize} from '@renofi/utilities';
import {basic80} from '@renofi/theme';

const Comments = ({
  action,
  hidden,
  loading,
  disabled,
  comment,
  comments,
  message,
  subject,
  sortColumn,
  sortDirection,
  onCloseModal,
  onCancelSubmit,
  onRemoveAccept,
  onRemoveReject,
  onSortComments,
  onChangeMessage,
  onChangeSubject,
  onSubmitComment,
  onCreateComment,
  onRemoveComment,
  onSelectComment,
  onUpdateComment,
}) => {
  const {isMobile} = useScreenSize();
  const isReverse = sortDirection === 'asc';

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Box width={isMobile ? '100%' : 1140} mt={24}>
          <Card
            title={
              <Flex
                width={1}
                justifyContent="space-between"
                alignItems="center">
                <Flex alignItems="center">
                  <File />
                  <Text ml={16}>Comments</Text>
                </Flex>
                <Button onClick={() => onCreateComment()}>
                  Create comment
                </Button>
              </Flex>
            }
            p={0}>
            <Table css={{marginBottom: 0}} hover>
              <thead>
                <tr>
                  <HeadCell
                    sortable
                    active={sortColumn === 'createdAt'}
                    reverse={isReverse}
                    onClick={() => onSortComments('createdAt')}>
                    Date added
                  </HeadCell>
                  <HeadCell
                    sortable
                    active={sortColumn === 'createdBy'}
                    reverse={isReverse}
                    onClick={() => onSortComments('createdBy')}>
                    Added by
                  </HeadCell>
                  <HeadCell>Subject</HeadCell>
                </tr>
              </thead>
              <tbody>
                {comments.map(({id, createdAt, subject, user}) => (
                  <tr key={id} onClick={() => onSelectComment(id)}>
                    <td css={{textTransform: 'capitalize'}}>
                      {createdAt && <TimeAgo date={new Date(createdAt)} />}
                    </td>
                    <td>
                      {user?.firstName} {user?.lastName}
                    </td>
                    <td>{subject}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Box>
      </Flex>
      <Modal
        lock
        fixed
        p={24}
        width={600}
        show={['create', 'update'].includes(action)}
        footer={
          <Flex justifyContent="flex-end" alignItems="center">
            <Box mr="4px">
              <Button variant="secondary" onClick={() => onCancelSubmit()}>
                Cancel
              </Button>
            </Box>
            <Box ml="4px">
              <Button
                data-testid="create-comment-button"
                loading={loading}
                disabled={disabled}
                onClick={() => onSubmitComment()}>
                {action === 'create' && 'Create comment'}
                {action === 'update' && 'Save changes'}
              </Button>
            </Box>
          </Flex>
        }
        header="Create a new comment"
        onClose={() => onCancelSubmit()}>
        <TextField
          name="comment-subject"
          label="Enter a subject for this note"
          value={subject}
          onChange={onChangeSubject}
        />
        <Textarea
          rows={5}
          value={message}
          label="Message"
          onChange={onChangeMessage}
        />
      </Modal>
      <Modal
        lock
        fixed
        p={24}
        width={600}
        show={['remove', 'select'].includes(action)}
        header={
          <Flex alignItems="center">
            <Text color="#333333" fontSize="10px" lineHeight="12px">
              <Badge css={{padding: 2}} variant="default">
                FROM
              </Badge>
            </Text>
            <Text ml={12} color="#333333" fontSize={18} lineHeight="21px">
              {comment?.user?.firstName} {comment?.user?.lastName} | RenoFi
            </Text>
          </Flex>
        }
        footer={
          <>
            {action === 'remove' && (
              <Flex width={1} alignItems="center">
                <Box>
                  <Button
                    variant="danger"
                    loading={loading}
                    onClick={() => onRemoveAccept()}>
                    Confirm deletion
                  </Button>
                </Box>
                <Box ml={16}>
                  <Button variant="outline" onClick={() => onRemoveReject()}>
                    Cancel
                  </Button>
                </Box>
              </Flex>
            )}
            {action === 'select' && (
              <Flex
                width={1}
                justifyContent="space-between"
                alignItems="center">
                <Box>
                  {!hidden && (
                    <Button variant="danger" onClick={() => onRemoveComment()}>
                      Delete comment
                    </Button>
                  )}
                </Box>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Box mr="4px">
                    {!hidden && (
                      <Button
                        variant="secondary"
                        onClick={() => onUpdateComment()}>
                        Edit comment
                      </Button>
                    )}
                  </Box>
                  <Box ml="4px">
                    <Button onClick={() => onCloseModal()}>Close</Button>
                  </Box>
                </Flex>
              </Flex>
            )}
          </>
        }
        onClose={() => onCloseModal()}>
        {comment && (
          <>
            <Text color="#737373" fontSize={14}>
              <DateFormat value={comment.createdAt} />
            </Text>
            <Text
              color={basic80}
              fontSize={16}
              lineHeight="24px"
              mt={24}
              mb={32}>
              {comment.body}
            </Text>
          </>
        )}
      </Modal>
    </>
  );
};

Comments.propTypes = {
  action: PropTypes.string,
  comment: PropTypes.object,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelSubmit: PropTypes.func.isRequired,
  onRemoveAccept: PropTypes.func.isRequired,
  onRemoveReject: PropTypes.func.isRequired,
  onSortComments: PropTypes.func.isRequired,
  onChangeMessage: PropTypes.func.isRequired,
  onChangeSubject: PropTypes.func.isRequired,
  onSubmitComment: PropTypes.func.isRequired,
  onCreateComment: PropTypes.func.isRequired,
  onRemoveComment: PropTypes.func.isRequired,
  onSelectComment: PropTypes.func.isRequired,
  onUpdateComment: PropTypes.func.isRequired,
};

export default Comments;
