import {compose} from '@renofi/recompose';
import {withProject} from '@renofi/graphql';
import spreadProps from '@renofi/utilities/src/spreadProps';

import withProjectId from './withProjectId';

export default ({spread = false} = {}) =>
  compose(
    withProjectId(),
    withProject(),
    spreadProps(spread ? ['project'] : []),
  );
