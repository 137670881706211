import styled from '@emotion/styled';

import {Box, Link, Text} from '@renofi/components-internal';
import {basic80, minSmall} from '@renofi/theme';

export const Label = styled(Text)({
  marginBottom: 8,
  marginRight: 4,
  color: basic80,
});

export const Value = styled(Link)({
  maxWidth: 300,
  marginBottom: 8,
  marginRight: 8,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const DocumentCard = styled(Box)(({internal}) => {
  const padding = internal ? 0 : 8;
  return {
    width: '100%',

    [minSmall]: {
      width: !internal ? '50%' : '100%',
      paddingLeft: padding,
      ':nth-of-type(2n + 1)': {
        paddingLeft: 0,
        paddingRight: padding,
      },
    },
  };
});
