import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {GET_SIGNED_URLS} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getSignedUrls:
        ({client}) =>
        async (objectNames) => {
          const result = await client.query({
            query: GET_SIGNED_URLS,
            variables: {objectNames},
          });
          return pathOr({}, ['data', 'signedUrls'])(result);
        },
    }),
    withHandlers({
      getThumbnails:
        ({getSignedUrls}) =>
        async (objectNames, size = 'medium') => {
          return getSignedUrls(
            objectNames.map((objectName) => `thumbnails/${size}/${objectName}`),
          );
        },
    }),
  );
