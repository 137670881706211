import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {Button} from '@renofi/components-internal';
import {House} from '@renofi/icons';

const UpdateTableHeader = ({onSubmit}) => (
  <Flex width={1} justifyContent="space-between" alignItems="center">
    <Flex alignItems="center">
      <House />
      <Text ml={16}>Update history</Text>
    </Flex>
    <Button onClick={onSubmit}>Submit update</Button>
  </Flex>
);

UpdateTableHeader.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateTableHeader;
