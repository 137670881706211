import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {GET_UPLOAD_METADATA} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getUploadProperties:
        ({client}) =>
        async (variables) => {
          const result = await client.query({
            query: GET_UPLOAD_METADATA,
            variables,
          });
          return pathOr({}, ['data', 'uploadMetadata'])(result);
        },
    }),
  );
