import {pathOr} from 'ramda';

import {useToggledQuery} from '@renofi/graphql';
import noop from '@renofi/utilities/src/noop';

import {GET_PROJECT_REPORTS} from '../../../queries';

export default function useProjectReports({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECT_REPORTS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const {data, error, loading, fetch, refetching} = response;
  const projectReports = pathOr([], ['projectReports', 'collection'], data);
  const metadata = pathOr(null, ['projectReports', 'metadata'], data);

  return {
    fetch,
    projectReports,
    data,
    loading,
    error,
    metadata,
    refetching,
  };
}
