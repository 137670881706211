import styled from '@emotion/styled';

import omitProps from '@renofi/utilities/src/omitProps';
import {
  actionExtraLight,
  actionRegular,
  dangerExtraLight,
  dangerLight,
  basic20,
  basic55,
  basic80,
} from '@renofi/theme/src/colors';

import Text from '../../../Text';
import Flex from '../../../Flex';

import check from './check.svg';
import dot from './dot.svg';
import dotActive from './dot-active.svg';

const LEVELLED_VALUES = [
  {
    label: {
      fontSize: 14,
      lineHeight: '22px',
      marginLeft: 9,
      whiteSpace: 'nowrap',
    },
    level: {
      marginLeft: 11,
      borderLeftWidth: 1,
    },
    status: {
      height: 23,
      marginLeft: -12,
      position: 'relative',
    },
  },
  {
    label: {
      color: basic55,
      fontSize: 13,
      lineHeight: '15px',
      marginLeft: 8,
    },
    level: {
      paddingBottom: 14,
    },
    status: {
      height: 17,
      marginLeft: -9,
    },
  },
  {
    label: {
      color: basic55,
      fontSize: 12,
      lineHeight: '15px',
      marginLeft: 8,
    },
    level: {
      paddingBottom: 14,
    },
    status: {
      height: 17,
      marginLeft: -9,
    },
  },
];

export const Wrapper = styled(Flex)(
  {
    flexDirection: 'column',
    width: '100%',
  },
  ({duration = 500}) => ({
    transition: `all ${duration}ms ease`,
  }),
);

export const Level = styled.div(
  ({complete, duration = 500, flat, moved, level}) => ({
    ':last-of-type': {
      paddingBottom: 0,
      borderLeftWidth: 0,
    },
    borderLeftStyle: 'dashed',
    borderLeftColor: complete ? actionRegular : basic20,
    ...(level === 0 ? {paddingBottom: flat ? 20 : 28} : {}),
    ...(level > 0
      ? {
          transition: `all ${duration}ms ease`,
          borderLeftWidth: moved ? 0 : 1,
        }
      : {}),
    ...LEVELLED_VALUES[level]?.level,
  }),
);

export const Status = styled(Flex)(({level}) => ({
  alignItems: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...LEVELLED_VALUES[level]?.status,
}));

export const Label = styled(omitProps(['hidden'])(Text))(
  ({active, duration = 500, hidden, level, simple}) => ({
    color: active && simple ? basic80 : basic55,
    transition: `all ${duration * 0.6}ms ease`,
    fontWeight: level === 0 && active && !simple ? 'bold' : 'normal',
    opacity: hidden ? 0 : 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ...LEVELLED_VALUES[level]?.label,
  }),
);

export const Dot = styled.div(
  {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    backgroundColor: 'white',
  },
  ({active, complete, hover, small}) => ({
    width: small ? 17 : 23,
    height: small ? 17 : 23,
    backgroundImage: `url('${active ? dotActive : complete ? check : dot}')`,
    backgroundColor: hover ? actionExtraLight : 'white',
  }),
);

export const Info = styled(Text)({
  paddingLeft: 8,
  cursor: 'pointer',
  color: actionRegular,
  fontSize: 13,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const InfoLabelBox = styled(Flex)({
  position: 'relative',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 64,
  maxWidth: 154,
  textAlign: 'right',
  justifyContent: 'end',
});

export const Hover = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  height: 40,
  backgroundColor: actionExtraLight,
  marginLeft: -24,
  marginRight: -12,
  borderRadius: 4,
});

export const ConfirmDeleteWrapper = styled(Flex)({
  flexDirection: 'column',
  marginTop: 16,
  marginLeft: 24,
  padding: 8,
  backgroundColor: dangerExtraLight,
  border: `1px solid ${dangerLight}`,
  borderRadius: 4,
});
