import {pathOr} from 'ramda';
import {withFeasibilityTab, withViewerLayout} from 'api/hoc';
import {
  withReviewerGroupedFeasibilityStudyItems,
  withReviewerFeasibilityReview,
} from 'modules/feasibility-reviewer';
import {withValidation} from 'modules/feasibility';

import {compose, withHandlers, withProps, withState} from '@renofi/recompose';

import Component from './Component';

export default compose(
  withViewerLayout(),
  withFeasibilityTab(),
  withReviewerFeasibilityReview(),
  withReviewerGroupedFeasibilityStudyItems(),
  withValidation(),
  withState(
    'commentary',
    'setCommentary',
    pathOr('', ['feasibilityReview', 'generalCommentaryAndNotes']),
  ),
  withProps(({feasibilityReview}) => ({
    complete: Boolean(feasibilityReview?.returnedAt),
    costEstimateSuppliedApproved:
      feasibilityReview?.costEstimateSuppliedApproved,
    costEstimateSuppliedNotes: feasibilityReview?.costEstimateSuppliedNotes,
    xactRemodelUrl: feasibilityReview?.xactRemodelUrl,
  })),
  withHandlers({
    onChangeCommentary:
      ({setCommentary}) =>
      (value) => {
        setCommentary(value);
      },
    onUpdateCommentary:
      ({updateFeasibilityReview}) =>
      async (generalCommentaryAndNotes) => {
        await updateFeasibilityReview({
          generalCommentaryAndNotes,
        });
      },
  }),
)(Component);
