import {useEffect} from 'react';

function useClickOutside(handler, theRef) {
  const refs = Array.isArray(theRef) ? theRef : [theRef];

  const handleClickOutside = (event) => {
    const clickedOutside = refs.every((ref) => {
      return ref?.current && !ref.current.contains(event.target);
    });

    if (clickedOutside) {
      handler(event);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handler, refs]);

  return refs;
}

export default useClickOutside;
