import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const AccountBox = ({color = basic20, size = 24}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3676_39956"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill={'#D9D9D9'} />
      </mask>
      <g mask="url(#mask0_3676_39956)">
        <path
          d="M5 17.85C5.9 16.9667 6.94583 16.2708 8.1375 15.7625C9.32917 15.2542 10.6167 15 12 15C13.3833 15 14.6708 15.2542 15.8625 15.7625C17.0542 16.2708 18.1 16.9667 19 17.85V5H5V17.85ZM12 13C12.9667 13 13.7917 12.6583 14.475 11.975C15.1583 11.2917 15.5 10.4667 15.5 9.5C15.5 8.53333 15.1583 7.70833 14.475 7.025C13.7917 6.34167 12.9667 6 12 6C11.0333 6 10.2083 6.34167 9.525 7.025C8.84167 7.70833 8.5 8.53333 8.5 9.5C8.5 10.4667 8.84167 11.2917 9.525 11.975C10.2083 12.6583 11.0333 13 12 13ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM7 19H17V18.75C16.3 18.1667 15.525 17.7292 14.675 17.4375C13.825 17.1458 12.9333 17 12 17C11.0667 17 10.175 17.1458 9.325 17.4375C8.475 17.7292 7.7 18.1667 7 18.75V19ZM12 11C11.5833 11 11.2292 10.8542 10.9375 10.5625C10.6458 10.2708 10.5 9.91667 10.5 9.5C10.5 9.08333 10.6458 8.72917 10.9375 8.4375C11.2292 8.14583 11.5833 8 12 8C12.4167 8 12.7708 8.14583 13.0625 8.4375C13.3542 8.72917 13.5 9.08333 13.5 9.5C13.5 9.91667 13.3542 10.2708 13.0625 10.5625C12.7708 10.8542 12.4167 11 12 11Z"
          fill={color}
        />
        <path
          d="M12 13C12.9667 13 13.7917 12.6583 14.475 11.975C15.1583 11.2917 15.5 10.4667 15.5 9.5C15.5 8.53333 15.1583 7.70833 14.475 7.025C13.7917 6.34167 12.9667 6 12 6C11.0333 6 10.2083 6.34167 9.525 7.025C8.84167 7.70833 8.5 8.53333 8.5 9.5C8.5 10.4667 8.84167 11.2917 9.525 11.975C10.2083 12.6583 11.0333 13 12 13ZM12 11C11.5833 11 11.2292 10.8542 10.9375 10.5625C10.6458 10.2708 10.5 9.91667 10.5 9.5C10.5 9.08333 10.6458 8.72917 10.9375 8.4375C11.2292 8.14583 11.5833 8 12 8C12.4167 8 12.7708 8.14583 13.0625 8.4375C13.3542 8.72917 13.5 9.08333 13.5 9.5C13.5 9.91667 13.3542 10.2708 13.0625 10.5625C12.7708 10.8542 12.4167 11 12 11Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

AccountBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default AccountBox;
