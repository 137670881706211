import {mergeDeepRight} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';
import {withApollo} from '@renofi/graphql';

import {GET_APPLICATION_STATE} from '../queries';

export default () =>
  compose(
    withApollo,
    withHandlers({
      updateMessage:
        ({client}) =>
        (partial) => {
          const {application} = client.readQuery({
            query: GET_APPLICATION_STATE,
          });
          client.writeQuery({
            query: GET_APPLICATION_STATE,
            data: {
              application: {
                ...application,
                message: mergeDeepRight(application.message, partial),
              },
            },
          });
        },
    }),
  );
