import {pathOr} from 'ramda';

import {useToggledQuery} from '@renofi/graphql';
import noop from '@renofi/utilities/src/noop';

import {GET_SALESFORCE_IMPORT_ERRORS} from '../../queries';

export default function useSalesforceImportErrors({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_SALESFORCE_IMPORT_ERRORS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const salesforceImportErrors = pathOr(
    [],
    ['projectById', 'salesforceImportErrors'],
    data,
  );

  return {fetch, salesforceImportErrors, data, loading, error, refetching};
}
