import {
  initDataDog,
  initLogRocket,
  initSegment,
  initSentry,
} from '@renofi/analytics';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import googlePlaces from '@renofi/utilities/src/googlePlaces';

export default async ({config, storage}) => {
  const {DEVELOPMENT, GOOGLE_MAPS_API_KEY, PRODUCTION} = config;

  if (GOOGLE_MAPS_API_KEY) {
    googlePlaces(GOOGLE_MAPS_API_KEY);
  }

  const impersonation = isImpersonation(storage);

  if (impersonation) return;

  if (!DEVELOPMENT) {
    initSegment({integrations: {All: false, Heap: true}});
  }

  if (PRODUCTION) {
    initLogRocket({sanitize: true});
    initSentry();
  }
  initDataDog({ignoreErrors: ['csp_violation']});
};
