import {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {Check, CopyToClipboard as CopyToClipboardIcon} from '@renofi/icons';
import {
  actionDark,
  actionExtraLight,
  basic55,
  basic88,
  successDark,
} from '@renofi/theme';
import {copyToClipboard} from '@renofi/utils';

import Flex from '../Flex';
import Link from '../Link';
import Text from '../Text';
import Tooltip from '../Tooltip';

const CopyToClipboard = ({children, href, target, type}) => {
  const [hover, setHover] = useState(false);
  const [success, setSuccess] = useState(false);

  const onClick = () => {
    setSuccess(true);
    copyToClipboard(target);
  };

  useEffect(() => {
    if (!hover) setTimeout(() => setSuccess(false), 200);
  }, [hover]);

  return (
    <Flex alignItems="center" css={{gap: 4}} fontSize={14} p="2px" m="-2px">
      <Link
        css={{
          color: basic55,
          '&:hover': {color: actionDark, background: 'red'},
        }}
        href={href}
        title={target}>
        {children}
      </Link>

      <Tooltip
        white
        isOpen={hover}
        elementCss={{
          ...(hover
            ? {
                background: actionExtraLight,
                borderRadius: 3,
              }
            : {}),
        }}
        content={
          <Flex alignItems="center" css={{gap: 8}}>
            {success ? (
              <Check width={16} height={16} color={successDark} />
            ) : null}
            <Text color={success ? successDark : basic88}>
              {success ? `${type} Copied` : `Copy ${type}`}
            </Text>
          </Flex>
        }
        place="top">
        <Flex
          css={{cursor: 'pointer'}}
          alignItems="center"
          onClick={onClick}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}>
          <CopyToClipboardIcon color={hover ? actionDark : basic55} />
        </Flex>
      </Tooltip>
    </Flex>
  );
};

CopyToClipboard.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
};

export default CopyToClipboard;
