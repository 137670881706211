import {useContext, useEffect, useState} from 'react';

import {identity} from 'ramda';
import {graphql} from '@apollo/client/react/hoc';

import {
  branch,
  compose,
  mapProps,
  renderComponent,
  withProps,
} from '@renofi/recompose';
import Loader from '@renofi/components-internal/src/Loader';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import {useSystemState} from '../hooks/system';
import getQueryName from '../utils/getQueryName';

import withGraphQlErrorBoundary from './utilities/withGraphQlErrorBoundary';

const composeOptions = (optsOrFunc) => {
  if (typeof optsOrFunc === 'function') {
    return (props) => {
      const options = optsOrFunc(props);
      return {errorPolicy: 'all', ...options};
    };
  }
  return {errorPolicy: 'all', ...optsOrFunc};
};

const isLoading = (key, loader) => (props) => {
  return props[key] && props[key].loading && loader;
};

export default ({
  query,
  name = 'data',
  options = {},
  props = identity,
  loader = renderComponent(Loader),
  breakOnError = true,
}) => {
  const queryName = getQueryName(query);
  const isSystem = queryName === 'system';
  return compose(
    graphql(query, {name, options: composeOptions(options)}),
    withProps((props) => {
      const [graphqlFailed, setGraphqlFailed] = useState(false);
      const {toggleSystemQuery} = useSystemState();
      const config = useContext(ConfigContext);
      const storage = useContext(StorageContext);
      const {error, loading = false} = props[name] || {};

      useEffect(() => {
        if (!isSystem) {
          toggleSystemQuery(queryName, loading);
        }
      }, [loading]);

      return {
        config,
        graphqlError: breakOnError ? error : null,
        graphqlFailed,
        setGraphqlFailed,
        storage,
        queryName,
        toggleSystemQuery,
      };
    }),
    branch(isLoading(name, loader), loader),
    withGraphQlErrorBoundary(query),
    mapProps(props),
  );
};
