import {assoc} from 'ramda';

import {compose, withHandlers, branch} from '@renofi/recompose';

import {GET_PROJECT_TASKS} from '../queries';

import withApollo from './withApollo';
import withBorrowerProject from './withBorrowerProject';

export default () =>
  compose(
    withApollo,
    branch(({admin}) => !admin, withBorrowerProject()),
    withHandlers({
      updateAppraisalTask:
        ({client, project}) =>
        async (taskId, field, value) => {
          const facet = 'appraisal';
          const {projectTasks = []} =
            client.readQuery({
              query: GET_PROJECT_TASKS,
              variables: {projectId: project.id, facet},
            }) || {};
          client.writeQuery({
            query: GET_PROJECT_TASKS,
            variables: {projectId: project.id, facet},
            data: {
              projectTasks: projectTasks.map((task) => {
                return task.id === taskId ? assoc(field, value, task) : task;
              }),
            },
          });
        },
    }),
  );
