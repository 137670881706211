export const IGNORE_ERRORS = [
  'top.GLOBALS', // Random plugins/extensions
  'ResizeObserver loop',
  'The operation timed out',
  'Load failed',
  'Failed to fetch',
  'ChunkLoadError',
  'Loading CSS chunk',
  'typeChunkLoadError',
  /Loading CSS chunk [0-9]+ failed/,
  "Can't find variable: acsbJS",
  'AbortError: The user aborted a request',
];

export const SERVICES_TO_IGNORE_COOKIE_CONSENT = [
  'blueprint-frontend',
  'lenders-frontend',
  'login-frontend',
  'secure-frontend',
];
