import {
  withReviewerFeasibilityReview,
  withReviewerGroupedFeasibilityStudyItems,
} from 'modules/feasibility-reviewer';
import {withValidation} from 'modules/feasibility';

import {compose} from '@renofi/recompose';

import Component from './Component';

export default compose(
  withReviewerFeasibilityReview(),
  withReviewerGroupedFeasibilityStudyItems(),
  withValidation(),
)(Component);
