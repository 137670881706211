import {identity, propOr} from 'ramda';

import {compose, mapProps, withProps} from '@renofi/recompose';

import withProject from './withProject';

export default ({mapper = identity} = {}) =>
  compose(
    withProject(),
    withProps(({project}) => ({
      comments: propOr([], 'comments', project),
    })),
    mapProps(mapper),
  );
