import styled from '@emotion/styled';

import Flex from '../../../../Flex';

export const Wrapper = styled(Flex)({
  padding: '0 16px',
  alignItems: 'center',
  borderRadius: '4px 4px 0px 0px',
  fontSize: 16,
  lineHeight: '19px',
  margin: '0 4px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '@media (max-width: 600px)': {
    width: '100%',
  },
});
