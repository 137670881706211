import {compose, mapProps, withHandlers} from '@renofi/recompose';
import {withStorage} from '@renofi/utilities/src/storage';
import omitProps from '@renofi/utilities/src/omitProps';

import withSettings from './withSettings';
import withUpdateSettings from './withUpdateSettings';

export default () =>
  compose(
    withStorage,
    withSettings(),
    withUpdateSettings(),
    mapProps(({settings, ...props}) => ({
      feasibilityTab: settings?.viewerFeasibilityTab,
      ...props,
    })),
    withHandlers({
      onChangeFeasibilityTab:
        ({storage, updateSettings}) =>
        (viewerFeasibilityTab) => {
          updateSettings({viewerFeasibilityTab});
          storage.setItem('viewer:feasibilityTab', viewerFeasibilityTab);
        },
    }),
    omitProps(['storage', 'updateSettings']),
  );
