import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

import getMimeTypes from '@renofi/utilities/src/mimeTypes';
import UploadIcon from '@renofi/icons/src/Upload';

import {
  Wrapper,
  Placeholder,
  Message,
  Details,
  Content,
  UploadText,
} from './styled';

const defaultAcceptList = getMimeTypes();

const FileInput = ({
  children,
  onAcceptFiles,
  onRejectFiles = () => {},
  accept = defaultAcceptList,
  dragOverScreen,
  uploadText = 'Drop files here or use the Add files button',
  ...props
}) => {
  const [dragOver, setDragOver] = useState(false);
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      setDragOver(false);
      if (acceptedFiles && acceptedFiles.length) {
        onAcceptFiles(acceptedFiles);
      }
      if (rejectedFiles && rejectedFiles.length) {
        onRejectFiles(rejectedFiles);
      }
    },
    onDragLeave: () => {
      setDragOver(false);
    },
    onDragOver: () => {
      setDragOver(true);
    },
    accept: accept.join(', '),
  });
  return (
    <Wrapper {...getRootProps()} {...props}>
      <input {...getInputProps()} />
      {dragOver ? (
        (dragOverScreen && <Content>{dragOverScreen}</Content>) || (
          <Placeholder>
            <Message>Drop files here or use the Add files button</Message>
            <Details>
              The following files are supported: png, jpg, jpeg, gif, bmp and
              pdf.
            </Details>
          </Placeholder>
        )
      ) : (
        <Content>
          {children ? (
            children
          ) : (
            <>
              {<UploadIcon />}
              <UploadText>{uploadText}</UploadText>
            </>
          )}
        </Content>
      )}
    </Wrapper>
  );
};

FileInput.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  uploadText: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  dragOverScreen: PropTypes.node,
  onAcceptFiles: PropTypes.func.isRequired,
  onRejectFiles: PropTypes.func.isRequired,
};

export default FileInput;
