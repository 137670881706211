import withCurrentProject from 'lib/withCurrentProject';

import {compose} from '@renofi/recompose';
import {withTaskAdditionalRequestPreview} from '@renofi/graphql';

import Component from './Component';

export default compose(
  withCurrentProject(),
  withTaskAdditionalRequestPreview(),
)(Component);
