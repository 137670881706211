import {useCallback} from 'react';

import {useApolloClient} from '@apollo/client';

import logger from '@renofi/utilities/src/logger';
import useChannel from '@renofi/utilities/src/pusher/hooks/useChannel';

import {GET_APPRAISAL_ORDERS} from '../../queries';

export default function useAppraisalOrderUpdated(projectId) {
  const client = useApolloClient();

  const onAppraisalOrderUpdated = useCallback(
    (event) => {
      logger.debug('APPRAISAL_ORDER_UPDATED', event);
      client.refetchQueries({
        include: [
          {
            query: GET_APPRAISAL_ORDERS,
            variables: {projectId},
          },
        ],
      });
    },
    [projectId],
  );

  useChannel({
    channelName: `appraisal_order_updated--project=${projectId}`,
    onUpdate: onAppraisalOrderUpdated,
  });

  return {};
}
