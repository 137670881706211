import {compose, withProps} from '@renofi/recompose';

import withSettings from './withSettings';

export default () =>
  compose(
    withSettings(),
    withProps(({settings}) => ({
      layout: settings?.viewerLayout,
    })),
  );
