import {identity, pathOr} from 'ramda';

import {compose, mapProps, withProps} from '@renofi/recompose';
import omitProps from '@renofi/utilities/src/omitProps';

import withRenovationReadyFile from './withRenovationReadyFile';

export default ({mapper = identity} = {}) =>
  compose(
    withRenovationReadyFile(),
    withProps(({renovationReadyFile}) => ({
      comments: pathOr([], ['project', 'comments'], renovationReadyFile),
    })),
    omitProps(['renovationReadyFile']),
    mapProps(mapper),
  );
