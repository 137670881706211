import {pick, reduce} from 'ramda';

import {getChecksum} from '@renofi/utils';

import {getFileType} from './document';
import readFile from './readFile';
import logger from './logger';

const ENCRYPT_REGEX = /\/Encrypt/;

const getIsEncrypted = async (data) => {
  var blob = new Blob([data], {type: 'application/pdf'});
  const text = await blob.text();
  return Boolean(text.match(ENCRYPT_REGEX));
};

export default async ({files = [], checksums, validateEncryption = false}) => {
  const finalFiles = await Promise.all(
    files.map(async ({id, file, ...rest}) => {
      const {pdf} = getFileType(file.name);
      const data = await readFile(file);
      const isEncrypted =
        pdf && validateEncryption ? await getIsEncrypted(data) : null;
      logger.debug('isEncrypted', isEncrypted);
      const checksum = await getChecksum(data);
      return {id, checksum, file, isEncrypted, ...rest};
    }),
  );
  const finalChecksums = checksums.concat(finalFiles.map(pick('checksum')));
  return reduce(
    ([accepted, rejected], file) =>
      finalChecksums.includes(file.checksum)
        ? [accepted, rejected.concat(file)]
        : [accepted.concat(file), rejected],
    [[], []],
  )(finalFiles);
};
