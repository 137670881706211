import {assoc, omit, pathOr, propEq} from 'ramda';

import {compose, mapProps, withHandlers} from '@renofi/recompose';
import {getAllFacets} from '@renofi/utilities/src/facets';

import {REMOVE_DOCUMENT} from '../mutations';
import {GET_PROJECT_TASKS} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';
import withUpdateProjectSummary from './withUpdateProjectSummary';

const facets = getAllFacets();

export default () =>
  compose(
    withApollo,
    withMutation(REMOVE_DOCUMENT, {
      name: 'removeDocument',
    }),
    withUpdateProjectSummary(),
    withHandlers({
      removeDocument:
        ({client, removeDocument, updateProjectSummaryTasks}) =>
        async ({documentId, projectId, taskId}) => {
          const response = await removeDocument({
            variables: {id: documentId},
          });
          const success = pathOr(
            false,
            ['data', 'removeDocument', 'success'],
            response,
          );
          if (success) {
            try {
              await Promise.all(
                facets.map(async (facet) => {
                  const {projectTasks = []} =
                    client.readQuery({
                      query: GET_PROJECT_TASKS,
                      variables: {projectId, facet},
                    }) || {};
                  const containsTask = projectTasks.some(propEq('id', taskId));

                  if (!containsTask) return;

                  const updatedTasks = projectTasks.map((task) => {
                    return task.id === taskId
                      ? assoc(
                          'documents',
                          task.documents.filter(({id}) => id !== documentId),
                          task,
                        )
                      : task;
                  });

                  client.writeQuery({
                    query: GET_PROJECT_TASKS,
                    variables: {projectId, facet},
                    data: {
                      projectTasks: updatedTasks,
                    },
                  });

                  updateProjectSummaryTasks({[facet]: updatedTasks});
                }),
              );
            } catch (error) {
              // ignore
            }
          }
          return response;
        },
    }),
    mapProps(omit(['client'])),
  );
