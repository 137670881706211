import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {propEq, propOr, reject, without} from 'ramda';
import * as uuid from 'uuid';

import logger from '@renofi/utilities/src/logger';
import {Provider as ThemeProvider} from '@renofi/theme/src';

import Snackbar from '../Snackbar';

import NotificationsContext from './context';
import {Content, Notification, Wrapper} from './styled';

const DELAY = 5000;

const getDelay = (notification) => {
  const customDelay = propOr(null, 'delay', notification);
  if (customDelay) {
    return customDelay;
  }

  const variant = propOr(null, 'variant', notification);
  return variant === 'danger' ? DELAY * 2 : DELAY;
};

const initialState = {
  notifications: [],
  timeoutIds: [],
};

class Notifications extends React.PureComponent {
  state = initialState;

  componentWillUnmount() {
    this.state.timeoutIds.forEach(clearTimeout);
  }

  addNotification = (notification) => {
    logger.debug('ADD_NOTIFICATION', notification);

    const id = notification.id || uuid.v4();
    const delay = getDelay(notification);
    const timeoutId = setTimeout(() => this.removeById(id, timeoutId), delay);

    this.setState(({notifications, timeoutIds}) => ({
      notifications: notifications.concat({
        createdAt: new Date().toISOString(),
        id,
        icon: null,
        vibrant: true,
        ...notification,
      }),
      timeoutIds: timeoutIds.concat(timeoutId),
    }));
    return {id, timeoutId};
  };

  removeById = (notificationId, timeoutId) => {
    this.setState(({notifications, timeoutIds}) => ({
      notifications: reject(propEq('id', notificationId), notifications),
      timeoutIds: without([timeoutId], timeoutIds),
    }));
  };

  removeAll = () => {
    this.setState(initialState);
  };

  render() {
    const {addNotification, removeAll, removeById} = this;
    const {notifications} = this.state;
    const {children, top, ...props} = this.props;

    if (!children) {
      logger.warn('No children given for Notifications');
      return null;
    }

    return (
      <NotificationsContext.Provider
        value={{addNotification, notifications, removeById, removeAll}}>
        <ThemeProvider theme="light">
          <Wrapper flexDirection="column" top={top}>
            {notifications.map((notification) => {
              const content = propOr(null, 'content', notification);
              const type = propOr('notification', 'type', notification);

              return (
                <Fragment key={notification.id}>
                  {type === 'snackbar' ? (
                    <Snackbar
                      width="auto"
                      key={notification.id}
                      variant={notification.variant}
                      message={content}
                    />
                  ) : (
                    <Notification
                      key={notification.id}
                      vibrant
                      visible={Boolean(notification)}
                      variant={propOr('default', 'variant', notification)}
                      {...props}>
                      <Content title={content}>{content}</Content>
                    </Notification>
                  )}
                </Fragment>
              );
            })}
          </Wrapper>
        </ThemeProvider>
        {children}
      </NotificationsContext.Provider>
    );
  }
}

Notifications.propTypes = {
  children: PropTypes.node,
  top: PropTypes.number,
};

Notification.defaultProps = {
  top: 130,
};

export default Notifications;
