import React, {Fragment, useEffect, useState} from 'react';

import {lensProp, set} from 'ramda';
import PropTypes from 'prop-types';
import * as uuid from 'uuid';

import {
  Box,
  ButtonGroup,
  EmailField,
  Flex,
  TextField,
  Textarea,
  PhoneField,
} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {isEmail, isPhone} from '@renofi/utilities';

const TYPE_OPTIONS = [
  {label: 'Client', value: 'client'},
  {label: 'Subcontractor', value: 'subcontractor'},
];

const COLLECTION_METHODS = [
  {label: 'Email questionnaire', value: false},
  {label: 'Manual entry', value: true},
];

const REQUIRED_KEYS = ['email', 'name', 'phoneNumber', 'type'];

const getIsValid = (obj, isManual) => {
  const keys = isManual
    ? [...REQUIRED_KEYS, 'manualEntryDetails']
    : REQUIRED_KEYS;

  return keys.every((key) => {
    switch (true) {
      case key === 'phoneNumber':
        return isPhone(obj[key]);
      case key === 'email':
        return isEmail(obj[key]);
      default:
        return Boolean(obj[key]);
    }
  });
};

function AddGcddReference({onChange, initialReferenceValues, action}) {
  const [attributes, setAttributes] = useState(initialReferenceValues);
  const {manualEntryDetails} = initialReferenceValues;
  const isUpdateView = action === 'update';

  const [isManualEntry, setIsManualEntry] = useState(
    !isUpdateView || Boolean(manualEntryDetails),
  );

  const isFormDisabled = !isManualEntry && isUpdateView;

  const onChangeValue = (key, value) => {
    let updated = set(lensProp(key), value, attributes);
    const isValid = getIsValid(updated, isManualEntry);

    setAttributes(updated);
    onChange({attributes: updated, isManualEntry, isValid});
  };

  useEffect(() => {
    setAttributes({
      ...attributes,
      id: isUpdateView ? attributes?.id : uuid.v4(),
    });
  }, []);

  const onChangeManualEntry = (value) => {
    setIsManualEntry(value);

    if (!isManualEntry) {
      const updated = {
        ...attributes,
        manualEntryDetails: isUpdateView ? attributes?.manualEntryDetails : '',
      };
      const isValid = getIsValid(updated, isManualEntry);

      setAttributes(updated);
      onChange({attributes: updated, isManualEntry, isValid});
    }
  };

  return (
    <ThemeProvider theme="light">
      <Fragment>
        <Flex width={0.5}>
          <ButtonGroup
            label="Reference type"
            onChange={(v) => onChangeValue('type', v)}
            options={TYPE_OPTIONS}
            value={attributes?.type}
            disabled={isFormDisabled}
          />
        </Flex>

        <Flex width={0.6}>
          <ButtonGroup
            label="Collection method"
            onChange={onChangeManualEntry}
            options={COLLECTION_METHODS}
            value={isManualEntry}
            disabled={isUpdateView}
          />
        </Flex>

        <Flex width={1}>
          <Box flex={0.5} mr={3}>
            <TextField
              label="Full name"
              onChange={(v) => onChangeValue('name', v)}
              value={attributes?.name || ''}
              disabled={isFormDisabled}
            />
          </Box>
          <Box flex={0.5} mr={3}>
            <EmailField
              label="Email"
              onChange={(v) => onChangeValue('email', v)}
              value={attributes?.email || ''}
              disabled={isFormDisabled}
            />
          </Box>
        </Flex>
        <PhoneField
          label="Phone number"
          onChange={(v) => onChangeValue('phoneNumber', v)}
          value={attributes?.phoneNumber || ''}
          disabled={isFormDisabled}
        />
        {isManualEntry && (
          <Textarea
            label="Provide details"
            onChange={(v) => onChangeValue('manualEntryDetails', v)}
            value={attributes?.manualEntryDetails || ''}
            disabled={isFormDisabled}
          />
        )}
      </Fragment>
    </ThemeProvider>
  );
}

AddGcddReference.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialReferenceValues: PropTypes.shape({
    confirmationRequestedAt: PropTypes.string,
    confirmationRequestedById: PropTypes.string,
    confirmationRequestedMessage: PropTypes.string,
    confirmedAt: PropTypes.string,
    createdAt: PropTypes.string,
    gcddReview: PropTypes.object,
    email: PropTypes.string,
    id: PropTypes.string,
    manualEntryDetails: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    phoneNumber: PropTypes.string,
    questionnaireId: PropTypes.string,
    score: PropTypes.string,
    scoreSubmittedAt: PropTypes.string,
    scoreSubmittedById: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    updatedAt: PropTypes.string,
    __typename: PropTypes.string,
  }),
  action: PropTypes.oneOf(['create', 'update']),
};

AddGcddReference.defaultProps = {
  initialReferenceValues: {type: 'client'},
  action: 'create',
};

export default AddGcddReference;
