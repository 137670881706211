import {onError} from '@apollo/link-error';

import {logErorr} from '@renofi/analytics';

export default onError(({graphQLErrors}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path}) => {
      if (message.includes('Cannot query field')) {
        logErorr('[GraphQL error] Cannot query field.', {
          details: `Message: ${message}, Location: ${locations}, Path: ${path}`,
        });
      }
    });
});
