import styled from '@emotion/styled';

import {actionDark} from '@renofi/theme';

import Link from '../Link';

export default styled(Link)({}, ({css = {}}) => {
  return {
    fontSize: 14,
    color: actionDark,
    padding: '8px',
    borderRadius: 2,
    marginLeft: '-8px',
    '&:hover': {
      backgroundColor: '#F0F9FF',
      color: actionDark,
      textDecoration: 'none',
    },
    ...css,
  };
});
