import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {propEq, last, isEmpty} from 'ramda';
import {useCurrentSource} from 'api/hooks';
import {
  RevertStatusModal,
  ModalsContext,
  OrderAnalysisModal,
} from 'modules/modals';

import {
  Alert,
  DateFormat,
  Flex,
  Box,
  Text,
  Link,
} from '@renofi/components-internal';
import {actionLight, dangerLight} from '@renofi/theme/src/colors';
import {
  useRevertFeasibilityReviewCompletion,
  useFeasibilityReviews,
} from '@renofi/graphql';

import {
  StatusBarItemContainer,
  StatusBarItemLabel,
  StatusBarItemValue,
  StatusBarWrapper,
} from '../styled';

import {getUserName} from './utils';

const FeasibilityStatusBar = (props = {}) => {
  const [modal, setModal] = useState(false);
  const {projectId, ...params} = useParams();
  const {isInternal} = useCurrentSource();
  const feasibilityReviewId =
    params?.feasibilityReviewId || props?.feasibilityReviewId;

  const {feasibilityReviews} = useFeasibilityReviews({
    variables: {
      projectId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const feasibilityReview = useMemo(() => {
    const getFeasibilityReview = () => {
      if (isEmpty(feasibilityReviews)) {
        return {};
      }

      if (!feasibilityReviewId) {
        return last(feasibilityReviews);
      }

      return feasibilityReviews.find(propEq('id', feasibilityReviewId));
    };

    return getFeasibilityReview();
  }, [feasibilityReviews, feasibilityReviewId]);

  const {
    completedAt,
    completedBy,
    requestedAt,
    canceledAt,
    canceledBy,
    requestedBy,
    returnedAt,
    reviewer,
    uncompletedReason,
  } = feasibilityReview || {};

  const {revertFeasibilityReviewCompletion} =
    useRevertFeasibilityReviewCompletion();

  const revert = async () => {
    await revertFeasibilityReviewCompletion({
      variables: {id: feasibilityReviewId},
    });
  };

  if (!isInternal || !requestedAt) {
    return null;
  }

  return (
    <>
      <StatusBarWrapper>
        {!completedAt && !uncompletedReason && (
          <Alert px={0}>
            Project feasibility & contract review{' '}
            {returnedAt ? (
              'received'
            ) : (
              <>
                ordered
                {reviewer ? ' from ' : '.'}
                {reviewer && (
                  <Link
                    css={{color: '#80cfff', textDecoration: 'underline'}}
                    onClick={() => setModal(true)}>
                    {getUserName(reviewer)}
                  </Link>
                )}
              </>
            )}
          </Alert>
        )}
        {!completedAt && uncompletedReason && (
          <Alert variant="danger" px={0}>
            Project feasibility & contract review received incomplete.
          </Alert>
        )}
        {completedAt && (
          <ModalsContext.Consumer>
            {({onClose, setLoading, setModalProps}) => (
              <Flex alignItems="center">
                <Alert variant="success" px={0}>
                  <Text color={actionLight}>
                    Project feasibility & contract review completed.
                  </Text>
                </Alert>
                <Link
                  css={{
                    color: dangerLight,
                    marginLeft: 10,
                  }}
                  onClick={() =>
                    setModalProps({
                      acceptLabel: 'Yes, revert to incomplete',
                      rejectLabel: 'No, close',
                      component: RevertStatusModal,
                      hasButtons: true,
                      onAccept: async () => {
                        setLoading(true);
                        await revert();
                        setLoading(false);
                        onClose();
                      },
                      onReject: () => onClose(),
                    })
                  }>
                  Revert to incomplete
                </Link>
              </Flex>
            )}
          </ModalsContext.Consumer>
        )}
        <Flex>
          <StatusBarItemContainer>
            <StatusBarItemLabel>Order date</StatusBarItemLabel>
            <StatusBarItemValue>
              {requestedAt ? (
                <>
                  <DateFormat value={new Date(requestedAt)} /> (
                  {getUserName(requestedBy)})
                </>
              ) : (
                'N/A'
              )}
            </StatusBarItemValue>
          </StatusBarItemContainer>

          {canceledAt && (
            <StatusBarItemContainer>
              <StatusBarItemLabel>Cancelation date</StatusBarItemLabel>
              <StatusBarItemValue>
                <>
                  <DateFormat value={new Date(canceledAt)} />
                  {canceledBy?.fullName && ` (${canceledBy.fullName})`}
                </>
              </StatusBarItemValue>
            </StatusBarItemContainer>
          )}

          <StatusBarItemContainer>
            <StatusBarItemLabel danger={uncompletedReason && dangerLight}>
              {uncompletedReason ? 'Date returned incomplete' : 'Date returned'}
            </StatusBarItemLabel>
            <StatusBarItemValue>
              {returnedAt ? (
                <>
                  <DateFormat value={new Date(returnedAt)} /> (
                  {reviewer ? getUserName(reviewer) : null})
                </>
              ) : (
                'N/A'
              )}
            </StatusBarItemValue>
          </StatusBarItemContainer>

          <StatusBarItemContainer>
            <StatusBarItemLabel>Completed</StatusBarItemLabel>
            <StatusBarItemValue>
              {completedAt ? (
                <>
                  <DateFormat value={new Date(completedAt)} /> (
                  {getUserName(completedBy)})
                </>
              ) : (
                'N/A'
              )}
            </StatusBarItemValue>
          </StatusBarItemContainer>
        </Flex>
      </StatusBarWrapper>
      {modal && (
        <Box css={{position: 'absolute', left: 'calc(50% - 20px)', top: 120}}>
          <OrderAnalysisModal reassign onClose={() => setModal(false)} />
        </Box>
      )}
    </>
  );
};

FeasibilityStatusBar.propTypes = {
  feasibilityReviewId: PropTypes.string,
};

export default FeasibilityStatusBar;
