import {compose, withHandlers} from '@renofi/recompose';

import {GET_RENOVATION_UPDATE} from '../queries';

import withApollo from './withApollo';
import withChangeRenovationState from './withChangeRenovationState';

export default () =>
  compose(
    withApollo,
    withChangeRenovationState(),
    withHandlers({
      changeRenovationStateData:
        ({client, changeRenovationState}) =>
        (data) => {
          const {renovationUpdate} = client.readQuery({
            query: GET_RENOVATION_UPDATE,
          });
          changeRenovationState({
            data: {...(renovationUpdate?.data || {}), ...data},
          });
        },
    }),
  );
