import {gql} from '@apollo/client';

import {partials} from '@renofi/graphql';

const {user} = partials;

export default gql`
  query userById($id: ID!) {
    userById(id: $id) {
      ${user}
      slackId
    }
  }
`;
