import styled from '@emotion/styled';

import {
  Alert as CommonAlert,
  Link as CommonLink,
} from '@renofi/components-internal';
import {dangerLight, white} from '@renofi/theme';

export const Alert = styled(CommonAlert)({
  borderRadius: 0,
  position: 'sticky',
  top: 0,
  zIndex: 1053,
});

export const Link = styled(CommonLink)({
  color: dangerLight,
  textDecoration: 'underline',
  marginLeft: 8,
  ':hover': {
    color: white,
  },
});

export const Code = styled.pre({
  opacity: 0.8,
  borderRadius: 8,
  textWrap: 'balance',
  background: 'red',
  padding: 10,
  maxHeight: 200,
  overflow: 'auto',
  fontFamily: "Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console'",
});
