import styled from '@emotion/styled';

import {mediaQuery} from '@renofi/theme';

import {Box} from '../index';

export default styled(Box)(
  {
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  ({columns = 4, gap = '24px', extraColumns, customColumns, gridColumn}) =>
    mediaQuery({
      gridTemplateColumns: [
        '1fr',
        customColumns
          ? customColumns
          : `repeat(${columns}, minmax(0, 1fr))${extraColumns ? ' ' + extraColumns : ''}`,
      ],
      gridColumnGap: gap,
      gridColumn,
    }),
);
