import agreement from './agreement';

export default `
  id
  active
  allAgreementsAcceptedAt
  email
  firstName
  lastName
  mfaEnabled
  accountCategories
  lenderId
  contractorId
  crmId
  currentAgreements {
    ${agreement}
  }
  active
  registrationCompletedAt
  lastFailedLoginAt
  lastLoginAt
  lockedAt
  invitationSentAt
  salesforceUserId
  notificationPreferences {
    id
    active
    notificationType
  }
  permissions {
    id
    key
  }
  phoneNumber
`;
