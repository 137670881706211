import {assoc, pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {CREATE_TASK_ADDITIONAL_REQUEST} from '../mutations';
import {GET_PROJECT} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(CREATE_TASK_ADDITIONAL_REQUEST, {
      name: 'createTaskAdditionalRequest',
    }),
    withHandlers({
      createTaskAdditionalRequest:
        ({client, createTaskAdditionalRequest}) =>
        async ({projectId, ...variables}) => {
          const response = await createTaskAdditionalRequest({variables});
          const success = pathOr(
            false,
            ['data', 'createTaskAdditionalRequest', 'success'],
            response,
          );
          if (success) {
            const result = await client.query({
              query: GET_PROJECT,
              variables: {id: projectId},
              fetchPolicy: 'no-cache',
            });
            const {tasks, ...project} = pathOr(null, ['data', 'projectById'])(
              result,
            );
            client.writeQuery({
              query: GET_PROJECT,
              variables: {
                id: projectId,
              },
              data: {
                projectById: {
                  ...project,
                  tasks: tasks.map((task) =>
                    task.id === variables.taskId
                      ? assoc(
                          'additionalRequestMessage',
                          variables.message,
                          task,
                        )
                      : task,
                  ),
                },
              },
            });
          }
          return success;
        },
    }),
  );
