import {either, isEmpty, isNil} from 'ramda';
import withPropertyDetails from 'lib/withPropertyDetails';

import {compose, withProps} from '@renofi/recompose';
import spreadProps from '@renofi/utilities/src/spreadProps';

import Component from './Component';

export default compose(
  spreadProps(['task']),
  withProps(({appraisalPropertyDetails}) => ({
    empty: either(isNil, isEmpty)(appraisalPropertyDetails),
  })),
  withPropertyDetails(),
)(Component);
