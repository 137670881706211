import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme';

import Flex from './Flex';

const Alert = ({block, children, ...props}) => {
  const {styles} = useTheme('alert', {defaultVariant: 'info', ...props});

  return (
    <Flex
      css={{
        width: block ? '100%' : 'auto',
        fontSize: 14,
        lineHeight: '17px',
        ...styles,
      }}
      px={16}
      py={12}
      alignItems="center"
      variant="variant"
      {...props}>
      {children}
    </Flex>
  );
};

Alert.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

export default Alert;
