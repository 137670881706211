import {useContext, useEffect, useMemo, useState} from 'react';

import {isEmpty, mergeDeepRight, pathOr} from 'ramda';

import {ReconfContext} from '../reconf';
import {Context as StorageContext, FEATURE_FLAG_OVERRIDES} from '../storage';

const useFeatureFlags = (targetFlags = []) => {
  const storage = useContext(StorageContext);
  const [overrides, setOverrides] = useState({});

  const hasOverrides = isEmpty(overrides);
  const {hasNoFlags, isReady, flags} = useContext(ReconfContext) || {};

  useEffect(() => {
    const lsOverrides = storage?.getItem(FEATURE_FLAG_OVERRIDES) || {};
    setOverrides(lsOverrides);
  }, []);

  const mergedFlags = useMemo(
    () => mergeDeepRight(flags, overrides),
    [flags, overrides],
  );

  const calculatedFlags = useMemo(() => {
    if (!targetFlags?.length) {
      return mergedFlags;
    }

    return targetFlags.reduce((obj, key) => {
      const flag = pathOr(null, [key, 'enabled'], mergedFlags);

      return {...obj, [key]: flag};
    }, {});
  }, [mergedFlags, targetFlags]);

  const clearAllOverrides = () => {
    storage.removeItem(FEATURE_FLAG_OVERRIDES);
    setOverrides({});
  };

  const setOverrideByKeyValue = (key, value) => {
    const newMerged = mergeDeepRight(overrides, {
      [key]: {...value, overwrittenAt: new Date().toISOString()},
    });
    setOverrides(newMerged);
    storage.setItem(FEATURE_FLAG_OVERRIDES, newMerged);
  };

  return {
    clearAllOverrides,
    flags: calculatedFlags,
    hasNoFlags,
    hasOverrides,
    isReady,
    mergedFlags,
    overrides,
    setOverrideByKeyValue,
  };
};

export default useFeatureFlags;
