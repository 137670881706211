import {Flex, Text} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {basic80} from '@renofi/theme';
import {getFormattedAddress} from '@renofi/utilities/src/data';

import TaskDocument from '../TaskDocument';
import {DocumentCard} from '../styled';
import {getAddedPrefixByDocumentType} from '../../utils';

import AdditionalPropertyDetails from './AdditionalPropertyDetails';
import SoldPropertyDetails from './SoldPropertyDetails';
import SubjectPropertyDetails from './SubjectPropertyDetails';

const PropertyItem = ({
  address,
  areActionsDisabled = true,
  borrower,
  coborrower,
  internal,
  onAccept,
  onClickDocument,
  onReject,
  onClickTaskData,
  property,
}) => {
  const {__typename, documents = []} = property || {};
  const type = humanizeSnakeCase(__typename);
  const fullAddress = address || getFormattedAddress(property);

  const onClickValue = () => {
    const [firstDocument] = documents;
    onClickTaskData({documentId: firstDocument?.id, propertyId: property.id});
  };

  const getDetailsByType = (typeName) => {
    const commonProps = {
      borrower,
      coborrower,
      internal,
      onClickValue,
      property,
    };
    switch (typeName) {
      case 'AdditionalProperty':
        return <AdditionalPropertyDetails {...commonProps} />;
      case 'SoldProperty':
        return <SoldPropertyDetails {...commonProps} />;
      case 'SubjectProperty':
        return <SubjectPropertyDetails {...commonProps} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Flex mb={1}>
        <Text color={basic80} fontWeight="bold" fontSize={16} pr={1}>
          {fullAddress}
        </Text>
        <Text fontSize={14}>({type})</Text>
      </Flex>

      {getDetailsByType(__typename)}

      <Flex mb={3} width={1} flexWrap="wrap">
        {documents.map((doc) => {
          const nickname = getAddedPrefixByDocumentType(doc);
          const addedPrefix = `${nickname} added`.trim();
          const reviewedPrefix = `${nickname} reviewed`.trim();

          return (
            <DocumentCard mb={2} internal={internal} key={doc.id}>
              <TaskDocument
                addedPrefix={addedPrefix}
                areActionsDisabled={areActionsDisabled}
                reviewedPrefix={reviewedPrefix}
                onClick={() => onClickDocument(doc.id, property.id)}
                showDownloadIcon={internal}
                internal={internal}
                onAccept={onAccept}
                onReject={onReject}
                immutable={!internal}
                {...doc}
              />
            </DocumentCard>
          );
        })}
      </Flex>
    </>
  );
};

export default PropertyItem;
