import {withCurrentSource} from 'api/hoc';
import {isFeasibilityReviewerSource} from 'lib/sourceHelper';

import {compose, withProps} from '@renofi/recompose';
import {withUser} from '@renofi/graphql';
import {hasFeasibilityAdvancedPermission} from '@renofi/utilities/src/permissions';

const filterFeasibilityStudyItems = (
  feasibilityStudyItems,
  {filteredType, currentSource},
) => {
  return feasibilityStudyItems.filter(
    ({cost, descriptionOfWork, source, type}) => {
      const isTypeMatched = type === filteredType;
      const isSourceMatched = source === currentSource;
      const hasCostOrDescription = Boolean(cost || descriptionOfWork);

      return isTypeMatched && (isSourceMatched || hasCostOrDescription);
    },
  );
};

export default () =>
  compose(
    withCurrentSource(),
    withUser(),
    withProps(({feasibilityReview, currentSource, user}) => {
      const hasEditPermission = hasFeasibilityAdvancedPermission(user);

      const items = feasibilityReview?.feasibilityStudyItems || [];
      const isCompleted = Boolean(feasibilityReview?.completedAt);

      const feasibilityStudyItems = items.map((item) => {
        const isReviewer = isFeasibilityReviewerSource(currentSource);
        const editable = isReviewer && (hasEditPermission || !isCompleted);

        return {...item, editable};
      });

      return {
        feasibilityStudyItems,
        groupedStudyItems: {
          inScopeStudyItems: filterFeasibilityStudyItems(
            feasibilityStudyItems,
            {
              filteredType: 'in_scope',
              currentSource,
            },
          ),
          outOfScopeStudyItems: filterFeasibilityStudyItems(
            feasibilityStudyItems,
            {
              filteredType: 'out_of_scope',
              currentSource,
            },
          ),
          homeownerStudyItems: filterFeasibilityStudyItems(
            feasibilityStudyItems,
            {
              filteredType: 'homeowner_responsibility',
              currentSource,
            },
          ),
        },
      };
    }),
  );
