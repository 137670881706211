import {gql} from '@apollo/client';

export default gql`
  mutation initializeSoftCreditCheck(
    $projectId: ID!
    $softCreditCheckAttributes: SoftCreditCheckInputObject!
  ) {
    initializeSoftCreditCheck(
      projectId: $projectId
      softCreditCheckAttributes: $softCreditCheckAttributes
    ) {
      success
    }
  }
`;
