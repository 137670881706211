import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {isFunction} from 'lodash';

import noop from '@renofi/utilities/src/noop';
import {isEmail} from '@renofi/utilities';

import FieldFrame from '../FieldFrame';
import FieldInput from '../FieldInput';

const INVALID_MSG = 'Invalid email';

const EmailField = ({
  active,
  autoComplete = 'email',
  defaultValue,
  disabled,
  name,
  placeholder,
  value: currentValue,
  onBlur = noop,
  onChange = noop,
  onFocus = noop,
  customValidation = noop,
  tabIndex,
  small,
  validations = [],
  onValidate = () => {},
  showValidation,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || defaultValue || '');
  const [dirty, setDirty] = useState(Boolean(value));
  const [error, setError] = useState(null);
  const [validationErr, setValidationErr] = useState(null);
  const isUsingInputValidation = isFunction(onValidate);

  const defaultValidation = (val) => {
    if (val === '') return null;
    if (!isEmail(val)) return INVALID_MSG;

    return null;
  };

  const getErrorMessage = (val) => {
    return defaultValidation(val) || customValidation(val);
  };

  useEffect(() => {
    const val = currentValue || defaultValue || '';
    if (active) {
      setValue(val);
    }
    setError(getErrorMessage(val));
  }, [dirty, defaultValue, currentValue]);

  const changeHandler = (val) => {
    const isValidEmail = isEmail(val);

    setValue(val);
    setError(getErrorMessage(val));
    onChange(val, isValidEmail);
  };

  const blurHandler = (event) => {
    setFocus(false);
    setDirty(true);
    onBlur(event);
  };

  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  return (
    <FieldFrame
      inputValue={value}
      small={small}
      disabled={disabled}
      error={
        showValidation
          ? validationErr
          : !isUsingInputValidation && dirty && error
      }
      focused={focus}
      leftIcon={small ? undefined : 'email'}
      name={name}
      {...props}>
      <FieldInput
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        id={name}
        type="email"
        value={value}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        tabIndex={tabIndex}
        validations={validations}
        onValidate={validationHandler}
      />
    </FieldFrame>
  );
};

EmailField.propTypes = {
  active: PropTypes.bool,
  small: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  tabIndex: PropTypes.number,
  validations: PropTypes.array,
  onValidate: PropTypes.func,
};

export default EmailField;
