import {withFeasibilityReviewActions} from 'modules/feasibility/viewer';
import ViewerContext from 'modules/viewer-v2/context';

import {compose, withContext} from '@renofi/recompose';
import {withMarkFeasibilityReviewAsCompleted} from '@renofi/graphql';

import Component from './Component';

export default compose(
  withContext(ViewerContext),
  withFeasibilityReviewActions(ViewerContext),
  withMarkFeasibilityReviewAsCompleted(),
)(Component);
