import {compose, withHandlers} from '@renofi/recompose';

import {REMOVE_RENOVATION_UPDATE} from '../mutations';
import {GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(REMOVE_RENOVATION_UPDATE, {
      name: 'removeRenovationUpdate',
      options: {
        refetchQueries: [GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE],
      },
    }),
    withHandlers({
      removeRenovationUpdate:
        ({removeRenovationUpdate}) =>
        async ({id}) => {
          const response = await removeRenovationUpdate({variables: {id}});
          return response;
        },
    }),
  );
