import React, {Fragment} from 'react';

import {nth} from 'ramda';
import PropTypes from 'prop-types';
import {GCDD_TABS} from 'lib/gcdd';
import {useHasGcddPermissions} from 'modules/viewer-v2';

import ComplexityLevel from './components/ComplexityLevel';
import QuestionnaireScore from './components/QuestionnaireScore';
import History from './components/History';
import OverallScore from './components/OverallScore';
import Insurance from './components/Insurance';
import References from './components/References';
import StateLicense from './components/StateLicense';
import WebReviews from './components/WebReviews';
import BetterBusinessBureau from './components/BetterBusinessBureau';

function GcddReview({documents, gcddReview, itemId}) {
  const {hasPermission} = useHasGcddPermissions();
  const currentTab = nth(1, (itemId || '').split(':'));
  const showLicenses = currentTab === GCDD_TABS.LICENSES;
  const showInsurances = currentTab === GCDD_TABS.INSURANCES;
  const showReferences = currentTab === GCDD_TABS.REFERENCES;
  const showWebReviews = currentTab === GCDD_TABS.REVIEWS;
  const showBBB = currentTab === GCDD_TABS.BBB;
  const showHistory = [GCDD_TABS.CONTRACTOR, GCDD_TABS.QUESTIONNAIRE].includes(
    currentTab,
  );
  const showQuestionnaireScore = currentTab === GCDD_TABS.QUESTIONNAIRE;

  if (!hasPermission || !gcddReview) {
    return null;
  }

  const props = {documents, gcddReview};

  return (
    <Fragment>
      <ComplexityLevel {...props} />
      <OverallScore {...props} />
      {showQuestionnaireScore && <QuestionnaireScore {...props} />}
      {showHistory && <History {...props} />}
      {showReferences && <References {...props} />}
      {showLicenses && <StateLicense {...props} />}
      {showInsurances && <Insurance {...props} />}
      {showWebReviews && <WebReviews {...props} />}
      {showBBB && <BetterBusinessBureau {...props} />}
    </Fragment>
  );
}

GcddReview.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  itemId: PropTypes.string,
};

export default GcddReview;
