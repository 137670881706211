import {identity, ifElse, is, keys, map} from 'ramda';

import {compose, withProps} from '@renofi/recompose';

import Component from './Component';

export default compose(
  withProps(({items}) => ({
    items: ifElse(
      Array.isArray,
      map(
        ifElse(
          is(String),
          (value) => ({
            label: value,
            value: value,
          }),
          identity,
        ),
      ),
      (list) => {
        const isString = is(String);
        return keys(list).map((value) => {
          const val = list[value];
          if (isString(val)) {
            return {label: list[value], value};
          }

          return {
            label: val.name,
            value,
            badge: val?.badge,
            badgeVariant: val?.badgeVariant,
          };
        });
      },
    )(items),
  })),
)(Component);
