import {useMemo} from 'react';

import {
  head,
  isNil,
  keys,
  map,
  omit,
  pathOr,
  pipe,
  prop,
  propOr,
  reject,
  sortBy,
  uniqBy,
  values,
} from 'ramda';

import {useToggledQuery} from '@renofi/graphql';
import noop from '@renofi/utilities/src/noop';
import {getFullName} from '@renofi/utilities/src/data';
import {toSentenceCase} from '@renofi/utilities/src/string';
import flattenObject from '@renofi/utilities/src/flattenObject';

import {GET_PROJECTS_SEARCH_FACETS} from '../../../queries';

const isNumber = (v) => !isNaN(v);

const getCrudeObjectTranslation = (obj = {}) => {
  const allKeys = keys(obj);
  const idLike = allKeys.find((k) => /id$/i.test(k));
  const valueLike = allKeys.find((k) => /(name|value)$/i.test(k));
  if (!idLike || !valueLike) {
    return null;
  }

  return {
    label: toSentenceCase(obj[valueLike]),
    value: isNumber(obj[idLike]) ? Number(obj[idLike]) : obj[idLike],
  };
};

const translateFacetValues = (facet = []) => {
  return pipe(
    reject(isNil),
    sortBy(prop('label')),
  )(
    facet.reduce((arr, item) => {
      const flattened = flattenObject(item);
      const data = omit(['__typename', 'count'], flattened);
      const dataKeys = keys(data);
      const singleItem = dataKeys.length === 1;
      const isUserType = flattened['__typename'].includes('User');

      switch (true) {
        case singleItem:
          return arr.concat(head(values(data)));
        case isUserType:
          return arr.concat({
            label: getFullName(data),
            value: data.id,
          });
        default:
          return arr.concat(getCrudeObjectTranslation(data));
      }
    }, []),
  );
};

export default function useProjectsSearchFacets({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECTS_SEARCH_FACETS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, loading} = response;

  const projectUsers = useMemo(() => {
    const facets = pathOr(null, ['projectsSearch', 'metadata', 'facets'], data);
    const allKeys = keys(facets);

    return uniqBy(
      prop('id'),
      allKeys.reduce((arr, key) => {
        const projectUser = pipe(
          propOr([], key),
          map(propOr(null, 'projectUser')),
          reject(isNil),
        )(facets);

        return arr.concat(projectUser);
      }, []),
    );
  }, [data]);

  const getFacetValuesByName = (filterName) => {
    const facets = pathOr(null, ['projectsSearch', 'metadata', 'facets'], data);
    const facet = propOr([], filterName, facets);
    const values = translateFacetValues(facet) || [];

    return reject(isNil, values);
  };

  return {
    loading,
    getFacetValuesByName,
    projectUsers,
  };
}
