import {assoc, identity, pipe, propEq, reject} from 'ramda';
import {camelCase} from 'change-case';

import {compose, mapProps, withHandlers} from '@renofi/recompose';

import {GET_EXCEPTION_REQUEST_STATE} from '../queries';

import withApollo from './withApollo';
import withQuery from './withQuery';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    withApollo,
    withQuery({
      query: GET_EXCEPTION_REQUEST_STATE,
      props: ({data: {exceptionRequest}, ...props}) => ({
        exceptionRequestState: exceptionRequest,
        ...props,
      }),
      options,
    }),
    mapProps(mapper),
    withHandlers({
      updateExceptionRequest:
        ({client, exceptionRequestState}) =>
        (partial) => {
          client.writeQuery({
            query: GET_EXCEPTION_REQUEST_STATE,
            data: {
              exceptionRequest: {
                ...exceptionRequestState,
                ...partial,
              },
            },
          });
        },
    }),
    withHandlers({
      updateExceptionRequestMetric:
        ({exceptionRequestState: {metrics}, updateExceptionRequest}) =>
        (key, name, value) => {
          updateExceptionRequest({
            metrics: metrics.map((metric) => {
              if (metric.name === name) {
                return {
                  ...metric,
                  [key]: value,
                };
              }
              return metric;
            }),
          });
        },
      updateExceptionRequestMetrics:
        ({exceptionRequestState: {metrics}, updateExceptionRequest}) =>
        (values) => {
          const data = metrics.map((metric) => ({
            ...metric,
            exception: false,
            notes: '',
            value: `${values[camelCase(metric.name)]}`,
          }));
          updateExceptionRequest({
            metrics: data,
          });
        },
      updateExceptionRequestComments:
        ({updateExceptionRequest}) =>
        (comments) => {
          updateExceptionRequest({
            comments,
          });
        },
    }),
    withHandlers({
      updateExceptionRequestMetricNotes:
        ({updateExceptionRequestMetric}) =>
        (name, value) => {
          updateExceptionRequestMetric('notes', name, value);
        },
      updateExceptionRequestMetricException:
        ({updateExceptionRequestMetric}) =>
        (name, value) => {
          updateExceptionRequestMetric('exception', name, value);
        },
      createExceptionRequestComment:
        ({exceptionRequestState: {comments}, updateExceptionRequestComments}) =>
        (comment) => {
          updateExceptionRequestComments(
            comments.concat(
              assoc('__typename', 'ExceptionRequestStateComment', comment),
            ),
          );
        },
      updateExceptionRequestComment:
        ({exceptionRequestState: {comments}, updateExceptionRequestComments}) =>
        (id, partial) => {
          updateExceptionRequestComments(
            comments.map((comment) => {
              if (id === comment.id) {
                return {
                  ...comment,
                  ...partial,
                };
              }
              return comment;
            }),
          );
        },
      removeExceptionRequestComment:
        ({exceptionRequestState: {comments}, updateExceptionRequestComments}) =>
        (id) => {
          updateExceptionRequestComments(reject(propEq('id', id), comments));
        },
      resetExceptionRequest:
        ({exceptionRequestState: {metrics}, updateExceptionRequest}) =>
        () => {
          updateExceptionRequest({
            projectId: null,
            lenderId: null,
            exceptionTitle: '',
            compensatingFactors: '',
            renofiNotes: '',
            renovationScope: '',
            comments: [],
            metrics: metrics.map(
              pipe(
                assoc('exception', false),
                assoc('notes', ''),
                assoc('value', ''),
              ),
            ),
          });
        },
    }),
  );
