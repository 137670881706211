import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

import Flex from '../../../Flex';

export const Wrapper = styled(Flex)(
  {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    padding: '0 8px',
  },
  ({dynamic, options, theme}) => {
    const totalOptions = Object.values(options).length;
    return {
      ...theme,
      flex: dynamic ? '1 1 auto' : '1 1 0',
      [maxSmall]: {
        flex: 0,
        flexBasis: `${(1 / totalOptions) * 100}%`,
        whiteSpace: 'initial',
        lineHeight: '16px',
      },
    };
  },
);
