import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';

const getUrl = ({envKey, previewDomain}) => {
  if (isDeployPreviewUrl()) {
    return window.location.origin.replace(
      /blueprint|lenders|login|reviewers|secure/i,
      previewDomain,
    );
  }
  return process.env[envKey];
};

/**
 * @description Shared ENV variables across all apps
 */
export const ENVIRONMENT = process.env.REACT_APP_ENV || process.env.NODE_ENV;

export const DEVELOPMENT = ENVIRONMENT === 'development';
export const CI = ENVIRONMENT === 'ci';
export const PRODUCTION = ENVIRONMENT === 'production';
export const TESTING = ENVIRONMENT === 'testing';
export const MONEY_LOCALE = 'en-US';

export const GRAPHQL_PROXY_URL =
  process.env.REACT_APP_GRAPHQL_PROXY_URL || process.env.GRAPHQL_PROXY_URL;
export const APP_REVISION =
  process.env.REACT_APP_REVISION || process.env.REVISION || '';
export const SERVICE_ACCOUNT_ID =
  process.env.REACT_APP_SERVICE_ACCOUNT_ID || process.env.SERVICE_ACCOUNT_ID;
export const SERVICE_NAME =
  process.env.REACT_APP_SERVICE_NAME || process.env.SERVICE_NAME;

export const LENDERS_FRONTEND_URL = getUrl({
  envKey: 'REACT_APP_LENDERS_FRONTEND_URL',
  previewDomain: 'lenders',
});
export const LOGIN_FRONTEND_URL = getUrl({
  envKey: 'REACT_APP_LOGIN_FRONTEND_URL',
  previewDomain: 'login',
});
export const BLUEPRINT_FRONTEND_URL = getUrl({
  envKey: 'REACT_APP_BLUEPRINT_FRONTEND_URL',
  previewDomain: 'blueprint',
});
export const SECURE_FRONTEND_URL = getUrl({
  envKey: 'REACT_APP_SECURE_FRONTEND_URL',
  previewDomain: 'secure',
});
export const REVIEWERS_FRONTEND_URL = getUrl({
  envKey: 'REACT_APP_REVIEWERS_FRONTEND_URL',
  previewDomain: 'reviewers',
});

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY || '';
export const SEGMENT_URL = process.env.REACT_APP_SEGMENT_URL || '';
// temp code to compare env variables between this and monorepo
window._commonConfig = {
  ENVIRONMENT,
  DEVELOPMENT,
  CI,
  PRODUCTION,
  MONEY_LOCALE,
  TESTING,
  APP_REVISION,
  GRAPHQL_PROXY_URL,
  LOGIN_FRONTEND_URL,
  SEGMENT_KEY,
  SEGMENT_URL,
  SERVICE_NAME,
  SERVICE_ACCOUNT_ID,
};
