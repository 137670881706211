import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {useLocation} from 'react-router-dom';
import {pathOr} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {Flex} from '@renofi/components-internal';
import Nav from '@renofi/icons/src/Arrow';
import CommentIcon from '@renofi/icons/src/Comment';
import Close from '@renofi/icons/src/Cross';
import {actionLight, white} from '@renofi/theme';
import {sendEvent} from '@renofi/analytics';

import ViewerV2Context from '../../context';

import AppraisalGroup from './components/AppraisalGroup';
import FeasibilityReviews from './components/FeasibilityReviews';
import GcddReviews from './components/GcddReviews';
import InsuranceGroup from './components/InsuranceGroup';
import TasksFacet from './components/TasksFacet';
import {
  Actions,
  Action,
  Comment,
  NavBarWrapper,
  ScrollBox,
  Wrapper,
} from './styled';

const NAV_GROUP_MAP = {
  additional_documents: [
    TasksFacet,
    {facet: 'additional_documents', includeWithNoDocs: false},
  ],
  eligibility: [TasksFacet, {facet: 'eligibility'}],
  feasibility: [FeasibilityReviews],
  appraisal: [AppraisalGroup],
  insurance: [InsuranceGroup],
  gcdd: [GcddReviews],
};

const ViewerNavBar = () => {
  const {allowedGroups} = useContext(ViewerV2Context);
  const {history, params} = useViewerNavigation();
  const location = useLocation();
  const {projectId} = params;

  const ref = useRef();
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    setReferrer(pathOr(null, ['state', 'referrer'], location));
  }, []);

  const onMouseDown = (event) => {
    setClientX(event.clientX);
    setScrollX(ref.current.scrollLeft);
    setDragging(true);
  };

  const onMouseExit = () => {
    setDragging(false);
  };

  const onMouseMove = (event) => {
    if (!dragging) {
      return;
    }

    ref.current.scrollLeft = scrollX + (clientX - event.clientX);
  };

  const onCloseViewer = useCallback(() => {
    history.push(referrer ? referrer : `/borrowers/${projectId}/details`);

    sendEvent('Blueprint/Task/ViewerClosed');
  }, [projectId, referrer]);

  const onScrollBack = () => {
    ref.current.scrollBy({
      left: -200,
      behavior: 'smooth',
    });
  };

  const onScrollNext = () => {
    ref.current.scrollBy({
      left: 200,
      behavior: 'smooth',
    });
  };

  if (!allowedGroups.length) {
    return null;
  }

  return (
    <Wrapper>
      <ScrollBox
        onMouseDown={onMouseDown}
        onMouseUp={onMouseExit}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseExit}
        ref={ref}>
        <NavBarWrapper width={1}>
          <Flex
            width={80}
            height="100%"
            flexShrink={0}
            alignItems="center"
            justifyContent="center">
            <Comment>
              <CommentIcon width={20} height={20} color={actionLight} />
            </Comment>
          </Flex>

          {allowedGroups.map((group, index) => {
            const [Component, props] = NAV_GROUP_MAP[group] || [];

            return (
              Boolean(Component) && (
                <Component {...props} index={index} key={group} />
              )
            );
          })}
        </NavBarWrapper>
      </ScrollBox>

      <Actions>
        <Action onClick={onCloseViewer}>
          <Close color={white} />
        </Action>
        <Action flipped onClick={onScrollBack}>
          <Nav height={12} />
        </Action>
        <Action onClick={onScrollNext}>
          <Nav height={12} />
        </Action>
      </Actions>
    </Wrapper>
  );
};

export default ViewerNavBar;
