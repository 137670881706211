import styled from '@emotion/styled';

import {basic03, border} from '@renofi/theme/src/colors';

import Text from '../Text';
import Flex from '../Flex';

import dropzone from './dropzone.svg';

export const Wrapper = styled.div({
  padding: 8,
  outline: 'none',
});

export const Placeholder = styled.div({
  height: 138,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px dashed ${border}`,
  borderWidth: 2,
  borderRadius: 3,
  backgroundColor: basic03,
  backgroundSize: '204px 85px',
  backgroundPosition: '36px 32px',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${dropzone}')`,
  paddingLeft: 164,
});

export const Message = styled(Text)({
  fontSize: 19,
  lineHeight: 1,
  color: 'rgba(0,0,0,0.4)',
  margin: '10px 0',
});

export const Details = styled(Text)({
  fontSize: 15,
  lineHeight: 1,
  color: 'rgba(0,0,0,0.55)',
  margin: '10px 0',
});

export const Content = styled(Flex)(
  {
    alignItems: 'center',
  },
  ({children}) => ({
    height: children ? 'auto' : 44,
    paddingLeft: children ? 0 : 8,
  }),
);

export const UploadText = styled(Text)({
  fontSize: 14,
  lineHeight: '18px',
  color: 'rgba(0,0,0,0.5)',
  marginLeft: 16,
});
