import {assoc, omit, pathOr, propEq} from 'ramda';

import {compose, mapProps, withHandlers} from '@renofi/recompose';
import {getAllFacets} from '@renofi/utilities/src/facets';

import {SUBMIT_DOCUMENT} from '../mutations';
import {GET_PROJECT_TASKS} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

const facets = getAllFacets();

export default () =>
  compose(
    withApollo,
    withMutation(SUBMIT_DOCUMENT, {
      name: 'submitDocument',
    }),
    withHandlers({
      insertDocument:
        ({client}) =>
        ({document, projectId, taskId}) => {
          facets.forEach((facet) => {
            const {projectTasks = []} =
              client.readQuery({
                query: GET_PROJECT_TASKS,
                variables: {projectId, facet},
                fetchPolicy: 'cache-only',
              }) || {};
            const containsTask = projectTasks.some(propEq('id', taskId));

            if (!containsTask) return;

            client.writeQuery({
              query: GET_PROJECT_TASKS,
              variables: {projectId, facet},
              data: {
                projectTasks: projectTasks.map((task) => {
                  return task.id === taskId
                    ? assoc('documents', task.documents.concat(document), task)
                    : task;
                }),
              },
            });
          });
        },
    }),
    withHandlers({
      submitDocument:
        ({insertDocument, submitDocument}) =>
        async (
          {projectId, taskId, documentId, checksum, objectName},
          updateTask = true,
        ) => {
          const response = await submitDocument({
            variables: {taskId, documentId, checksum, objectName},
          });
          const document = pathOr(
            null,
            ['data', 'submitDocument', 'document'],
            response,
          );
          if (updateTask) {
            insertDocument({document, projectId, taskId});
          }
          return document;
        },
    }),
    mapProps(omit(['client'])),
  );
