import {isEmpty, isNil} from 'lodash';

import getIsReturned from './getIsReturned';

export default (gcddReview) => {
  if (isNil(gcddReview) || isEmpty(gcddReview)) {
    return [];
  }

  const {cancelledAt, completedAt} = gcddReview || {};
  const isCancelled = Boolean(cancelledAt);
  const isCompleted = Boolean(completedAt);
  const isReturned = getIsReturned(gcddReview);

  switch (true) {
    case isCompleted:
      return ['success', 'Completed'];
    case isReturned:
      return ['warning', 'Pending review'];
    case isCancelled:
      return ['dangerInverse', 'Cancelled'];
    default:
      return ['danger', 'With contractor'];
  }
};
