import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {basic55} from '@renofi/theme/src/colors';

import Icon from '../../../Icon';
import {IconBox} from '../../styled';

import {Text} from './styled';

const FrameSuffix = ({icon, iconProps, onClickIcon, primary, text, small}) => {
  if (!icon && !text) {
    return null;
  }

  return (
    <Fragment>
      {text && (
        <Text flex={0} color={basic55} fontSize={16}>
          {text}
        </Text>
      )}
      {icon && (
        <IconBox
          small={small}
          className="field-frame-right-icon"
          onClick={onClickIcon}
          right
          {...iconProps}>
          <Icon name={icon} primary={primary} size={small ? 16 : 20} />
        </IconBox>
      )}
    </Fragment>
  );
};

FrameSuffix.propTypes = {
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  onClickIcon: PropTypes.func,
  primary: PropTypes.bool,
  text: PropTypes.node,
  small: PropTypes.bool,
};

export default FrameSuffix;
