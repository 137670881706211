import React, {useEffect, useState} from 'react';

import {isEmpty} from 'lodash';
import {Box, Flex, Text} from 'rebass';

import {Button, Icon} from '@renofi/components-internal';
import {RENOFI_GRAPHQL_ERROR} from '@renofi/graphql';
import {white} from '@renofi/theme';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Alert, Code, Link} from './styled';

const UNKNOWN = 'Unknown mutation';

const GraphQlAlert = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState(false);

  const verb = showDetails ? 'Hide' : 'Show';
  const {data = [], operationName = UNKNOWN, variables = {}} = error || {};
  const humanizedOpName = humanizeSnakeCase(operationName);
  const hasVariables = !isEmpty(variables);

  const onClickDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  const onGraphQlError = (evt) => {
    const {detail} = evt || {};
    const {error} = detail || {};
    if (!error) {
      return;
    }
    setShowDetails(false);
    setError(detail);
  };

  useEffect(() => {
    global.addEventListener(RENOFI_GRAPHQL_ERROR, onGraphQlError);

    return () => {
      global.removeEventListener(RENOFI_GRAPHQL_ERROR, onGraphQlError);
    };
  }, []);

  if (!error) {
    return null;
  }

  return (
    <Alert variant="dangerVibrant">
      <Box width={1}>
        <Flex alignItems="center" justifyContent="space-between" width={1}>
          <Box>
            Error: the request "{humanizedOpName}" failed
            <Link onClick={onClickDetails}>{verb} details</Link>
          </Box>
          <Button ml="auto" variant="link" onClick={() => setError(false)}>
            <Icon name="cross" color={white} />
          </Button>
        </Flex>
        {showDetails && (
          <Box mt={2} flexDirection="column">
            <Text mb={1} fontWeight="bold">
              {operationName} at {new Date().toISOString()}
            </Text>

            {hasVariables && <Code>{JSON.stringify(variables)}</Code>}
            {data.map((item) => {
              const {message = 'Unknown error', extensions = {}} = item || {};
              const hasExtra = !isEmpty(extensions);
              return (
                <Box key={message} mt={16}>
                  {message}
                  {hasExtra && (
                    <Code>{JSON.stringify(extensions, null, 2)}</Code>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Alert>
  );
};

export default GraphQlAlert;
