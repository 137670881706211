import React, {Fragment, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {Box, Flex, Portal, Text} from '@renofi/components-internal';
import RenofiIcon from '@renofi/icons/src/Renofi';
import {useGcddReviewSearch} from '@renofi/graphql';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {Provider as ThemeProvider} from '@renofi/theme/src';

import useSearchParam from '../useSearchParam';

import {Popover, Project, SearchField, StatusBadge} from './styled';

function GcddSearch({inline = false, onSelectReview, theme = 'dark'}) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef();

  const {loading, fetch, gcddReviewSearch} = useGcddReviewSearch({
    lazy: true,
    fetchPolicy: 'no-cache',
  });

  const gcddReviews = gcddReviewSearch?.collection || [];
  const hasContrators = Boolean(gcddReviews?.length);
  const ResultsWrapper = inline ? Box : Portal;

  const debouncedReviewsSearch = useDebounce((qQuery) => {
    fetch({variables: {limit: 25, query: qQuery}});
  }, 300);

  const debouncedSetActive = useDebounce((isActive) => {
    setActive(isActive);
  }, 300);

  useEffect(() => {
    setReviews(gcddReviews);

    const [firstProject] = gcddReviews || [];
    if (!firstProject) {
      return;
    }

    setCurrentIndex(0);
    setActive(true);
  }, [loading]);

  const onChange = (inputValue) => {
    setValue(inputValue);
    debouncedReviewsSearch(inputValue);
  };

  useSearchParam(onChange);

  const onClickReviews = (id) => {
    if (id) {
      setActive(false);
      setReviews([]);
      setValue('');
      onSelectReview(id);
    }
  };

  const onKeyUp = (event) => {
    if (!hasContrators) {
      return;
    }

    if (event.key === 'Enter') {
      return onClickReviews(reviews[currentIndex]?.id);
    }

    setActive(true);
    if (event.key === 'ArrowDown') {
      const nextIndex =
        reviews.length - 1 > currentIndex ? currentIndex + 1 : 0;
      setCurrentIndex(nextIndex);
    } else if (event.key === 'ArrowUp') {
      const prevIndex =
        currentIndex > 0 ? currentIndex - 1 : reviews.length - 1;
      setCurrentIndex(prevIndex);
    }
  };

  const getStatus = (review) => {
    const cancelledAt = Boolean(review.cancelledAt);

    if (review?.completedAt) {
      return 'ques. completed';
    } else if (cancelledAt) {
      return 'cancelled';
    } else if (review?.questionnaire?.returnedAt) {
      return 'ques. returned';
    } else if (review?.questionnaire?.createdAt) {
      return 'ques. sent';
    } else {
      return 'ques. pending';
    }
  };

  return (
    <Fragment>
      <Flex mr={4} alignItems="center" css={{position: 'relative'}}>
        <ThemeProvider theme={theme}>
          <div id="contractors-search-input" ref={ref}>
            <SearchField
              active
              leftIcon="glass"
              mb={0}
              onFocus={() => setActive(true)}
              onBlur={() => debouncedSetActive(false)}
              onKeyUp={onKeyUp}
              onChange={onChange}
              placeholder="Search contractors"
              value={value}
            />
          </div>
        </ThemeProvider>

        {active && hasContrators && (
          <ResultsWrapper ref={ref}>
            <Popover inline={inline}>
              {reviews.map((review, i) => {
                return (
                  <Project
                    active={currentIndex === i}
                    css={{lineHeight: '20px'}}
                    p={3}
                    width="100%"
                    justifyContent="space-between"
                    key={review.id}
                    onClick={() => onClickReviews(review?.id)}>
                    <Box
                      flexDirection="column"
                      className="contractor-search-item"
                      css={{maxWidth: '65%', overflow: 'hidden'}}>
                      <Text fontSize={14}>
                        {review?.contractor?.businessName}{' '}
                      </Text>
                      <Text fontSize={12}>
                        {review?.contractor?.streetAddress},{' '}
                        {review?.contractor?.city}, {review?.contractor?.state},{' '}
                        {review?.contractor?.zipCode}
                      </Text>
                      <Text fontSize={12}>{review?.questionnaire?.email}</Text>
                      <Flex>
                        <RenofiIcon color="#FF5253" width={16} height={16} />
                        <Text fontSize={12} ml={1}>
                          {review?.project?.streetAddressOne ||
                            review?.project?.streetAddressTwo}
                          , {review?.project?.city}, {review?.project?.state},{' '}
                          {review?.project?.zipCode}
                        </Text>
                      </Flex>
                    </Box>

                    <Flex css={{textAlign: 'right'}} flexDirection="column">
                      <Box mb={2}>
                        <StatusBadge small variant="default" closed={false}>
                          {getStatus(review)}
                        </StatusBadge>
                      </Box>
                    </Flex>
                  </Project>
                );
              })}
            </Popover>
          </ResultsWrapper>
        )}
      </Flex>
    </Fragment>
  );
}

GcddSearch.propTypes = {
  inline: PropTypes.bool,
  onSelectReview: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default GcddSearch;
