import {useApolloClient} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import logger from '@renofi/utilities/src/logger';
import useChannel from '@renofi/utilities/src/pusher/hooks/useChannel';

import {useTasksOverview} from '../renovation';
import {GET_PROJECT_TASKS} from '../../queries';

const QUERY_PARAMS = {fetchPolicy: 'no-cache', lazy: true};

export default function useTaskStatusUpdated(
  projectId,
  analyticsPrefix = 'Secure',
) {
  const client = useApolloClient();
  const {fetch: fetchTasksOverview} = useTasksOverview(QUERY_PARAMS);

  const onTaskStatusUpdated = async (event) => {
    logger.debug('TASK_STATUS_UPDATED', event);
    const {status, task_id: taskId, task_type: taskType} = event || {};

    if (!taskId || !projectId) {
      return;
    }

    const rsp = await fetchTasksOverview({variables: {id: projectId}});
    const {data} = rsp || {};
    const {outstandingTotal = -1} = data?.projectById?.tasksOverview || {};

    if (outstandingTotal === 0) {
      sendEvent(`${analyticsPrefix}/No-Outstanding-Tasks`, {taskType});
    }

    const tasksData = client.readQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
    });
    if (!tasksData) return;

    const {projectTasks = []} = tasksData;
    client.writeQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
      data: {
        projectTasks: projectTasks.map((task) =>
          task.id === taskId ? {...task, status} : task,
        ),
      },
    });
  };

  useChannel({
    channelName: projectId && `task_status_updated--project=${projectId}`,
    onUpdate: onTaskStatusUpdated,
  });

  return {};
}
