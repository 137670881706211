/* eslint-disable max-lines */
import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {pick, prop} from 'ramda';
import {useParams} from 'react-router-dom';
import Row from 'modules/viewer-v2/components/Row';

import {useNotifications} from '@renofi/utilities/src/hooks';
import {
  ButtonGroup,
  TextField,
  SelectField,
  Textarea,
  Box,
  Flex,
  Button,
} from '@renofi/components-internal';
import Trash from '@renofi/icons/src/Trash';
import {basic75} from '@renofi/theme';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {
  GET_WEB_REVIEWS,
  useSubmitWebReview,
  useUpdateWebReview,
  useRemoveWebReview,
  GET_GCDD_REVIEWS_BY_PROJECT_ID,
} from '@renofi/graphql';

import {formatOrdinals, isOtherWebsite} from './utils';
import {
  WEBSITE_OPTIONS,
  WEBSITE_OTHER,
  STAR_RATING_OPTIONS,
  EMPTY_REVIEW,
  DEBOUNCE_DELAY,
} from './constants';

const KEYS_TO_PICK = ['comment', 'rating', 'url', 'website'];

const getState = (reviews = []) =>
  reviews?.length > 0
    ? [...reviews]
    : [{...EMPTY_REVIEW, createdAt: new Date().toISOString()}];

const isWebReviewValid = ({website, rating, url, comment}) =>
  Boolean(website && rating && url && comment);

function WebReviewList({reviews = [], gcddReviewId}) {
  const {projectId} = useParams();
  const {addNotification} = useNotifications();
  const [webReviews, setWebReviews] = useState(getState(reviews));
  const params = {
    refetchQueries: [
      {
        query: GET_WEB_REVIEWS,
        variables: {gcddReviewId},
      },
      {
        query: GET_GCDD_REVIEWS_BY_PROJECT_ID,
        variables: {projectId},
      },
    ],
  };
  const {submitWebReview, loading: isSubmitting} = useSubmitWebReview(params);
  const {updateWebReview, loading: isUpdating} = useUpdateWebReview(params);
  const {removeWebReview, loading: isRemoving} = useRemoveWebReview(params);

  const [lastCreatedReviewIndex, setLastCreatedReviewIndex] = useState(null);
  const [shouldUpdateWebReviewsState, setShouldUpdateWebReviewsState] =
    useState(true);

  const loading = isSubmitting || isUpdating || isRemoving;

  useEffect(() => {
    if (shouldUpdateWebReviewsState) {
      setWebReviews(getState(reviews));
    }
  }, [reviews.length, shouldUpdateWebReviewsState]);

  const debouncedSubmitWebReview = useDebounce(
    async (variables, reviewIndex) => {
      try {
        await submitWebReview({variables});
        setLastCreatedReviewIndex(reviewIndex);
      } catch (error) {
        addNotification({
          variant: 'danger',
          content: 'Failed to create review',
        });
        throw new Error('Failed to create review');
      }
    },
    DEBOUNCE_DELAY,
  );

  const debouncedUpdateWebReview = useDebounce(async (variables) => {
    await updateWebReview({variables});
  }, DEBOUNCE_DELAY);

  const onAddReview = () => {
    const now = new Date();

    setWebReviews([
      ...webReviews,
      {...EMPTY_REVIEW, createdAt: now.toISOString()},
    ]);
  };

  const onRemoveReview = async (id, reviewIndex) => {
    setLastCreatedReviewIndex(null);
    const reviewId = getState(reviews)?.[reviewIndex]?.id;
    if (!reviewId) {
      return;
    }

    try {
      await removeWebReview({variables: {id: reviewId}});
      setShouldUpdateWebReviewsState(true);
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to remove Web Review',
      });
      throw new Error('Failed to remove Web Review');
    }
  };

  const onBlur = async (review, reviewIndex) => {
    const {id} = review || {};
    const updatedReviewId = reviews[reviewIndex]?.id;
    const reviewId = id || updatedReviewId;
    const isValidReview = isWebReviewValid(review);
    const isAlreadyLoading = reviewId ? isUpdating : isSubmitting;

    // If we're already saving some values, do nothing
    if (isAlreadyLoading || !isValidReview) {
      return;
    }

    const action = reviewId ? updateWebReview : submitWebReview;
    let variables = pick(KEYS_TO_PICK, review);
    if (reviewId) {
      variables.id = reviewId;
    } else {
      variables.gcddReviewId = gcddReviewId;
    }
    await action({variables});
  };

  const onChange = (reviewIndex, key, value) => {
    setShouldUpdateWebReviewsState(false);
    const updatedReview = {
      ...webReviews[reviewIndex],
      [key]: value,
    };

    const updatedReviews = webReviews.map((webReview, index) =>
      reviewIndex === index ? updatedReview : webReview,
    );
    setWebReviews(updatedReviews);

    const {id} = updatedReview;
    const variables = pick(KEYS_TO_PICK, updatedReview);
    const isValidReview = isWebReviewValid(updatedReview);
    const isAlreadyLoading = id ? isUpdating : isSubmitting;
    const updatedId = reviews[reviewIndex]?.id;
    const reviewId = id || updatedId;

    if (isAlreadyLoading || !isValidReview) {
      return;
    }

    if (reviewId) {
      return debouncedUpdateWebReview({
        ...variables,
        id: reviewId,
      });
    }

    if (lastCreatedReviewIndex !== reviewIndex) {
      debouncedSubmitWebReview(
        {
          ...variables,
          gcddReviewId,
        },
        reviewIndex,
      );
    }
  };

  return (
    <Box mt={15}>
      {webReviews.map((webReview, index) => {
        const ordinalIndex = formatOrdinals(index + 1);
        const website = prop('website', webReview);

        return (
          <Box
            key={webReview.id || webReview.createdAt}
            mt={28}
            p={'20px 15px'}
            css={{
              borderTop: `1px solid ${basic75}`,
            }}>
            <Row
              alignItems="center"
              justifyContent="space-between"
              layout="split_wide"
              css={{marginBottom: 24}}>
              <Box>{ordinalIndex} Review</Box>
              <Box
                onClick={() => onRemoveReview(webReview?.id, index)}
                disabled={loading}
                css={{cursor: 'pointer'}}>
                <Trash />
              </Box>
            </Row>
            <Row css={{gap: 16}}>
              <SelectField
                mr={16}
                displayValue={
                  Boolean(website) && isOtherWebsite(website)
                    ? WEBSITE_OTHER
                    : null
                }
                label="Website"
                options={WEBSITE_OPTIONS}
                placeholder="Select website"
                value={website}
                onChange={(value) => onChange(index, 'website', value)}
                required
              />
              <ButtonGroup
                mr={3}
                label="Star rating"
                options={STAR_RATING_OPTIONS}
                value={webReview.rating}
                inputValue={webReview.rating}
                onChange={(value) => onChange(index, 'rating', value)}
                required
              />
            </Row>
            {isOtherWebsite(website) ? (
              <Row>
                <Box width="0.6">
                  <TextField
                    label="If other, please enter website name"
                    name="otherWebsite"
                    placeholder="Enter website name"
                    value={website}
                    inputValue={website}
                    onChange={(value) => onChange(index, 'website', value)}
                    required
                  />
                </Box>
              </Row>
            ) : null}
            <Row>
              <TextField
                label="URL"
                name="url"
                inputValue={webReview.url}
                value={webReview.url}
                onBlur={() => onBlur(webReview, index)}
                onChange={(value) => onChange(index, 'url', value)}
                required
              />
            </Row>
            <Row>
              <Textarea
                label="Comment"
                name="comment"
                value={webReview.comment}
                onBlur={() => onBlur(webReview, index)}
                onChange={(value) => onChange(index, 'comment', value)}
                required
              />
            </Row>
          </Box>
        );
      })}
      <Flex
        p="20px 15px"
        marginBottom="-16px"
        css={{
          borderTop: `1px solid rgba(115, 115, 115, .3)`,
        }}
        justifyContent="flex-end">
        <Button small onClick={onAddReview}>
          Add another review
        </Button>
      </Flex>
    </Box>
  );
}

WebReviewList.propTypes = {
  gcddReviewId: PropTypes.string,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      createdAt: PropTypes.string,
      gcddReviewId: PropTypes.string,
      id: PropTypes.string,
      rating: PropTypes.number,
      updatedAt: PropTypes.string,
      url: PropTypes.string,
      website: PropTypes.string,
    }),
  ),
};

export default WebReviewList;
