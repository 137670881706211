import React, {Fragment, useMemo} from 'react';

import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import {basic15, basic75} from '@renofi/theme/src/colors';

export {ReactTooltip};

const Tooltip = ({
  content,
  children,
  type = 'dark',
  triggerEvent,
  place = 'bottom',
  elementCss = {},
  disable,
  height,
  white,
  tag: Tag = 'a',
  ...props
}) => {
  const randomId = useMemo(() => {
    if (process.env.NODE_ENV === 'test') {
      return 'jest-id';
    }

    return String(Math.random());
  }, []);

  return (
    <Fragment>
      <Tag
        data-tooltip-id={`tooltip-${randomId}`}
        style={{...elementCss, ...(height ? {height} : {})}}
        data-event={triggerEvent}>
        {children}
      </Tag>
      {content && !disable ? (
        <ReactTooltip
          id={`tooltip-${randomId}`}
          padding="10px"
          backgroundColor={basic75}
          type={type}
          place={place}
          globalEventOff="click"
          effect="solid"
          isCapture
          border={white ? `solid 1px ${basic15}` : 'none'}
          opacity={1}
          {...props}
          style={{
            zIndex: 10005,
            padding: '6px 12px',
            fontSize: 14,
            boxShadow: '0px 4px 12px 0px #00579215',
            ...props.style,
            ...(white
              ? {
                  backgroundColor: 'white',
                  color: 'black',
                }
              : {}),
          }}>
          {content}
        </ReactTooltip>
      ) : null}
    </Fragment>
  );
};

Tooltip.propTypes = {
  place: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  openOnClick: PropTypes.bool,
  type: PropTypes.string,
  trigger: PropTypes.string,
  triggerEvent: PropTypes.string,
  elementCss: PropTypes.object,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  disable: PropTypes.bool,
};

export default Tooltip;
