import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useProjectDetails} from 'api';

import {Provider as ThemeProvider} from '@renofi/theme/src';
import {
  Text,
  Flex,
  Box,
  TextAreaWithMentions,
} from '@renofi/components-internal';
import {useInternalUsersSearch} from '@renofi/graphql';
import {basic55} from '@renofi/theme';

import {getInitialNoteBody} from './utils';

const MarkReviewAsCompletedModal = ({projectId, onChange}) => {
  const {project} = useProjectDetails({
    variables: {id: projectId},
  });

  const [body, setBody] = useState('');
  const {internalUsers, searchInternalUsers} = useInternalUsersSearch();

  useEffect(() => {
    if (!project) {
      return;
    }

    const initialBody = getInitialNoteBody(project);
    setBody(initialBody);
    onChange({
      body: initialBody,
    });
  }, [project]);

  const onNoteChange = (value) => {
    onChange({body: value});
    setBody(value);
  };

  useEffect(() => {
    searchInternalUsers({variables: {query: ''}});
  }, []);

  return (
    <Flex flexDirection="column">
      <Text mb={3}>
        Please confirm that the details below are accurate and the appraisal
        review is completed.
      </Text>

      <Text color={basic55}>BP note</Text>

      <Box>
        <ThemeProvider theme="light">
          <TextAreaWithMentions
            value={body}
            users={internalUsers}
            onChange={onNoteChange}
          />
        </ThemeProvider>
      </Box>
    </Flex>
  );
};

MarkReviewAsCompletedModal.propTypes = {
  projectId: PropTypes.string,
  onChange: PropTypes.func,
};

export default MarkReviewAsCompletedModal;
