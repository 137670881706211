import {propEq} from 'ramda';
import {withCurrentSource} from 'api/hoc';
import withFeasibilityReview from 'lib/withFeasibilityReview';
import {isInternalSource} from 'lib/sourceHelper';
import {
  FEASIBILITY_STUDY_ITEM_TYPES,
  useHasFeasibilityEditPermission,
} from 'modules/viewer-v2';

import {compose, withProps} from '@renofi/recompose';

export default () =>
  compose(
    withCurrentSource(),
    withFeasibilityReview(),
    withProps(({feasibilityReview}) => ({
      feasibilityStudyItems: feasibilityReview?.feasibilityStudyItems || [],
    })),
    withProps(({feasibilityStudyItems, currentSource}) => {
      const isInternal = isInternalSource(currentSource);
      const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();

      return {
        feasibilityStudyItems: feasibilityStudyItems.map((item) => {
          const isSameSource = currentSource === item.source;
          const editable =
            (isSameSource || isInternal) && hasFeasibilityEditPermission;

          return {...item, editable};
        }),
      };
    }),
    withProps(({feasibilityStudyItems}) => ({
      groupedStudyItems: {
        inScopeStudyItems: feasibilityStudyItems.filter(
          propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE),
        ),
        outOfScopeStudyItems: feasibilityStudyItems.filter(
          propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.OUT_OF_SCOPE),
        ),
        homeownerStudyItems: feasibilityStudyItems.filter(
          propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.HOMEOWNER_RESPONSIBILITY),
        ),
      },
    })),
  );
