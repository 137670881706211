import {compose, withHandlers} from '@renofi/recompose';

import {SEND_REMINDER} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(SEND_REMINDER, {
      name: 'sendReminder',
    }),
    withHandlers({
      sendReminder:
        ({sendReminder}) =>
        ({message, tasks}) => {
          return sendReminder({variables: {message, tasks}});
        },
    }),
  );
