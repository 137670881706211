import styled from '@emotion/styled/macro';

import {actionRegular} from '@renofi/theme';

import {Box, Flex} from '../';
import {Container} from '../FieldFrame/styled';

export const InputGroupItem = styled(Box)(({flex}) => ({
  border: 'none',
  flex: flex || 0.5,
  marginRight: -1,
  maxWidth: '100%',
}));

export default styled(Flex)({
  [InputGroupItem]: {
    [Container]: {
      borderRadius: 0,
      ':hover': {
        boxShadow: '0px 0px 0px 0px #ADADAD',
        clipPath: 'inset(0 -1px 0 0)',
        zIndex: 10,
      },
      '&.field-frame-focus': {
        boxShadow: `0px 0px 0px 0px ${actionRegular}`,
        clipPath: 'inset(0 -1px 0 0)',
        zIndex: 10,
      },
    },
  },
  '&:first-of-type': {
    [Container]: {
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
  },
  '&:last-of-type': {
    [Container]: {
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
});
