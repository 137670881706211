import {
  filter,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
} from 'ramda';
import {useParams} from 'react-router-dom';

import {
  useGenerateCombinedPdf,
  useRequestFilesZip,
  useSignedUrls,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';
import logger from '@renofi/utilities/src/logger';
import {DownloadZipNotification} from '@renofi/components-internal';

import {useProjectDocuments} from '../hooks/renovation';

const getBorrowerDocuments = pipe(
  propOr([], 'documents'),
  filter(propEq('source', 'borrower')),
);

const getObjectNames = pipe(
  pipe(reject(propEq('rejectedBySystem', true))),
  pipe(reject(propEq('status', 'processing_file'))),
  map(prop('objectName')),
);

const getAllUrls = pipe(pathOr([], ['data', 'signedUrls']), map(prop('url')));

const useDownloadAllFiles = () => {
  const {projectId} = useParams();
  const {addNotification, removeAll} = useNotifications();
  const {project} = useProjectDocuments({variables: {id: projectId}});
  const {fetch: getSignedUrls} = useSignedUrls({lazy: true});
  const {generateCombinedPdf} = useGenerateCombinedPdf();
  const {requestFilesZipAsync} = useRequestFilesZip();

  const onDownloadFiles = async ({documents = [], zip = true}) => {
    const areDocsGiven = Boolean(documents.length);

    // const projectDocs = propOr([], 'documents', project);
    const borrowerDocuments = getBorrowerDocuments(project);

    const docsToDownload = areDocsGiven ? documents : borrowerDocuments;
    const {primaryApplicant} = project || {};
    const outputFileName = `${primaryApplicant?.firstName}_${primaryApplicant?.lastName}_${primaryApplicant?.id}`;

    const objectNames = getObjectNames(docsToDownload);
    if (zip) {
      await requestFilesZipAsync({objectNames, outputFileName});
      return;
    }

    const rsp = await getSignedUrls({variables: {objectNames}});
    const urls = getAllUrls(rsp);

    addNotification({
      variant: 'info',
      content: 'Your download should begin shortly',
    });

    const archive = await generateCombinedPdf({
      variables: {outputFileName, urls},
    });
    const fileUrl = path(['data', 'generateCombinedPdf', 'url'])(archive);

    removeAll();

    if (fileUrl) {
      addNotification({
        variant: 'info',
        content: <DownloadZipNotification fileUrl={fileUrl} />,
        delay: 20000,
      });

      const popup = window.open(fileUrl);
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        logger.info('POPUP_BLOCKED', popup);
      }
    } else {
      addNotification({
        variant: 'danger',
        content: 'Something went wrong',
      });
    }
  };

  return {onDownloadFiles};
};

export default useDownloadAllFiles;
