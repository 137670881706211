import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {isSplitWideLayout} from 'lib/layoutHelper';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {useChangeTaskStatus} from '@renofi/graphql';
import {Button, Text} from '@renofi/components-internal';

import {Complete as Wrapper} from '../../../Layout';

const Complete = ({disabled, layout, loading, setLoading, tasks = []}) => {
  const wide = isSplitWideLayout(layout);
  const {changeTaskStatus} = useChangeTaskStatus({awaitRefetchQueries: false});

  const onComplete = useCallback(async () => {
    setLoading(true);
    await Promise.all(
      tasks.map(({id}) =>
        changeTaskStatus({variables: {id, status: TASK_STATUSES.COMPLETED}}),
      ),
    );
    setLoading(false);
  }, [tasks]);

  return (
    <Wrapper>
      <Text
        fontSize={12}
        lineHeight="14px"
        mb={wide ? 0 : 16}
        textAlign={wide ? 'left' : 'center'}>
        Review all documents/info before marking as completed
      </Text>
      <Button
        disabled={disabled}
        loading={loading}
        variant="danger"
        onClick={onComplete}>
        Mark as complete
      </Button>
    </Wrapper>
  );
};

Complete.propTypes = {
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ),
};

export default Complete;
