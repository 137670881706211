import {noop} from 'lodash';

import {getPropertyFullAddress} from '@renofi/utilities/src/getPropertyFullAddress';

import PropertyItem from './PropertyItem';

const RealEstateDetails = ({
  areActionsDisabled = true,
  internal = false,
  onAccept = noop,
  onClickDocument = noop,
  onReject = noop,
  onClickTaskData = noop,
  project,
  task,
}) => {
  const {borrower, coborrower} = project || {};
  const {realEstateOwned} = task || {};
  const {
    subjectProperty,
    additionalProperties = [],
    soldProperties = [],
  } = realEstateOwned || {};
  const commonProps = {
    areActionsDisabled,
    borrower,
    coborrower,
    internal,
    onAccept,
    onClickDocument,
    onReject,
    onClickTaskData,
  };

  if (!realEstateOwned) {
    return null;
  }

  return (
    <>
      <PropertyItem
        address={getPropertyFullAddress(project)}
        property={subjectProperty}
        {...commonProps}
      />

      {additionalProperties.map((p) => (
        <PropertyItem key={p.id} property={p} {...commonProps} />
      ))}

      {soldProperties.map((p) => (
        <PropertyItem key={p.id} property={p} {...commonProps} />
      ))}
    </>
  );
};
export default RealEstateDetails;
