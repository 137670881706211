/* eslint-disable max-lines */
import {gql} from '@apollo/client';

import {
  additionalCreditLiability,
  appraisalComparableSaleUrl,
  appraisalPropertyPhoto,
  documentWithAllProps,
  lender,
  note,
  smsConsentRenovationProgress,
  advisorFinalCallSchedule,
  certificateAcknowledgement,
  productDetailsConfirmation,
  personalInfoAndResidency,
  realEstateOwned,
  employmentHistory,
  incomeSource,
  payStatementInformation,
  assetDetails,
  borrowersAndPropertyInformation,
  applicant,
  incomeCommentary,
} from '../partials';

export default gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      id
      actualCurrentHomeValue
      actualRenovationHomeValue
      actualCloseDate
      actualCreditScore
      actualDtiRatio
      appraisalCost
      appraisalManagementCompany
      appraiserName
      appraiserReportSummary
      arvCltvRatio
      qualifyingPayment
      completionCertificateReceivedOn
      completionCertificateOrderedOn
      additionalCreditLiabilities {
        ${additionalCreditLiability}
      }
      borrower {
        id
        email
        firstName
        lastName
        phoneNumber
        salesforceContactId
      }
      opportunity {
        appraisalCostPaidByBorrower
      }
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      homeowners {
        id
        email
        firstName
        lastName
        phoneNumber
        salesforceContactId
      }
      applicants {
        id
        email
        firstName
        lastName
      }
      primaryApplicant {
        ${applicant}
      }
      coborrower {
        ${applicant}
      }
      primaryApplicantId
      city
      comments {
        id
        body
        createdAt
        source
        subject
        updatedAt
        userId
        user {
          id
          email
          firstName
          lastName
        }
      }
      createdAt
      mortgageStatementDate
      matchedToLenderCrmId
      matchedToLenderDetails
      actualCurrentHomeValue
      statedCurrentHomeValue
      currentMortgageBalance
      currentMortgagePayment
      secondMortgageBalance
      documents {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfFileName
        pdfObjectName
        rejectedAt
        rejectedById
        rejectedBy {
          id
          firstName
          lastName
        }
        rejectedBySystem
        rejectionDetails
        rejectionMessageScheduledAt
        rejectionReason
        reviewedAt
        source
        status
      }
      exceptionRequests {
        id
        approvedAt
        approvedById
        approvedBy {
          id
          email
          firstName
          lastName
        }
        comments {
          id
          addedById
          addedBy {
            id
            email
            firstName
            lastName
          }
          createdAt
          message
          source
          subject
          updatedAt
        }
        compensatingFactors
        createdAt
        deniedAt
        deniedById
        deniedBy {
          id
          email
          firstName
          lastName
        }
        lenderId
        lender {
          ${lender}
        }
        metrics {
          id
          createdAt
          exceptional
          name
          notes
          updatedAt
          value
        }
        renofiNotes
        renovationScope
        status
        title
        updatedAt
      }
      estimatedCloseDate
      invitationSentAt
      incomeCommentaries {
        ${incomeCommentary}
      }
      lender {
        ${lender}
      }
      raas
      loanType
      loanValue
      narrative
      peakCltvRatio
      positionedInterestRate
      actualRenovationHomeValue
      estimatedRenovationHomeValue
      renovationScope
      renofiId
      renofiProvidedDocuments {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        rejectedBySystem
        rejectionDetails
        rejectionReason
        shareWithLenderAllowed
        shareable
        status
      }
      renofiPayment
      renovationCost
      renovationCostToCurrentPropertyValueRatio
      renovationReadyFile {
        id
        lenderId
        lender {
          ${lender}
        }
        loanApplicationStatus
        transmittedById
        transferredAt
        transmittedBy {
          id
          firstName
          lastName
        }
      }
      renovationReadyFiles {
        id
        createdAt
        loanApplicationStatus
        lenderId
        lender {
          ${lender}
        }
        project {
          id
          status
        }
        transferredAt
        transmittedById
        transmittedBy {
          id
          firstName
          lastName
        }
      }
      renovationUnderwritingRequestId
      renovationUnderwritingRequest {
        id
        assets
        city
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
        creditScore
        currentHomeValue
        currentMortgageBalance
        dtiRatio
        email
        firstName
        intendedStartDate
        lastName
        lender {
          ${lender}
        }
        loanValue
        phoneNumber
        postRenovationHomeValue
        renovationCost
        reserves
        ruStartedAt
        secondMortgageBalance
        state
        status
        streetAddressOne
        streetAddressTwo
        updatedAt
        zipCode
      }
      renovationUpdateSharingSettingValue
      productDetailsConfirmationStatus
      ruSummary
      salesforceOpportunityId
      selfReportedAssets
      selfReportedCreditScoreRange
      selfReportedDtiRatio
      selfReportedIncome
      selfReportedReserves
      stageName
      state
      status
      streetAddressOne
      streetAddressTwo
      notes {
        ${note}
      }
      owner {
        id
        email
        emailToCase
        name
        salesforceUserId
      }
      tasks {
        id
        additionalExplanation
        additionalRequest {
          createdAt
          message
          resolvedAt
        }
        additionalRequestMessage
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        appraisalComparableSales {
          id
          createdAt
          updatedAt
          urls
        }
        appraisalComparableSaleUrls {
          ${appraisalComparableSaleUrl}
        }
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        appraisalPropertyDetails {
          id
          additionalInformation
          additionalInformationPresent
          basementSquareFootage
          basementState
          bedroomsInBasement
          bedroomsTotal
          city
          coolingTypes
          county
          createdAt
          fireplaceTypes
          fullBathroomsInBasement
          fullBathroomsTotal
          garagesAttached
          garagesDetached
          halfBathroomsInBasement
          halfBathroomsTotal
          heatingTypes
          lat
          lng
          mapPhotoUrl
          noteForAppraiser
          noteForAppraiserPresent
          previousRenovationsDescription
          previousRenovationsDescriptionPresent
          propertyType
          propertyTypeOther
          rentalIncome
          rentalIncomeCollectedOnPrimaryResidence
          state
          streetAddressOne
          streetAddressTwo
          streetViewPhotoUrl
          totalSquareFootage
          updatedAt
          yearBuilt
          yearPurchased
          zipCode
        }
        appraisalPropertyPhotos {
          ${appraisalPropertyPhoto}
        }
        appraisalRecentTransactions {
          id
          createdAt
          date
          haveAppraisalFile
          occurredRecently
          updatedAt
          value
        }
        appraisalRenovationDetails {
          id
          additionalDetails
          additionalDetailsPresent
          createdAt
          renovationChanges {
            basement {
              changeTypes
              details
            }
            bathrooms {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            bedrooms {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            bonusRoom {
              changeTypes
              details
            }
            cooling {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            custom {
              details
              name
            }
            deck {
              changeTypes
              details
            }
            diningRoom {
              changeTypes
              details
            }
            exterior {
              changeTypes
              details
            }
            fireplaces {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            garages {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            heating {
              additional {
                details
                location
                type
              }
              existing {
                changeType
                details
                location
                type
              }
            }
            interior {
              changeTypes
              details
            }
            kitchen {
              changeTypes
              details
            }
            landscaping {
              changeTypes
              details
            }
            laundryRoom {
              changeTypes
              details
            }
            livingRoom {
              changeTypes
              details
            }
            mudRoom {
              changeTypes
              details
            }
            office {
              changeTypes
              details
            }
            outerBuildings {
              changeTypes
              details
            }
            painting {
              changeTypes
              details
            }
            patio {
              changeTypes
              details
            }
            pool {
              changeTypes
              details
            }
            porch {
              changeTypes
              details
            }
            roofing {
              changeTypes
              details
            }
            siding {
              changeTypes
              details
            }
            squareFootage {
              basement
              total
            }
            structuralChanges {
              changeTypes
              details
            }
            windows {
              changeTypes
              details
            }
          }
          updatedAt
        }
        canHaveDocument
        completedAt
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        contractor {
          id
          businessName
          city
          contactName
          contactTitle
          email
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        contractorInformations {
          id
          businessName
          city
          contactName
          contactTitle
          email
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        details
        documents {
          ${documentWithAllProps}
          rejectedById
          rejectedBy {
            id
            firstName
            lastName
          }
        }
        employmentHistories {
          adjustedGrossMonthlyIncomeBase
          adjustedGrossMonthlyIncomeBonus
          adjustedGrossMonthlyIncomeCommission
          adjustedGrossMonthlyIncomeMilitaryEntitlements
          adjustedGrossMonthlyIncomeOther
          adjustedGrossMonthlyIncomeOvertime
          ${employmentHistory}
        }
        facet
        assetDetails {
          ${assetDetails}
        }
        incomeSources {
          ${incomeSource}
        }
        informationKind
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        payStatementInformations {
          ${payStatementInformation}
        }
        requirements
        status
        submittedAt
        taskType
        title
        governmentIssuedIds {
          expirationDate
          id
          idNumber
          idType
          issueDate
          borrowerRole
          issuingCountry
          issuingState
          taskId
        }
        personalInfoAndResidency {
          ${personalInfoAndResidency}
        }
        realEstateOwned {
          ${realEstateOwned}
        }
      }
      smsConsentRenovationProgress {
        ${smsConsentRenovationProgress}
      }
      updatedAt
      yearsInHome
      zipCode
    }
  }
`;
