import {useContext} from 'react';

import {useApolloClient} from '@apollo/client';

import {
  JWT_KEY,
  deleteCookie,
  deleteAllCookies,
  REFRESH_TOKEN_KEY,
} from '../cookies';
import logger from '../logger';
import logoutWithRedirect from '../logoutWithRedirect';
import {AUTH_TOKEN, REFRESH_TOKEN, Context as StorageContext} from '../storage';
import {isDeployPreviewUrl} from '../netlify';

export default function useLogout() {
  const client = useApolloClient();
  const storage = useContext(StorageContext);

  const logout = (params = {}) => {
    try {
      client?.clearStore();
    } catch (err) {
      logger.error(err);
    }

    storage?.removeItem(AUTH_TOKEN);
    storage?.removeItem(REFRESH_TOKEN);
    // Delete cookie
    deleteCookie(JWT_KEY);
    deleteCookie(REFRESH_TOKEN_KEY);
    // Also delete all cookies for Netlify preview
    if (isDeployPreviewUrl()) {
      deleteAllCookies();
    }
    // logout of current app, including the redirect
    logoutWithRedirect(params);
  };

  return {logout};
}
