import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

export const getByValue =
  (enums = []) =>
  (prop) => {
    if (!prop) {
      return '-';
    }

    const {label} = enums.find(({value}) => prop === value) || {};
    return label || humanizeSnakeCase(prop);
  };
