import {propOr} from 'ramda';

import {useGetUser} from '@renofi/graphql';
import {canEditFeasibilityReview} from '@renofi/utilities/src/permissions';

export default function useHasFeasibilityEditPermission() {
  const {user} = useGetUser();
  const permissions = propOr([], 'permissions', user);
  const hasFeasibilityEditPermission = canEditFeasibilityReview(user);

  return {hasFeasibilityEditPermission, permissions};
}
