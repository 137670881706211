/* eslint-disable no-useless-escape */
const phoneRegex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
// from https://emailregex.com/
const emailRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const ipRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z-]+)+((\/)[\w-#]+)*(\/[\w-#]+\?[a-zA-Z0-9_-]+=[\w-#]+(&[a-zA-Z0-9_-]+=[\w-#]+)*)?(\/)?$/;

export const isEmail = (value) => emailRegex.test(value);

export const isPhone = (value) => phoneRegex.test(value);

export const isZipCode = (value) => zipCodeRegex.test(value);

export const isIP = (value) => ipRegex.test(value);

// I think we can just ignore Query String values given
// only the domain format is what we're checking for
export const isUrl = (value = '') => {
  const [prefix] = value.split('?');
  return urlRegex.test(prefix);
};

export const hasUrlProtocol = (value) =>
  value?.match(/^(ftp|http|https):\/\//i);

export const isValidDateObject = (date) => {
  return date instanceof Date && !isNaN(date);
};
