import {isEmpty, pipe, propOr} from 'ramda';
import {
  withCurrentSource,
  withSetShareablePropertyPhoto,
  withViewerLayout,
} from 'api/hoc';

import {branch, compose, renderNothing, withHandlers} from '@renofi/recompose';

import Component from './Component';

export default compose(
  branch(pipe(propOr([], 'photos'), isEmpty), renderNothing),
  withCurrentSource(),
  withSetShareablePropertyPhoto(),
  withViewerLayout(),
  withHandlers({
    onSharePhoto:
      ({projectId, setShareablePropertyPhoto}) =>
      (id, shareable) => {
        setShareablePropertyPhoto({id, projectId, shareable});
      },
  }),
)(Component);
