import React, {memo, useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useCanEditGcddReview} from 'lib/gcdd';
import {Container} from 'modules/viewer-v2/components/Layout';
import Row from 'modules/viewer-v2/components/Row';

import {Box, ButtonGroup, Text} from '@renofi/components-internal';
import SpeedIcon from '@renofi/icons/src/Speed';
import {useSetGcddReviewComplexityLevel} from '@renofi/graphql';

const SCORE_BUTTON_OPTIONS = [
  {label: 'Low', value: 'low'},
  {label: 'Medium', value: 'medium'},
  {label: 'High', value: 'high'},
];

const ComplexityLevel = ({gcddReview}) => {
  const [level, setLevel] = useState();
  const {canEdit} = useCanEditGcddReview(gcddReview);
  const {loading, setGcddReviewComplexityLevel} =
    useSetGcddReviewComplexityLevel();
  const id = gcddReview?.id;

  useEffect(() => {
    setLevel(gcddReview?.complexityLevel);
  }, [gcddReview]);

  const onChangeValue = useCallback(
    (complexityLevel) => {
      setLevel(complexityLevel);
      setGcddReviewComplexityLevel({variables: {complexityLevel, id}});
    },
    [id],
  );

  return (
    <Container
      header={
        <Row alignItems="center">
          <Box height={24}>
            <SpeedIcon />
          </Box>
          <Text ml={10}>CDD complexity level</Text>
        </Row>
      }>
      <Box px={3}>
        <ButtonGroup
          active
          disabled={loading || !canEdit}
          label="Select complexity level"
          onChange={onChangeValue}
          options={SCORE_BUTTON_OPTIONS}
          value={level}
        />
      </Box>
    </Container>
  );
};

ComplexityLevel.propTypes = {
  gcddReview: PropTypes.shape({
    complexityLevel: PropTypes.string,
    id: PropTypes.string.isRequired,
  }),
};

export default memo(ComplexityLevel);
