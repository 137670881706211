import React, {forwardRef, useState, isValidElement} from 'react';

import PropTypes from 'prop-types';
import {
  filter,
  find,
  ifElse,
  isNil,
  join,
  map,
  pipe,
  propEq,
  propOr,
  reject,
} from 'ramda';
import {noop} from 'lodash';

import FieldFrame from '../../FieldFrame';
import {getLayoutProps} from '../../utils';
import FieldInput from '../../FieldInput';

const translateValue = ({displayValue, multi, options, value}) => {
  const isMultiSelect = !displayValue && multi;

  return isMultiSelect
    ? pipe(
        ifElse(
          () => Array.isArray(value),
          filter((option) => value.includes(option?.value)),
          filter(propEq('value', value)),
        ),
        map(propOr(null, 'label')),
        reject(isNil),
        join(', '),
      )(options)
    : pipe(find(propEq('value', value)), propOr('', 'label'))(options);
};

const TriggerFieldFrame = forwardRef((props, parentRef) => {
  const {
    children,
    className,
    disabled,
    displayValue,
    empty,
    error,
    help,
    fake,
    info,
    label,
    leftIcon,
    open,
    placeholder,
    searchable,
    small,
    validations,
    onValidate = () => {},
    showValidation,
    name,
  } = props;

  const [validationErr, setValidationErr] = useState(null);
  const isPlaceholder = !children && !displayValue && !translateValue(props);

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  return (
    <FieldFrame
      {...getLayoutProps(props)}
      css={{
        visibility: searchable && open ? 'hidden' : 'visible',
      }}
      inputStyles={{paddingRight: '0 !important'}}
      focused={open}
      className={className}
      disabled={disabled}
      error={showValidation ? validationErr : error}
      info={info}
      inactive={empty || fake}
      leftIcon={leftIcon}
      ref={parentRef}
      rightIcon="expand"
      label={label}
      small={small}
      placeholder={placeholder}
      help={help}>
      {isPlaceholder && isValidElement(placeholder) ? (
        placeholder
      ) : (
        <FieldInput
          fake={fake || !searchable}
          light={isPlaceholder}
          validations={validations}
          onValidate={validationHandler}
          showValidation={showValidation}
          value={
            children || displayValue || translateValue(props) || placeholder
          }
          onChange={noop}
          name={name}
        />
      )}
    </FieldFrame>
  );
});

TriggerFieldFrame.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayValue: PropTypes.string,
  empty: PropTypes.bool,
  small: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  help: PropTypes.string,
  info: PropTypes.node,
  fake: PropTypes.bool,
  label: PropTypes.string,
  leftIcon: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  searchable: PropTypes.bool,
};

export default TriggerFieldFrame;
