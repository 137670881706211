import React from 'react';

import PropTypes from 'prop-types';

import {white} from '@renofi/theme/src/colors';

const Minus = ({color = white, height = 24, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0504 11.0505L3.52144 11.0505V12.9495L11.0504 12.9495H12.9495L20.4785 12.9495V11.0505L12.9495 11.0505H11.0504Z"
        fill={color}
      />
    </svg>
  );
};

Minus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Minus;
