import React, {Fragment, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {find, isNil, lensProp, pick, prop, propEq, set} from 'ramda';
import {getContractorName, useCanEditGcddReview} from 'lib/gcdd';
import {
  Container,
  Col,
  Row,
  StatusBadge,
  getGcddDocumentStatus,
} from 'modules/viewer-v2';

import {
  Box,
  ButtonGroup,
  DateField,
  Text,
  TextField,
  StatesField,
  Textarea,
} from '@renofi/components-internal';
import DropZone from '@renofi/components-internal/src/next/DropZone';
import {
  useSubmitGcddDocument,
  useUpdateLicense,
  useSubmitReviewNotes,
  useUploadFiles,
} from '@renofi/graphql';
import {useQueryString} from '@renofi/utilities/src/hooks';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {GET_GCDD_REVIEWS_BY_PROJECT_ID} from '@renofi/graphql';

import {getStartEndDate} from './utils';
import {FILE_PROPS, LICENSE_PROPS} from './constants';

function StateLicense({documents, gcddReview}) {
  const {insuranceNotes, referencesNotes, webReviewsNotes, questionnaire} =
    gcddReview;
  const {projectId, ...params} = useParams();
  const search = useQueryString();
  const [license, setLicense] = useState(null);
  const [reviewNotes, setReviewNotes] = useState(
    gcddReview?.licenseNotes || '',
  );
  const [showComments, setShowComments] = useState(Boolean(reviewNotes));

  const refetchQueries = [
    {
      query: GET_GCDD_REVIEWS_BY_PROJECT_ID,
      variables: {projectId},
    },
  ];
  const {loading: isSubmitting, submitGcddDocument} = useSubmitGcddDocument({
    refetchQueries,
  });
  const {updateLicense} = useUpdateLicense({refetchQueries});
  const {submitReviewNotes} = useSubmitReviewNotes({refetchQueries});
  const {isUploading, uploadFiles} = useUploadFiles({
    basePath: 'contractor_license',
  });

  const documentId = search?.documentId || params?.documentId;
  const gcddReviewId = prop('id', gcddReview);
  const {canEdit} = useCanEditGcddReview(gcddReview);
  const isEmpty = isNil(license);
  const isLoading = isSubmitting || isUploading;
  const disabled = isLoading || isEmpty || !canEdit;
  const startEndDate = getStartEndDate(gcddReview);
  const {status, variant} = getGcddDocumentStatus(license, questionnaire);
  const contractorName = getContractorName(gcddReview);

  const debouncedUpdateLicense = useDebounce(async (variables) => {
    await updateLicense({variables});
  }, 250);

  const debouncedSubmitReviewNotes = useDebounce(async (variables) => {
    await submitReviewNotes({variables});
  }, 1000);

  const onChangeNotes = (value) => {
    setReviewNotes(value);
    debouncedSubmitReviewNotes({
      gcddReviewId,
      licenseNotes: value,
      insuranceNotes,
      referencesNotes,
      webReviewsNotes,
    });
  };

  useEffect(() => {
    const currentLicense = find(propEq('id', documentId), documents);
    setLicense(currentLicense);
  }, [documents, documentId]);

  const onChangeValue = (key, value) => {
    const updated = set(lensProp(key), value, license || {});
    const picked = pick(LICENSE_PROPS, updated);

    setLicense(updated);

    if (!license?.id) {
      return;
    }

    debouncedUpdateLicense({
      ...picked,
      id: license?.id,
    });
  };

  const onAcceptFiles = async (files) => {
    const [uploadedFile] = await uploadFiles(files);
    const fileSizeBytes = prop('size', uploadedFile);
    const licenseProps = pick(LICENSE_PROPS, license || {});
    const fileProps = pick(FILE_PROPS, uploadedFile);

    await submitGcddDocument({
      variables: {
        documentType: 'contractor_license',
        fileSizeBytes,
        gcddReviewId,
        ...fileProps,
        ...licenseProps,
      },
    });
  };

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Contractor state license</Text>
          <StatusBadge variant={variant}>{status}</StatusBadge>
        </Row>
      }
      collapsible={false}
      comment
      toggleCommentSection={() => setShowComments(!showComments)}>
      <Fragment>
        {showComments && (
          <Row>
            <Col width={1}>
              <Textarea
                label="Add a section comment"
                help="Comments are visible to the lender"
                rows={4}
                value={reviewNotes}
                onChange={onChangeNotes}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <StatesField
              active
              disabled={disabled}
              label="State"
              placeholder="Select state"
              onChange={(v) => onChangeValue('state', v)}
              value={license?.state || null}
            />
          </Col>
          <Col>
            <DateField
              active
              disabled={disabled}
              label="Expiry date"
              onChange={(v) => onChangeValue('expiryDate', v)}
              value={license?.expiryDate || null}
              popupCss={{left: -40}}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ButtonGroup
              disabled={disabled}
              label="Name on cert matches actual name"
              onChange={(v) => onChangeValue('namesMatch', v)}
              value={license?.namesMatch || null}
            />
          </Col>
          <Col>
            <TextField
              active
              disabled
              rightIcon="lock"
              label="Actual contractor name"
              value={contractorName}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextField
              active
              disabled={disabled}
              label="State license number"
              onChange={(v) => onChangeValue('number', v)}
              value={license?.number || ''}
            />
          </Col>
          <Col>
            <TextField
              active
              disabled
              rightIcon="lock"
              label="Estimated project start / end dates"
              value={startEndDate}
            />
          </Col>
        </Row>

        <Row>
          <Box px={12} width={1}>
            <DropZone
              disabled={disabled}
              fileName="State license"
              multiple={false}
              onAcceptFiles={onAcceptFiles}
              onRejectFiles={() => {}}
            />
          </Box>
        </Row>
      </Fragment>
    </Container>
  );
}

StateLicense.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
    project: PropTypes.shape({
      timeline: PropTypes.shape({
        expectedRenovationCompletionOn: PropTypes.string,
        expectedRenovationStart: PropTypes.string,
      }).isRequired,
    }).isRequired,
    insuranceNotes: PropTypes.string,
    referencesNotes: PropTypes.string,
    webReviewsNotes: PropTypes.string,
    questionnaire: PropTypes.shape({
      returnedAt: PropTypes.string,
    }),
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

export default StateLicense;
