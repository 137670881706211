import {useContext} from 'react';

import {Context as ConfigContext} from '../config';
import {Context as StorageContext} from '../storage';

export default () => {
  const config = useContext(ConfigContext);
  const storage = useContext(StorageContext);

  return {config, storage};
};
