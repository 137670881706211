import {gql} from '@apollo/client';

export default gql`
  query notesSearch($filter: NotesSearchFilterInputObject) {
    notesSearch(filter: $filter) {
      metadata {
        facets {
          author {
            count
            user {
              email
              fullName
              id
            }
          }
          mentionedUser {
            count
            user {
              email
              fullName
              id
            }
          }
          renofiAdvisor {
            count
            user {
              email
              fullName
              id
            }
          }
        }
      }
    }
  }
`;
