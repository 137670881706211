import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose';

import {GET_RENOVATION_READY_FILE} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    withQuery({
      query: GET_RENOVATION_READY_FILE,
      props: ({data: {loading, renovationReadyFileById}, ...props}) => ({
        loading,
        renovationReadyFile: renovationReadyFileById,
        ...props,
      }),
      options,
    }),
    mapProps(mapper),
  );
