import queryString from 'query-string';
import {keys} from 'lodash';

import noop from '@renofi/utilities/src/noop';
import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';
import {openTab} from '@renofi/utilities/src/window';
import {setCookie} from '@renofi/utilities/src/cookies';

import {IMPERSONATE} from '../../mutations';
import useMutation from '../useMutation';

export default function useImpersonate({onCompleted = noop} = {}) {
  const [impersonate, {data, loading, error}] = useMutation(IMPERSONATE, {
    onCompleted,
  });

  const response = data?.impersonate;

  const impersonateByUserId = async ({
    id,
    params = {},
    redirectUrl = '',
  } = {}) => {
    if (!redirectUrl) {
      return console.warn(`[🥸] No redirect URL given`);
    }

    const rsp = await impersonate({variables: {id}});
    // const {jwt, refreshToken} = pathOr({}, ['data', 'impersonate'], response);
    const {jwt, refreshToken} = rsp?.data?.impersonate || {};
    const isPreviewUrl = isDeployPreviewUrl();

    // include extra params in the URL as part of Netlify Preview domains
    const options = isPreviewUrl
      ? {...params, jwt, refreshToken}
      : {jwt, refreshToken};
    const qString = queryString.stringify(options);

    // Set params as cookies
    keys(params).forEach((key) => {
      setCookie(key, params[key]);
    });

    if (!jwt) {
      return console.warn(`[🥸] No JWT while trying to impersonate user ${id}`);
    }

    console.warn(`[🥸] Opening: ${redirectUrl}?${qString}`);
    openTab(`${redirectUrl}?${qString}`);
  };

  return {impersonate, impersonateByUserId, response, loading, error};
}
