import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REVIEW_DOCUMENT} from '../../../mutations';

export default function useReviewDocument({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [reviewDocument, {data, loading, error}] = useMutation(
    REVIEW_DOCUMENT,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.reviewDocument?.document;

  return {reviewDocument, response, loading, error};
}
