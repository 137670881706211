import {Flex, TaskDetailsCard} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Label, Value} from '../styled';

const SoldPropertyDetails = ({onClickValue, property}) => {
  return (
    <TaskDetailsCard>
      <Flex>
        <Label>Property type:</Label>
        <Value onClick={onClickValue}>
          {humanizeSnakeCase(property?.propertyStructureType)}
        </Value>
        <Label>Date sold:</Label>
        <Value onClick={onClickValue}>{property?.soldDate}</Value>
      </Flex>
    </TaskDetailsCard>
  );
};

export default SoldPropertyDetails;
