import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme';
import {slideInComponent} from '@renofi/utilities/src/animations';

import Alert from '../Alert';
import Flex from '../Flex';

export const Notification = styled(Alert)(
  {
    animation: `${slideInComponent()} 300ms ease-out`,
    borderRadius: 0,
    fontSize: 17,
    zIndex: 1100,
    padding: 16,

    [maxSmall]: {
      padding: 8,
    },
  },
  ({visible}) => ({
    opacity: visible ? 1 : 0,
    height: visible ? 60 : 0,
    ...(visible ? {} : {minHeight: 0, padding: 0}),
  }),
);

export const Content = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
});

export const Wrapper = styled(Flex)(({top}) => ({
  width: '100%',
  position: 'fixed',
  left: 0,
  top,
  zIndex: 1100,
}));
