import React from 'react';

import {Redirect} from 'react-router-dom';
import {fromPairs, isNil, map, propEq, pipe, prop, propOr} from 'ramda';
import groupTasksIntoFacets from 'lib/groupTasksIntoFacets';

import {branch, compose, renderComponent, withProps} from '@renofi/recompose';
import {withFeasibilityReview} from '@renofi/graphql';
import {withStorage} from '@renofi/utilities/src/storage';
import {Alert} from '@renofi/components-internal';

import {useFeasibilityReviewProject} from '../hooks';

export default () =>
  compose(
    withStorage,
    withProps(() => {
      return useFeasibilityReviewProject();
    }),
    branch(pipe(prop('feasibilityReviewId'), isNil), () => () => (
      <Alert variant="danger">
        <h3>feasibilityReviewId is null.</h3>
      </Alert>
    )),
    withFeasibilityReview({breakOnError: false}),
    withProps(({graphqlError}) => ({
      graphqlError: graphqlError?.message,
    })),
    branch(
      propEq('graphqlError', 'RECORD_NOT_FOUND'),
      renderComponent(() => <Redirect to="/not-found" />),
    ),
    branch(pipe(prop('feasibilityReview'), isNil), () => () => (
      <Alert variant="danger">
        <h3>Feasibility review not found.</h3>
      </Alert>
    )),
    withProps(({feasibilityReview}) => {
      const project = propOr(null, 'project', feasibilityReview);
      const projectId = propOr(null, 'id', project);
      const facets = groupTasksIntoFacets(project).filter(
        ({tasks}) => !!tasks.length,
      );

      return {
        facets,
        project,
        projectId,
      };
    }),
    withProps(({facets}) => ({
      facets: pipe(
        map(({tasks, value}) => [value, tasks]),
        fromPairs,
      )(facets),
    })),
  );
