import React from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';
import {Checkbox as RebassCheckbox} from '@rebass/forms';

import {useTheme} from '@renofi/theme/src';

import {Container, Label, Wrapper} from './styled';

const spaceKeys = [
  'm',
  'mb',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
  'width',
];

const fontKeys = ['color', 'fontSize', 'lineHeight', 'fontWeight'];

const Checkbox = ({
  button = false,
  small,
  smallLabel,
  checked,
  disabled,
  children,
  onChange,
  label,
  wrapperCss = {},
  name,
  value,
  noBorder,
  ...props
}) => {
  const {styles} = useTheme('checkbox', {disabled, ...props});
  return (
    <Wrapper
      small={small}
      noBorder={noBorder}
      disabled={disabled}
      button={button}
      checked={checked}
      css={wrapperCss}
      {...pick(spaceKeys, props)}
      onClick={(event) => {
        if (!button) return;
        return onChange && onChange(!checked, event);
      }}>
      <Container
        {...omit([...spaceKeys, ...fontKeys], props)}
        empty={!label && !children}>
        <RebassCheckbox
          minWidth={small ? 20 : 30}
          checked={Boolean(checked)}
          disabled={disabled}
          name={name}
          value={value}
          onChange={(event) => {
            if (button) return;
            return onChange && onChange(event.target.checked, event);
          }}
          css={{margin: 0, cursor: 'pointer', borderRadius: 10}}
          onClick={(e) => e.stopPropagation()}
        />
        {(label || children) && (
          <Label
            xSmall={smallLabel}
            small={small}
            disabled={disabled}
            css={styles}
            {...pick(fontKeys, props)}>
            {label || children}
          </Label>
        )}
      </Container>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  button: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  small: PropTypes.bool,
  smallLabel: PropTypes.bool,
};

export default Checkbox;
