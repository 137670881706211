import {pick} from 'ramda';
import {withRouter} from 'react-router-dom';

import {compose, mapProps} from '@renofi/recompose';

export default (keys) =>
  compose(
    withRouter,
    mapProps(({match, params, ...props}) => ({
      ...props,
      params: {
        ...params,
        ...pick(keys, match?.params),
      },
    })),
  );
