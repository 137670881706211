import {isString} from 'lodash';

export default function calculateAge(string) {
  if (!string || !isString(string)) return '';

  const birthDate = new Date(string);
  const today = new Date();
  let age = today.getFullYear() - birthDate?.getFullYear();
  const monthDiff = today.getMonth() - birthDate?.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate?.getDate())
  ) {
    age--;
  }

  return age;
}
