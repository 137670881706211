import {either, isEmpty, isNil, propOr} from 'ramda';
import withRenovationDetails from 'lib/withRenovationDetails';

import {compose, withProps} from '@renofi/recompose';
import spreadProps from '@renofi/utilities/src/spreadProps';

import Component from './Component';

export default compose(
  spreadProps(['task']),
  withProps(({appraisalRenovationDetails, project}) => ({
    empty: either(isNil, isEmpty)(appraisalRenovationDetails),
    tasks: propOr([], 'tasks', project),
  })),
  withRenovationDetails(),
)(Component);
