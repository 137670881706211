import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {Complete as Wrapper} from 'modules/viewer-v2';
import {OrderAnalysisModal} from 'modules/modals';

import {DropdownButton, Flex} from '@renofi/components-internal';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {getActions} from './utils';

const Actions = ({
  markFeasibilityReviewAsCompleted,
  feasibilityReviewId,
  disabled,
  completed,
  requested,
  canComplete,
  canRequestSecond,
  canUpdate,
}) => {
  const {addNotification} = useNotifications();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const options = getActions({canComplete, canRequestSecond, canUpdate});

  const onComplete = useCallback(async () => {
    try {
      setLoading(true);

      await markFeasibilityReviewAsCompleted({
        id: feasibilityReviewId,
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to mark feasibility review as completed',
      });
    } finally {
      setLoading(false);
    }
  }, [feasibilityReviewId, markFeasibilityReviewAsCompleted]);

  const selectHandler = useCallback(
    (value) => {
      if (value === 'request') {
        setModal(true);
      }

      if (value === 'complete') {
        onComplete();
      }
    },
    [onComplete],
  );

  if (completed || !requested) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <Flex width={1} justifyContent="flex-end">
          <DropdownButton
            disabled={disabled || loading}
            id="feasibility-actions"
            options={options}
            onSelect={selectHandler}
            place="top-end"
            variant="danger"
          />
        </Flex>
      </Wrapper>
      {modal && <OrderAnalysisModal second onClose={() => setModal(false)} />}
    </>
  );
};

Actions.propTypes = {
  disabled: PropTypes.bool,
  markFeasibilityReviewAsCompleted: PropTypes.func,
  feasibilityReviewId: PropTypes.string,
  completed: PropTypes.bool,
  requested: PropTypes.bool,
  canComplete: PropTypes.bool,
  canRequestSecond: PropTypes.bool,
  canUpdate: PropTypes.bool,
};

export default Actions;
