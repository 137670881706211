import {gql} from '@apollo/client';

export default gql`
  mutation submitBorrowersInfo(
    $projectId: ID!
    $primaryApplicant: ApplicantInputObject
    $coborrower: ApplicantInputObject
  ) {
    submitBorrowersInfo(
      projectId: $projectId
      primaryApplicant: $primaryApplicant
      coborrower: $coborrower
    ) {
      project {
        id
      }
    }
  }
`;
