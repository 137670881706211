import React, {useEffect, useRef} from 'react';

import PropTypes from 'prop-types';
import {keys} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {useTheme} from '@renofi/theme';

import {Wrapper} from './styled';

const get = (rect, key) => rect[key] || 0;

const getAreaOfOverlap = (rect1, rect2) => {
  return (
    (Math.max(get(rect1, 'left'), get(rect2, 'left')) -
      Math.min(get(rect1, 'right'), get(rect2, 'right'))) *
    (Math.max(get(rect1, 'top'), get(rect2, 'top')) -
      Math.min(get(rect1, 'bottom'), get(rect2, 'bottom')))
  );
};

const Tab = ({children, containerRef, id: tabId, ...props}) => {
  const {styles} = useTheme('tab', props);
  const {search} = useViewerNavigation();
  const ref = useRef();

  useEffect(() => {
    const isCurrentTab = keys(search).some((key) => search[key] === tabId);
    const el = document.getElementById(tabId);
    const tabBounds = ref?.current?.getBoundingClientRect();
    const containerBounds = containerRef?.current?.getBoundingClientRect();
    if (!tabBounds || !containerBounds) {
      return;
    }

    // Maths baby!
    // https://math.stackexchange.com/questions/2449221/calculating-percentage-of-overlap-between-two-rectangles
    const area = get(tabBounds, 'width') * get(tabBounds, 'height');
    const overlap = getAreaOfOverlap(tabBounds, containerBounds);
    const ratioOfTabVisible = overlap / area;
    const isTooObscured = ratioOfTabVisible < 0.65;
    // 65% is pretty arbitrary TBH, just a case of how obscured is too much

    if (isCurrentTab && isTooObscured && Boolean(el)) {
      el.scrollIntoView({inline: 'center', behavior: 'smooth'});
    }
  }, [search, tabId]);

  return (
    <Wrapper css={styles} id={tabId} ref={ref} {...props}>
      {children}
    </Wrapper>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default Tab;
