import {useEffect} from 'react';

import withCurrentProject from 'lib/withCurrentProject';

import {useBorrowerInvitedToProject} from '@renofi/graphql';
import {compose, withProps} from '@renofi/recompose';

import Component from './Component';

const LAZY = {lazy: true};

export default compose(
  withCurrentProject(),
  withProps(({project}) => {
    const {borrowerInvitedToProject, fetch} = useBorrowerInvitedToProject(LAZY);

    useEffect(() => {
      if (!project) {
        return null;
      }

      const {primaryApplicant, id, matchedToLenderCrmId, raas} = project;
      const borrowerId = primaryApplicant.id;
      const variables = {
        project: {
          id,
          borrowerId,
          raasLead: raas,
          matchedToLenderCrmId,
        },
      };
      fetch({variables});
    }, [project]);
    return {borrowerInvitedToProject};
  }),
)(Component);
