export default `
id
email
firstName
middleName
lastName
nameSuffix
fullName
phoneNumber
dateOfBirth
maritalStatus
citizenshipType
dependentsAges
dependentsNumber
selfReportedCreditScore
onLoan
onTitle
alternativeNames {
  id
  firstName
  lastName
  middleName
  nameSuffix
}
`;
