import {compose, withHandlers} from '@renofi/recompose';

import {CHANGE_RENOVATION_UPDATE} from '../mutations';
import {GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE} from '../queries';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(CHANGE_RENOVATION_UPDATE, {
      name: 'changeRenovationUpdate',
      options: {
        refetchQueries: [GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE],
      },
    }),
    withHandlers({
      changeRenovationUpdate:
        ({changeRenovationUpdate}) =>
        (variables) => {
          return changeRenovationUpdate({variables});
        },
    }),
  );
