import styled from '@emotion/styled';

import {actionDark, actionRegular, basic15, basic20} from '@renofi/theme';

import {Button} from '../index';

export default styled(Button)(
  {
    border: 'transparent',
    padding: 0,
    'svg path': {
      fill: basic20,
    },
    svg: {
      cursor: 'pointer !important',
    },
  },
  ({
    frameHover,
    selected,
    width,
    height,
    dark,
    border,
    embedInTextField,
    css = {},
  }) => {
    const hover = {
      'svg path': {
        fill: actionDark,
      },
    };
    const frame = {
      border: `solid 1px ${actionRegular}`,
      borderRadius: 3,
    };
    return {
      ...(border ? {border: `solid 1px transparent`} : {}),
      height: height || 24,
      width: width || 24,
      borderRadius: 3,
      ...(dark
        ? {
            'svg path': {
              fill: actionDark,
            },
          }
        : {}),
      ...(selected
        ? {
            ...hover,
            backgroundColor: '#00a0ff22',
          }
        : {}),
      ...(frameHover && selected ? frame : {}),
      '&:hover': {
        ...hover,
        backgroundColor: selected ? '#00a0ff22' : '#F0F9FF',
        ...(frameHover ? frame : {border: 'transparent'}),
      },
      ...css,
      ...(embedInTextField
        ? {
            width: 36,
            height: 36,
            marginTop: -2,
            marginBottom: -2,
            marginRight: -1,
            paddingTop: 5,
            borderBottomLeftRadius: '0 !important',
            borderTopLeftRadius: '0 !important',
            borderLeft: `solid 1px ${basic15}`,
          }
        : {}),
    };
  },
);
