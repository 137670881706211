import {compose, lifecycle, branch, renderComponent} from '@renofi/recompose';
import {withUser} from '@renofi/graphql';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import {getLocation} from '@renofi/utilities/src/window';
import {withStorage} from '@renofi/utilities/src/storage';
import withNavigate from '@renofi/utilities/src/withNavigate';

import Component from './Component';

export default compose(
  withUser(),
  withStorage,
  withNavigate,
  branch(
    ({errors, user}) => {
      return (
        !user &&
        errors &&
        Boolean(errors.length) &&
        errors[0]?.message === 'AUTHENTICATION_ERROR'
      );
    },
    renderComponent(
      lifecycle({
        componentDidMount() {
          const {storage} = this.props;
          const location = getLocation();
          storage.clear();
          storage.setItem(
            'path:redirect',
            `${location.pathname}${location.search}`,
          );
          logoutWithRedirect();
        },
      })(() => null),
    ),
  ),
)(Component);
