import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import noop from '@renofi/utilities/src/noop';
import {useTheme} from '@renofi/theme/src';
import {basic03} from '@renofi/theme/src/colors';

import Icon from '../Icon';
import Loader from '../Loader';

import {Wrapper} from './styled';

const Button = ({
  children,
  disabled,
  fakeDisabled,
  loading,
  success,
  onClick = noop,
  to,
  ...props
}) => {
  const isDisabled = disabled || loading || success;
  const {styles} = useTheme('button', {
    defaultVariant: 'primary',
    disabled: isDisabled || fakeDisabled,
    ...props,
  });
  const history = useHistory();

  const onButtonClick = useCallback(
    (event) => {
      if (isDisabled || fakeDisabled) {
        return;
      }

      onClick(event);
      if (to) {
        history.push(to);
      }
    },
    [isDisabled, fakeDisabled, onClick, to],
  );

  return (
    <Wrapper
      disabled={isDisabled}
      fakeDisabled={fakeDisabled}
      onClick={onButtonClick}
      css={styles}
      {...props}>
      {!loading && !success ? children : null}
      {loading && !success && (
        <Loader
          label={false}
          css={{marginTop: -4}}
          innerColor={basic03}
          width={22}
        />
      )}
      {success && !loading && <Icon name="check" />}
    </Wrapper>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fakeDisabled: PropTypes.bool,
  large: PropTypes.bool,
  loading: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default Button;
