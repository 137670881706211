import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose';

import {GET_FEASIBILITY_REVIEWS} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options, ...config} = {}) =>
  compose(
    withQuery({
      query: GET_FEASIBILITY_REVIEWS,
      options: options || (({projectId}) => ({variables: {projectId}})),
      props: ({
        data: {feasibilityReviews, loading, previousData},
        ...props
      }) => ({
        loading,
        feasibilityReviews:
          feasibilityReviews || previousData?.feasibilityReviews || [],
        ...props,
      }),
      ...config,
    }),
    mapProps(mapper),
  );
