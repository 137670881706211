import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {propOr, isEmpty, isNil} from 'ramda';
import {useParams} from 'react-router-dom';
import {SCORES} from 'lib/gcdd';
import {Tooltip, ValidationListItem} from 'modules/viewer-v2';
import {GcddSetOverallScore, ModalsContext} from 'modules/modals';

import {useNotifications} from '@renofi/utilities/src/hooks';
import {Button, Text, MouseHover} from '@renofi/components-internal';
import {
  GET_GCDD_REVIEWS_BY_PROJECT_ID,
  useMarkGcddReviewAsCompleted,
  useSubmitOverallScore,
} from '@renofi/graphql';
import {basic20, basic55} from '@renofi/theme';

import {StickyFooter} from './styled';
import {
  getStateLicenseValidationItems,
  getInsuranceValidationItems,
  getReferenceValidationItems,
  getWebReviewValidationItems,
  getBetterBusinessBureauItems,
} from './utils';

const GcddReviewFooter = ({gcddReview}) => {
  const {addNotification} = useNotifications();
  const {projectId} = useParams();
  const refetchQueries = [
    {query: GET_GCDD_REVIEWS_BY_PROJECT_ID, variables: {projectId}},
  ];

  const {loading: isMarkingComplete, markGcddReviewAsCompleted} =
    useMarkGcddReviewAsCompleted({refetchQueries});
  const {
    loading: isScoring,
    submitOverallScore,
    abort,
  } = useSubmitOverallScore({refetchQueries});
  const [hover, setHover] = useState(false);
  const isSubmitting = isMarkingComplete || isScoring;

  const questionnaire = propOr({}, 'questionnaire', gcddReview);
  const contractor = propOr({}, 'contractor', gcddReview);

  const {overallScore, webReviewsScore} = gcddReview;
  const {contractorLicense, references} = questionnaire;
  const {bbbListed, bbbGrade} = contractor;

  const isFailScore = overallScore === SCORES.FAIL;

  const stateLicenseItems = getStateLicenseValidationItems(contractorLicense);
  const insuranceItems = getInsuranceValidationItems(questionnaire);
  const referencesItems = getReferenceValidationItems(references);
  const webReviewItems = getWebReviewValidationItems(webReviewsScore);
  const bbbItems = getBetterBusinessBureauItems(bbbListed, bbbGrade);

  const buttonText = isFailScore
    ? 'Return to RU for review'
    : 'Return to RU as completed';

  const validationItems = [
    ...stateLicenseItems,
    ...referencesItems,
    ...webReviewItems,
    ...bbbItems,
  ];

  const hasInvalidInsuranceItems = insuranceItems.some(
    ({items}) => !isEmpty(items),
  );

  const isBtnDisabled = !isEmpty(validationItems) || hasInvalidInsuranceItems;

  const onSubmitModal = async (gcddReview) => {
    const gcddReviewId = propOr(null, 'id', gcddReview);
    const overallScore = propOr(null, 'overallScore', gcddReview);
    const overallScoreNotes = propOr(null, 'overallScoreNotes', gcddReview);

    if (!gcddReviewId) {
      return;
    }

    try {
      await submitOverallScore({
        variables: {gcddReviewId, overallScore, overallScoreNotes},
      });

      await markGcddReviewAsCompleted({
        variables: {
          id: gcddReviewId,
        },
      });

      abort();

      addNotification({
        variant: 'success',
        content: 'CDD is successfully returned to the RU team',
        type: 'snackbar',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to return CDD to the RU Team',
        type: 'snackbar',
      });
    }
  };

  return (
    <StickyFooter>
      <Text
        fontSize={12}
        lineHeight="14px"
        mb={0}
        color={basic55}
        textAlign="left">
        Review all documents/info returning to RU
      </Text>
      <MouseHover
        type="div"
        hideDelay={500}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {}}>
        <>
          {isBtnDisabled && hover && (
            <Tooltip
              width={280}
              css={{
                width: 300,
                right: 10,
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '90vh',
              }}
              tailPosition="right"
              onClose={() => setHover(false)}>
              <Text fontSize={20} mb={12}>
                Checklist
              </Text>
              <Text mb={16} color={basic20} fontSize={14} lineHeight="17px">
                The items listed below are required before returning CDD to the
                RU team.
              </Text>
              <>
                <ValidationListItem
                  title="Contractor State License"
                  validation={stateLicenseItems}
                />
                <ValidationListItem
                  title="Insurance"
                  validation={insuranceItems}
                  hasNestedItems
                />
                <ValidationListItem
                  title="References"
                  validation={referencesItems}
                />
                <ValidationListItem
                  title="Web Reviews"
                  validation={webReviewItems}
                />
                <ValidationListItem
                  title="Better Business Bureau"
                  validation={bbbItems}
                />
              </>
            </Tooltip>
          )}
          <ModalsContext.Consumer>
            {({loading, onClose, setLoading, setModalProps}) => {
              const modalProps = {
                acceptLabel: buttonText,
                component: GcddSetOverallScore,
                componentProps: {
                  gcddReview,
                },
                hasButtons: true,
                header: 'Overall score',
                onAccept: async ({gcddReview}) => {
                  setLoading(true);
                  await onSubmitModal(gcddReview);
                  onClose();
                },
                onReject: () => onClose(),
                submitDisabled: ({gcddReview}) => {
                  const hasNoScore = isNil(gcddReview?.overallScore);
                  return hasNoScore;
                },
                clickBackdropToClose: false,
              };
              return (
                <Button
                  variant="danger"
                  loading={loading || isSubmitting}
                  disabled={isBtnDisabled || loading || isSubmitting}
                  onClick={() => setModalProps(modalProps)}>
                  {buttonText}
                </Button>
              );
            }}
          </ModalsContext.Consumer>
        </>
      </MouseHover>
    </StickyFooter>
  );
};

GcddReviewFooter.propTypes = {
  gcddReview: PropTypes.shape({
    completedAt: PropTypes.string,
    overallScore: PropTypes.string,
    overallScoreNotes: PropTypes.string,
    webReviewsScore: PropTypes.string,
  }),
};

export default GcddReviewFooter;
