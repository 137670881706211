import {useEffect, useRef} from 'react';

import PropTypes from 'prop-types';

import withForwardRef from '@renofi/utilities/src/withForwardRef';

import Text from '../Text';

import {Input} from './styled';
import {validate} from './validate';

const FieldInput = ({
  forwardedRef = useRef(null),
  type = 'text',
  onChange,
  onValidate = () => {},
  validations = [],
  fake,
  passFullEvent,
  light,
  ...props
}) => {
  useEffect(() => {
    if (validations?.length <= 0) return;
    const err = validateHandler(props.value);
    onValidate(err, props.name);
  }, [props.value, JSON.stringify(validations)]);

  useEffect(() => {
    return () => {
      onValidate(undefined, props.name);
    };
  }, []);
  const changeHandler = (event) => {
    const value = passFullEvent ? event : event?.target?.value;
    onChange && onChange(value);
  };

  const validateHandler = (value) => {
    if (validations?.length <= 0) return undefined;

    let error = '';
    validations?.reverse()?.forEach((item) => {
      const errMessage = validate(item, value);
      if (errMessage) error = errMessage;
    });

    return error || undefined;
  };

  return fake ? (
    <Text css={{opacity: light ? '0.3' : '1'}}>{props.value}</Text>
  ) : (
    <Input ref={forwardedRef} onChange={changeHandler} type={type} {...props} />
  );
};

FieldInput.propTypes = {
  forwardedRef: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  validations: PropTypes.array,
  isDirty: PropTypes.bool,
  fake: PropTypes.bool,
  light: PropTypes.bool,
  passFullEvent: PropTypes.bool,
};

export default withForwardRef(FieldInput);
