import {documentWithAllProps} from '../document';

export default `
city
coborrowerOwned
county
currentOccupancyOther
documents {
  ${documentWithAllProps}
}
documentIds
existingMortgageLoan
homeownerMonthlyInsurance
homeownersAssociationFee
homeownersAssociationFeesIncluded
homeownersAssociationFeesExist
id
intendedOccupancy
marketValue
mortgageAccountNumber
mortgageBalance
mortgageBalanceSecondLien
mortgageBalanceThirdLien
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyInsurance
mortgageMonthlyPayment
mortgageStatementDate
otherMonthlyExpenses
primaryBorrowerOwned
propertyStatus
propertyKind
propertyMonthlyTaxes
propertyStructureType
propertyUseType
rentalIncome
state
streetAddressOne
streetAddressTwo
taskId
taxAndInsuranceIncluded
yearPurchased
zipCode
`;
